import React, { useState, useEffect } from "react";
import { useGetPropertyByIdQuery } from "../properties/properties.service";
import { Media } from "../properties/properties.types";
import { useParams } from "react-router-dom";
import {
  useDeleteGuideMutation,
  useGetCategoriesQuery,
  useGetGuidesQuery,
  useDeleteCategoryMutation,
  useAddCategoryMutation,
  useEditCategoryMutation,
  useUpdateCategoryOrderMutation,
} from "./guides.services";
import GuidePageTemplate from "../../../../components/templates /PropertyGuide/GuidePageTemplate";

const PropertyGuides: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Property ID from route
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isAddingGuide, setIsAddingGuide] = useState(false);
  const [newGuideMedias, setNewGuideMedias] = useState<Media[]>([]);
  const [updateCategoryOrder] = useUpdateCategoryOrderMutation(); // Hook for updating category order

  // Fetch property and categories
  const { data: property, isLoading: isPropertyLoading } =
    useGetPropertyByIdQuery(id ?? "");
  const { data: categories, refetch: refetchCategories } =
    useGetCategoriesQuery({ propertyId: id! }); // Pass propertyId here

  const { data: guides, refetch: refetchGuides } = useGetGuidesQuery({
    propertyId: id!,
    categoryKey: selectedCategory || "",
  });

  // Mutations for guides and categories
  const [deleteGuide] = useDeleteGuideMutation();
  const [addCategory] = useAddCategoryMutation();
  const [editCategory] = useEditCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  // Auto-select the first category if none is selected
  useEffect(() => {
    if (categories && categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0].id);
    }
  }, [categories, selectedCategory]);

  // =======================
  // Guide Deletion Logic
  // =======================
  const handleDeleteGuide = async (guideId: string) => {
    try {
      await deleteGuide(guideId).unwrap();
      refetchGuides(); // Refetch guides after deletion
    } catch (error) {
      console.error("Error deleting guide:", error);
    }
  };

  // =======================
  // Category Functions
  // =======================

  const handleAddCategory = async (
    categoryName: string,
    image: File | null
  ) => {
    if (categoryName && image) {
      try {
        // Prepare form data with the category name and image
        const formData = new FormData();
        formData.append("name", categoryName);
        formData.append("image", image);

        // Send the form data to the backend using addCategory function
        await addCategory({ name: categoryName, image: image });

        // Refresh the category list
        refetchCategories();
      } catch (error) {
        console.error("Error adding category:", error);
      }
    } else {
      console.error("Category name or image is missing");
    }
  };

  const handleEditCategory = async (
    id: string,
    name: string,
    image?: File | null // Updated to accept File, null, or undefined
  ) => {
    if (name) {
      try {
        // Adjust the logic to handle the possibility of 'null'
        await editCategory({ id, name, image: image || undefined }).unwrap();
        refetchCategories();
      } catch (error) {
        console.error("Error editing category:", error);
      }
    }
  };

  const handleDeleteCategory = async (categoryId: string) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await deleteCategory(categoryId).unwrap();
        refetchCategories();
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };
  const moveCategoryUp = async (categoryId: string) => {
    if (!categories) return;

    const index = categories.findIndex((cat) => cat.id === categoryId);
    if (index <= 0) return; // Already the first item

    // Create a shallow copy of the categories array and the objects within it
    const updatedCategories = categories.map((cat) => ({ ...cat }));

    // Swap the order of the current category with the one above it
    [updatedCategories[index], updatedCategories[index - 1]] = [
      updatedCategories[index - 1],
      updatedCategories[index],
    ];

    // Reassign the order sequentially (1 to x)
    updatedCategories.forEach((cat, idx) => {
      cat.order = idx + 1; // Assign order based on index (1-based)
    });

    // Prepare the payload for the backend
    const payload = {
      categories: updatedCategories.map((cat) => ({
        order: cat.order!, // Ensure order is always a number (use non-null assertion)
        category: cat.id, // The unique ID of the category
      })),
      property: id!, // Pass the propertyId separately
    };

    try {
      // Send the updated order to the backend
      await updateCategoryOrder(payload).unwrap();
      refetchCategories(); // Refetch categories to ensure consistency
    } catch (error) {
      console.error("Error updating category order:", error);
    }
  };

  const moveCategoryDown = async (categoryId: string) => {
    if (!categories) return;

    const index = categories.findIndex((cat) => cat.id === categoryId);
    if (index === -1 || index === categories.length - 1) return; // Already the last item

    // Create a shallow copy of the categories array and the objects within it
    const updatedCategories = categories.map((cat) => ({ ...cat }));

    // Swap the order of the current category with the one below it
    [updatedCategories[index], updatedCategories[index + 1]] = [
      updatedCategories[index + 1],
      updatedCategories[index],
    ];

    // Reassign the order sequentially (1 to x)
    updatedCategories.forEach((cat, idx) => {
      cat.order = idx + 1; // Assign order based on index (1-based)
    });

    // Prepare the payload for the backend
    const payload = {
      categories: updatedCategories.map((cat) => ({
        order: cat.order!, // Ensure order is always a number (use non-null assertion)
        category: cat.id, // The unique ID of the category
      })),
      property: id!, // Pass the propertyId separately
    };

    try {
      // Send the updated order to the backend
      await updateCategoryOrder(payload).unwrap();
      refetchCategories(); // Refetch categories to ensure consistency
    } catch (error) {
      console.error("Error updating category order:", error);
    }
  };

  // =======================
  // Render the Template
  // =======================
  if (isPropertyLoading) return <p>Loading property...</p>;

  return (
    <GuidePageTemplate
      property={property}
      propertyId={id ?? ""}
      guides={guides ?? []}
      categories={categories ?? []}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      onDeleteGuide={handleDeleteGuide}
      isAddingGuide={isAddingGuide}
      setIsAddingGuide={setIsAddingGuide}
      onAddCategory={handleAddCategory}
      onEditCategory={handleEditCategory}
      onDeleteCategory={handleDeleteCategory}
      refetchGuides={refetchGuides} // Pass refetchGuides to be called after guide actions
      medias={[]} // This can be handled as needed in the child components
      moveCategoryUp={moveCategoryUp}
      moveCategoryDown={moveCategoryDown}
    />
  );
};

export default PropertyGuides;
