import React, { useState } from "react";
import InputAtom from "../../atoms/PropertyFaqs/InputAtom";
import ButtonAtom from "../../atoms/PropertyFaqs/ButtonAtom";
import { AddFaqRequest } from "../../../features/athenticated/dashboard/PropertyFaq/faq.types";

interface FaqFormProps {
  propertyId: string;
  onAdd: (newFaq: AddFaqRequest) => void;
}

const FaqForm: React.FC<FaqFormProps> = ({ onAdd, propertyId }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleAdd = () => {
    const newFaq: AddFaqRequest = { question, answer, property: propertyId };
    onAdd(newFaq);
    setQuestion("");
    setAnswer("");
  };

  return (
    <div
      style={{
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      <InputAtom
        label="New Question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        style={{ marginBottom: "10px" }}
      />
      <InputAtom
        label="New Answer"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <ButtonAtom
        label="Add FAQ"
        onClick={handleAdd}
        variant="outlined"
        color="primary"
      />
    </div>
  );
};

export default FaqForm;
