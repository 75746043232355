// src/app/Slices/authSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import endpoints from "../../../api/endpoints";
import { setAuthPrompt } from "../appStateSlice";
import { RootState } from "../../../app/store";

interface AuthState {
  access_token: string | null;
  refresh_token: string | null;
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  access_token: null,
  refresh_token: null,
  isLoggedIn: false,
};

// Async thunk for refreshing the token
export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const refresh_token = state.auth.refresh_token;

    if (!refresh_token) {
      return rejectWithValue("No refresh token available");
    }

    try {
      const response = await axios.post(
        `${endpoints.baseUrl}${endpoints.refresh}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refresh_token}`,
          },
        }
      );

      const { access_token, refresh_token: newRefreshToken } = response.data;

      // Return new tokens on successful refresh
      return { access_token, refresh_token: newRefreshToken };
    } catch (error) {
      // Clear tokens if refresh fails
      dispatch(clearToken());
      // dispatch(setAuthPrompt(true)); // Show the auth prompt when refresh fails
      return rejectWithValue("Failed to refresh token");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ access_token: string; refresh_token: string }>
    ) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.isLoggedIn = true;

      // Optionally store tokens in localStorage
      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("refresh_token", action.payload.refresh_token);
    },
    clearToken: (state) => {
      state.access_token = null;
      state.refresh_token = null;
      state.isLoggedIn = false;

      // Clear tokens from localStorage
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshToken.fulfilled, (state, action) => {
        // Update tokens on successful refresh
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
        state.isLoggedIn = true;

        localStorage.setItem("access_token", action.payload.access_token);
        localStorage.setItem("refresh_token", action.payload.refresh_token);
      })
      .addCase(refreshToken.rejected, (state) => {
        // Clear tokens on refresh failure
        state.access_token = null;
        state.refresh_token = null;
        state.isLoggedIn = false;
      });
  },
});

export const { setUser, clearToken } = authSlice.actions;
export default authSlice.reducer;
