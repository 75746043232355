// src/services/baseApi.ts
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { RootState } from "../app/store";
import endpoints from "../api/endpoints";
import {
  clearToken,
  refreshToken,
} from "../redux/Slices/Authentication/authSlice";
import { setAuthPrompt } from "../redux/Slices/appStateSlice";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: endpoints.baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.access_token;
    console.log(token)
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      console.log(token);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock(); // Wait if a refresh is already in progress
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire(); // Acquire lock for refresh
      try {
        const refreshResult = await api.dispatch(refreshToken());

        if (refreshToken.fulfilled.match(refreshResult)) {
          // Retry original request with the new token
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(clearToken());
          api.dispatch(setAuthPrompt(true)); // Show login prompt if refresh fails
        }
      } finally {
        release(); // Release the lock after refresh
      }
    } else {
      // Wait until the refresh is done if another request is handling it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions); // Retry the original request
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ["Faqs"],
});
