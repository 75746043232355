import React from "react";

interface ErrorMessageProps {
  message?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return message ? <span className="text-red-600">{message}</span> : null;
};

export default ErrorMessage;
