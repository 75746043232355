// src/hooks/useConnectionStatus.ts
import { useEffect } from "react";
import { setOnlineStatus } from "../redux/Slices/appStateSlice";
import { useAppDispatch } from "./hooks";

const useConnectionStatus = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleOnline = () => dispatch(setOnlineStatus(true));
    const handleOffline = () => dispatch(setOnlineStatus(false));

    // Set initial online status
    dispatch(setOnlineStatus(navigator.onLine));

    // Add event listeners for online and offline events
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [dispatch]);
};

export default useConnectionStatus;
