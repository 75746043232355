// firebase_config.ts

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4OR-8Lh2gM6Er7fRajtgvEZsl4aUVWzs",
  authDomain: "airbnb-rental-9c5e7.firebaseapp.com",
  projectId: "airbnb-rental-9c5e7",
  storageBucket: "airbnb-rental-9c5e7.appspot.com",
  messagingSenderId: "781391888927",
  appId: "1:781391888927:web:800773089d1fe1717bcb2a",
  measurementId: "G-T6QYS4ZW0N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Google Provider
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
