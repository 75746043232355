import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Button,
  Typography,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  SelectChangeEvent,
  Switch,
  Autocomplete,
} from "@mui/material";
import {
  useGetContactsQuery,
  useAddContactMutation,
  useUpdatePropertyMutation,
  useUpdateAmenitiesMutation,
  useUpdateLanguagesByPropertyIdMutation,
  useGetLanguagesByPropertyIdQuery,
} from "../../features/athenticated/dashboard/PropertyDetails/propertyDetails.services";
import {
  Amenities,
  Contact,
  EditableProperty,
  ExtendedProperty,
  Media,
} from "../../features/athenticated/dashboard/properties/properties.types";
import endpoints from "../../api/endpoints";

// Import MUI Icons for amenities
import WifiIcon from "@mui/icons-material/Wifi";
import PoolIcon from "@mui/icons-material/Pool";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import KitchenIcon from "@mui/icons-material/Kitchen";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ElevatorIcon from "@mui/icons-material/Elevator";
import PetsIcon from "@mui/icons-material/Pets";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import TvIcon from "@mui/icons-material/Tv";
import FireplaceIcon from "@mui/icons-material/Fireplace";
import NoIcon from "@mui/icons-material/Block"; // Default icon
import { FormControl, InputLabel, OutlinedInput, Chip } from "@mui/material";

const languageMap: Record<string, string> = {
  en: "English",
  fr: "French",
  ar: "Arabic",
  zh: "Chinese",
  it: "Italian",
  es: "Spanish",
  de: "German",
  pt: "Portuguese",
};

// Icon mapping for amenities
const amenityIcons: Record<string, JSX.Element> = {
  wifi: <WifiIcon />,
  pool: <PoolIcon />,
  parking: <LocalParkingIcon />,
  kitchen: <KitchenIcon />,
  air_conditioning: <AcUnitIcon />,
  gym: <FitnessCenterIcon />,
  elevator: <ElevatorIcon />,
  pets_allowed: <PetsIcon />,
  smoke_free: <SmokeFreeIcon />,
  tv: <TvIcon />,
  fireplace: <FireplaceIcon />,
};

const PropertyDetailsOrganism: React.FC<{
  property: ExtendedProperty;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ property, isEditing, setIsEditing }) => {
  const [showMore, setShowMore] = useState(false); // Line to manage show more/less
  const [newContact, setNewContact] = useState<Contact>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    id: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [editedFields, setEditedFields] = useState({
    name: property.name,
    description: property.description,
  });

  // Fetching initial languages
  const { data, isLoading: loadingLanguages } =
    useGetLanguagesByPropertyIdQuery(property.id);
  const initialSelectedLanguages = data?.languages || []; // Extract languages array from the response

  // State to manage selected languages (as codes)
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    initialSelectedLanguages
  );
  const [updateLanguages, { isLoading: isUpdating }] =
    useUpdateLanguagesByPropertyIdMutation();
  const availableLanguages = Object.keys(languageMap);

  // Update selected languages based on fetched data
  useEffect(() => {
    if (Array.isArray(initialSelectedLanguages)) {
      setSelectedLanguages(initialSelectedLanguages);
    }
  }, [initialSelectedLanguages]);

  const { data: contacts = [], isLoading: contactsLoading } =
    useGetContactsQuery();
  const [addContact, { isLoading: addingContact }] = useAddContactMutation();
  const [
    updateProperty,
    {
      isLoading: isUpdatingLanguage,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdatePropertyMutation();

  const handleFieldChange = (field: string, value: string) => {
    setEditedFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Update individual amenities field
  const handleAmenityChange = (key: keyof Amenities, value: any) => {
    setAmenities((prev) => ({ ...prev, [key]: value }));
  };

  const [amenities, setAmenities] = useState<Amenities>(
    property.amenities || {
      breakfast_included: null,
      has_parking: null,
      adults_only: null,
      pets_allowed: null,
      max_people: null,
      units: null,
      has_wifi: null,
      has_meal_plan: null,
      bedrooms: null,
      bathrooms: null,
      area_unit: "sqf",
      area: null,
    }
  );

  const [updateAmenities, { isLoading: updatingAmenities }] =
    useUpdateAmenitiesMutation();

  const handleSaveChanges = async () => {
    try {
      // Define updates for property fields
      const updates: Partial<EditableProperty> = { id: property.id };

      // Check for main property field changes
      if (editedFields.name && editedFields.name !== property.internal_name) {
        updates.internal_name = editedFields.name;
      }

      if (
        editedFields.description &&
        editedFields.description !== property.description
      ) {
        updates.description = editedFields.description;
      }

      // Update contact if changed
      if (selectedContact?.id && selectedContact.id !== property.contact?.id) {
        updates.contact = selectedContact.id;
      }

      // Detect language changes by comparing selectedLanguages with initialSelectedLanguages
      // Detect language changes by comparing selectedLanguages with initialSelectedLanguages
      const hasLanguageUpdates =
        Array.isArray(initialSelectedLanguages) &&
        Array.isArray(selectedLanguages) &&
        JSON.stringify(selectedLanguages.slice().sort()) !==
          JSON.stringify(initialSelectedLanguages.slice().sort());

      // Filter out only changed fields in amenities
      const amenityUpdates = Object.fromEntries(
        Object.entries(amenities).filter(
          ([key, value]) =>
            value !==
            (property.amenities as Amenities)?.[key as keyof Amenities]
        )
      );

      const hasPropertyUpdates = Object.keys(updates).length > 1; // Only count beyond 'id'
      const hasAmenityUpdates = Object.keys(amenityUpdates).length > 0;

      // Exit if no updates are detected
      if (!hasPropertyUpdates && !hasAmenityUpdates && !hasLanguageUpdates) {
        console.log("No changes detected, skipping save.");
        setIsEditing(false);
        return;
      }

      console.log("Saving changes:", {
        updates,
        amenityUpdates,
        selectedLanguages,
      });

      // Save property updates if any
      if (hasPropertyUpdates) {
        await updateProperty({
          propertyId: property.id,
          updates,
        }).unwrap();
      }

      // Save amenity updates if any
      if (hasAmenityUpdates && amenities.id) {
        await updateAmenities({
          propertyId: property.amenities.id,
          updates: amenityUpdates,
        }).unwrap();
      }

      // Save language updates if any
      if (hasLanguageUpdates) {
        await updateLanguages({
          propertyId: property.id,
          languages: selectedLanguages,
        }).unwrap();
      }

      // Exit edit mode on successful save
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to save changes", error);
    }
  };

  // Initialize selectedContact with property.contact
  const [selectedContact, setSelectedContact] = useState<Contact | null>(
    property?.contact || null
  );

  // Update useEffect to use property.contact
  useEffect(() => {
    setSelectedContact(property.contact || null);
  }, [property.contact]);

  const handleSelectContact = (event: SelectChangeEvent<string>) => {
    console.log("Selected contact ID from dropdown:", event.target.value); // Log the selected ID

    // Find the contact based on the selected ID
    const contact = contacts.find((c) => c.id === event.target.value);

    if (contact) {
      console.log("Matching contact found:", contact); // Log the matched contact
    } else {
      console.warn("No matching contact found for ID:", event.target.value); // Warn if no contact is found
    }

    setSelectedContact(contact || null);
    console.log("Updated selectedContact state:", contact || null); // Log the state update
  };

  const [localContacts, setLocalContacts] = useState<Contact[]>([]);

  useEffect(() => {
    if (contacts) {
      setLocalContacts(contacts); // Set local contacts to initial fetched contacts
    }
  }, [contacts]);

  const [apiError, setApiError] = useState<string | null>(null);

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const validateContact = () => {
    let isValid = true;
    const newErrors = {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    };

    if (!newContact.first_name.trim()) {
      newErrors.first_name = "First name is required.";
      isValid = false;
    }

    if (!newContact.last_name.trim()) {
      newErrors.last_name = "Last name is required.";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!newContact.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!emailRegex.test(newContact.email)) {
      newErrors.email = "Invalid email format.";
      isValid = false;
    }

    if (!newContact.phone_number.trim()) {
      newErrors.phone_number = "Phone number is required.";
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const handleAddNewContact = async () => {
    setApiError(null); // Reset API error before a new attempt

    if (!validateContact()) return; // Stop if validation fails

    try {
      const newAddedContact = await addContact(newContact).unwrap();
      setSelectedContact(newAddedContact);
      setLocalContacts((prevContacts) => [...prevContacts, newAddedContact]);
      setModalOpen(false);
    } catch (error: any) {
      const errorMessage =
        error.data?.message || "Failed to add contact. Please try again.";
      setApiError(errorMessage);
      console.error("Failed to add contact", error);
    }
  };

  return (
    <Card
      style={{
        width: "100%",
        maxWidth: "1000px",
        padding: "20px",
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f0f0f0", // iOS style background
        color: "#000", // Black text for iOS look
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          {property.internal_name || property.name}{" "}
          {/* Internal name if exists */}
        </Typography>

        <Button
          onClick={async () => {
            if (isEditing) {
              await handleSaveChanges();
            } else {
              setIsEditing(true);
            }
          }}
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#000", color: "#fff" }} // Black and white button
        >
          {isEditing ? "Save" : "Edit"}
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Name of the property:</Typography>
          {isEditing ? (
            <TextField
              fullWidth
              value={editedFields.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
              label="Property Name"
            />
          ) : (
            <Typography variant="body1">
              {property.internal_name || property.name}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Description:</Typography>
          {isEditing ? (
            <TextField
              fullWidth
              multiline
              minRows={3}
              value={editedFields.description}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              label="Property Description"
            />
          ) : (
            <Typography variant="body1" component="div">
              {showMore
                ? property.description
                : `${property.description.slice(0, 100)}...`}
              {property.description && property.description.length > 100 && (
                <Box component="span" display="inline">
                  <Button
                    onClick={() => setShowMore(!showMore)}
                    size="small"
                    style={{
                      textTransform: "none",
                      padding: 0,
                      marginLeft: 4,
                      color: "blue",
                    }}
                  >
                    {showMore ? "See Less" : "See More"}
                  </Button>
                </Box>
              )}
            </Typography>
          )}
        </Grid>

        {/* Contact Owner Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Contact Owner:</Typography>
          {isEditing ? (
            <>
              <Select
                value={selectedContact?.id || ""}
                onChange={handleSelectContact}
                displayEmpty
                fullWidth
              >
                <MenuItem value="">
                  <em>Select a Contact</em>
                </MenuItem>
                {localContacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.first_name} {contact.last_name} - {contact.email}
                  </MenuItem>
                ))}
              </Select>

              <Button
                variant="outlined"
                onClick={() => setModalOpen(true)}
                style={{ marginTop: "10px" }}
              >
                Add New Contact
              </Button>

              {/* Modal for adding new contact */}
              <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
                <Box
                  style={{
                    padding: "20px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    maxWidth: "500px",
                    margin: "auto",
                    marginTop: "10%",
                  }}
                >
                  <Typography variant="h6">Add New Contact</Typography>

                  {apiError && (
                    <Typography
                      color="error"
                      variant="body2"
                      style={{ marginBottom: "10px" }}
                    >
                      {apiError}
                    </Typography>
                  )}

                  <TextField
                    label="First Name"
                    fullWidth
                    value={newContact.first_name}
                    onChange={(e) =>
                      setNewContact({
                        ...newContact,
                        first_name: e.target.value,
                      })
                    }
                    error={!!error.first_name}
                    helperText={error.first_name}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    label="Last Name"
                    fullWidth
                    value={newContact.last_name}
                    onChange={(e) =>
                      setNewContact({
                        ...newContact,
                        last_name: e.target.value,
                      })
                    }
                    error={!!error.last_name}
                    helperText={error.last_name}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    label="Email"
                    fullWidth
                    value={newContact.email}
                    onChange={(e) =>
                      setNewContact({ ...newContact, email: e.target.value })
                    }
                    error={!!error.email}
                    helperText={error.email}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    label="Phone Number"
                    fullWidth
                    value={newContact.phone_number}
                    onChange={(e) =>
                      setNewContact({
                        ...newContact,
                        phone_number: e.target.value,
                      })
                    }
                    error={!!error.phone_number}
                    helperText={error.phone_number}
                    style={{ marginBottom: "10px" }}
                  />
                  <Button
                    onClick={handleAddNewContact}
                    variant="contained"
                    color="primary"
                  >
                    {addingContact ? "Adding..." : "Add Contact"}
                  </Button>
                </Box>
              </Modal>
            </>
          ) : (
            <Box>
              {selectedContact ? (
                <>
                  <Typography variant="body1">
                    Name: {selectedContact.first_name}{" "}
                    {selectedContact.last_name}
                  </Typography>
                  <Typography variant="body1">
                    Email: {selectedContact.email}
                  </Typography>
                  <Typography variant="body1">
                    Phone: {selectedContact.phone_number}
                  </Typography>
                </>
              ) : (
                <Typography variant="body1">
                  No contact owner information available.
                </Typography>
              )}
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6" gutterBottom>
            Available Languages
          </Typography>

          {loadingLanguages ? (
            <CircularProgress />
          ) : isEditing ? (
            <Autocomplete
              multiple
              options={availableLanguages} // Use codes as options
              getOptionLabel={(option) => languageMap[option] || option} // Show language name in dropdown
              value={selectedLanguages}
              onChange={(event, newValue) => setSelectedLanguages(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Languages"
                  variant="outlined"
                  fullWidth
                />
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((code, index) => (
                  <Chip
                    label={languageMap[code] || code} // Show the name for each code
                    {...getTagProps({ index })}
                    style={{ margin: 2 }}
                  />
                ))
              }
            />
          ) : (
            <Box display="flex" flexWrap="wrap" gap={1}>
              {selectedLanguages.length > 0 ? (
                selectedLanguages.map((code) => (
                  <Typography key={code} variant="body2">
                    {languageMap[code] || code}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No languages selected
                </Typography>
              )}
            </Box>
          )}
        </Grid>
        {/* Amenity Grid with Icons */}
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ marginTop: "20px", fontWeight: "bold", color: "#333" }}
          >
            Amenities
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(amenities)
              .filter(([key]) => key !== "id") // Exclude 'id' from rendering
              .map(([key, value]) => (
                <Grid item xs={6} sm={4} key={key}>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      backgroundColor: "#f8f8f8",
                      padding: "12px",
                      borderRadius: "8px",
                      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                      transition: "background 0.3s",
                    }}
                  >
                    {/* Icon */}
                    {amenityIcons[key] || <NoIcon />}
                    <Typography
                      style={{
                        marginLeft: "10px",
                        textTransform: "capitalize",
                        color: "#333",
                        fontWeight: "500",
                        fontSize: "0.95rem",
                      }}
                    >
                      {key.replace(/_/g, " ")}
                    </Typography>

                    {/* Handle different input types */}
                    {[
                      "breakfast_included",
                      "has_parking",
                      "adults_only",
                      "pets_allowed",
                      "has_wifi",
                      "has_meal_plan",
                    ].includes(key) ? (
                      isEditing ? (
                        <Switch
                          checked={!!value}
                          onChange={(e) =>
                            handleAmenityChange(
                              key as keyof Amenities,
                              e.target.checked
                            )
                          }
                          color="default"
                          style={{
                            marginLeft: "auto",
                            color: "#000",
                          }}
                          inputProps={{ "aria-label": `${key} toggle` }}
                        />
                      ) : (
                        <Typography
                          style={{
                            marginLeft: "auto",
                            fontWeight: "bold",
                            color: value ? "#333" : "#999",
                          }}
                        >
                          {value ? "Available" : "Not Available"}
                        </Typography>
                      )
                    ) : key === "area_unit" ? (
                      // Dropdown for area_unit
                      isEditing ? (
                        <Select
                          value={value || ""}
                          onChange={(e) =>
                            handleAmenityChange("area_unit", e.target.value)
                          }
                          fullWidth
                          style={{
                            marginLeft: "auto",
                            color: "#333",
                            fontWeight: "500",
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                          }}
                        >
                          <MenuItem value="sqf">Square Feet (sqf)</MenuItem>
                          <MenuItem value="sqm">Square Meters (sqm)</MenuItem>
                        </Select>
                      ) : (
                        <Typography
                          style={{
                            marginLeft: "auto",
                            color: "#333",
                          }}
                        >
                          {value || "N/A"}
                        </Typography>
                      )
                    ) : // TextField for numeric or other text-based amenities
                    isEditing ? (
                      <TextField
                        fullWidth
                        type="number"
                        value={value || ""}
                        onChange={(e) =>
                          handleAmenityChange(
                            key as keyof Amenities,
                            e.target.value
                          )
                        }
                        style={{
                          marginLeft: "20px", // Add spacing between name and input
                          color: "#333",
                          backgroundColor: "#fff",
                          borderRadius: "4px",
                        }}
                        inputProps={{ min: 0 }}
                      />
                    ) : (
                      <Typography
                        style={{
                          marginLeft: "auto",
                          color: "#333",
                        }}
                      >
                        {value || "N/A"}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>

        {/* Property Images */}
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Property Images
          </Typography>
          {property.medias && property.medias.length > 0 ? (
            <Grid container spacing={1}>
              {property.medias.map((media: Media) => (
                <Grid item xs={6} sm={2} key={media.media_id}>
                  <img
                    src={media.file} // Removed URL concatenation
                    alt="Property"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">No images available.</Typography>
          )}
        </Grid>

        {/* Saving Indicator */}
        {isUpdating && (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default PropertyDetailsOrganism;
