import endpoints from "../../../api/endpoints";
import { apiSlice } from "../../../services/baseApi";
import { IGoogleLoginRequest, ILoginRequest } from "./login.types";
import { setUser } from "../../../redux/Slices/Authentication/authSlice";

const loginApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, ILoginRequest>({
      query: (body) => {
        return { url: endpoints.login, body, method: "POST" };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Store tokens in Redux store
          dispatch(
            setUser({
              access_token: data.access_token,
              refresh_token: data.refresh_token,
            })
          );
        } catch (error) {
          console.error("Login failed: ", error);
        }
      },
    }),

    googleLogin: builder.mutation<any, IGoogleLoginRequest>({
      query: (body) => ({
        url: endpoints.googleLogIn, // Custom Google login endpoint
        method: "POST",
        body, // Body contains email and Google access token
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Store tokens in Redux store
          dispatch(
            setUser({
              access_token: data.access_token,
              refresh_token: data.refresh_token,
            })
          );
        } catch (error) {
          console.error("Google Login failed: ", error);
        }
      },
    }),
  }),
});

export const { useLoginMutation, useGoogleLoginMutation } = loginApi;
