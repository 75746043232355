/* This code snippet is defining an API slice using Redux Toolkit's `apiSlice` to handle API requests
related to guest information. It imports necessary dependencies such as `endpoints` and `apiSlice`
from specific paths. */
import endpoints from "../../../api/endpoints";
import { apiSlice } from "../../../services/baseApi";
import {
  GuestFaqs,
  GuestGuidesDetails,
  GuestPropertyDetails,
  GuestReservationDetails,
} from "./guest.types";

const propertiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGuestFaqs: builder.query<
      GuestFaqs,
      { id: string; isReservation?: boolean; lang?: string }
    >({
      query: ({ id, isReservation, lang }) => {
        const params = {
          ...(isReservation && { reservation: true }),
          ...(lang && { lang }),
        };

        return {
          url: endpoints.guest + `${id}/faqs/`,
          method: "GET",
          params,
        };
      },
    }),

    getGuestPropertyDetails: builder.query<
      GuestPropertyDetails,
      { id: string; isReservation?: boolean; lang?: string }
    >({
      query: ({ id, isReservation, lang }) => {
        const params = {
          ...(isReservation && { reservation: true }),
          ...(lang && { lang }),
        };

        return {
          url: endpoints.guest + `${id}/property/`,
          method: "GET",
          params,
        };
      },
    }),

    getGuestReservationDetails: builder.query<
      GuestReservationDetails,
      { id: string; isReservation?: boolean; lang?: string }
    >({
      query: ({ id, isReservation, lang }) => {
        const params = {
          ...(isReservation && { reservation: true }),
          ...(lang && { lang }),
        };

        return {
          url: endpoints.guest + `${id}/reservation/`,
          method: "GET",
          params,
        };
      },
    }),

    getGuestGuidesDetails: builder.query<
      GuestGuidesDetails,
      { id: string; isReservation?: boolean; lang?: string }
    >({
      query: ({ id, isReservation, lang }) => {
        const params = {
          ...(isReservation && { reservation: true }),
          ...(lang && { lang }),
        };

        return {
          url: endpoints.guest + `${id}/guides/`,
          method: "GET",
          params,
        };
      },
    }),

    getGuestContactDetails: builder.query<
      any,
      { id: string; isReservation?: boolean; lang?: string }
    >({
      query: ({ id, isReservation, lang }) => {
        const params = {
          ...(isReservation && { reservation: true }),
          ...(lang && { lang }),
        };
        return {
          url: endpoints.guest + `${id}/contact/`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});

export const {
  useGetGuestContactDetailsQuery,
  useGetGuestFaqsQuery,
  useGetGuestGuidesDetailsQuery,
  useGetGuestPropertyDetailsQuery,
  useGetGuestReservationDetailsQuery,
} = propertiesApi;

export default propertiesApi;
