import React, { useState, useEffect } from "react";
import {
  Grid,
  Switch,
  Typography,
  Button,
  FormControlLabel,
  MenuItem,
  Select,
  Snackbar,
  Alert,
} from "@mui/material";
// Removed invalid import
import { Editor } from "@tinymce/tinymce-react";
import {
  useAddMessageMutation,
  useGetPlaceholdersQuery,
} from "../../../features/athenticated/dashboard/PropertyMessages/messageService";

interface MessageFormProps {
  messageDetails: {
    subject: string;
    content: string;
    byEmail: boolean;
    byWhatsapp: boolean;
    bySource: boolean;
    onlyMoreThanOneNight: boolean;
    send_at: number;
  };
  setMessageDetails: React.Dispatch<React.SetStateAction<any>>;
  propertyId: string;
  selectedScheduleKey: string;
  refetchMessages: () => void;
}

const MessageForm: React.FC<MessageFormProps> = ({
  messageDetails,
  setMessageDetails,
  propertyId,
  selectedScheduleKey,
  refetchMessages,
}) => {
  const { data: placeholders = [], isLoading } = useGetPlaceholdersQuery();
  const [mergeTagsList, setMergeTagsList] = useState<
    { value: string; title: string }[]
  >([]);
  const [addMessage, { isLoading: isSubmitting, isSuccess, isError }] =
    useAddMessageMutation();

  const [validationError, setValidationError] = useState<string | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Fetch and format placeholders
  useEffect(() => {
    if (!isLoading && placeholders.length > 0) {
      const formattedTags = placeholders.map((placeholder) => ({
        value: placeholder.key,
        title: placeholder.value,
      }));
      setMergeTagsList(formattedTags);
    }
  }, [placeholders, isLoading]);

  const handleHourChange = (event: any) => {
    const selectedHour = Number(event.target.value);
    setMessageDetails((prevState: any) => ({
      ...prevState,
      send_at: selectedHour,
    }));
  };

  const handleEditorChange = (
    content: string,
    editorType: "subject" | "content"
  ) => {
    setMessageDetails((prevState: any) => ({
      ...prevState,
      [editorType]: content,
    }));
  };

  const handleSubmit = async () => {
    if (!messageDetails.subject.trim() || !messageDetails.content.trim()) {
      setValidationError("Subject and Message Body cannot be empty.");
      return;
    }

    setValidationError(null);

    const newMessage = {
      property: propertyId,
      schedule: selectedScheduleKey,
      subject: messageDetails.subject,
      template: messageDetails.content,
      by_email: messageDetails.byEmail,
      by_whatsapp: messageDetails.byWhatsapp,
      by_source: messageDetails.bySource,
      only_more_than_one_night: messageDetails.onlyMoreThanOneNight,
      send_at: messageDetails.send_at,
    };

    try {
      await addMessage(newMessage).unwrap();
      setShowSuccessMessage(true);
      refetchMessages();
    } catch (err) {
      console.error("Error adding message:", err);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "20px", marginLeft: "12px" }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={messageDetails.onlyMoreThanOneNight}
              onChange={(e) =>
                setMessageDetails((prevState: any) => ({
                  ...prevState,
                  onlyMoreThanOneNight: e.target.checked,
                }))
              }
            />
          }
          label="Also reservations for more than 1 night"
        />
      </Grid>

      {/* Only render the editors when mergeTagsList is ready */}
      {mergeTagsList.length > 0 && (
        <>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Subject:
          </Typography>
          <Editor
            value={messageDetails.subject}
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            init={{
              height: 150,
              menubar: false,
              plugins: ["link", "lists", "autolink"],
              toolbar: "bold | bullist outdent indent | customMergeTags",
              setup: (editor) => {
                editor.ui.registry.addMenuButton("customMergeTags", {
                  icon: "custom-merge-tags-icon",
                  fetch: (callback) => {
                    const items: {
                      type: "menuitem";
                      text: string;
                      onAction: () => void;
                    }[] = mergeTagsList.map((tag) => ({
                      type: "menuitem",
                      text: tag.title,
                      onAction: () => editor.insertContent(`{{${tag.value}}}`),
                    }));
                    callback(items);
                  },
                });

                // Register the custom icon
                editor.ui.registry.addIcon(
                  "custom-merge-tags-icon",
                  '<svg width="24" height="24" focusable="false"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5a2 2 0 0 1 1.6.8L21 12l-4.4 6.2a2 2 0 0 1-1.6.8h-3v-2h3l3.5-5L15 7H5v3H3V7c0-1.1.9-2 2-2h10Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 12a1 1 0 0 0-1 1v2H3a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2H7v-2c0-.6-.4-1-1-1Z"></path></svg>'
                );
              },
            }}
            onEditorChange={(content) => handleEditorChange(content, "subject")}
          />

          <div style={{ margin: "20px 0" }} />

          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            Content:
          </Typography>
          <Editor
            value={messageDetails.content}
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            init={{
              height: 150,
              menubar: false,
              plugins: ["link", "lists", "autolink"],
              toolbar: "bold | bullist outdent indent | customMergeTags",
              setup: (editor) => {
                editor.ui.registry.addMenuButton("customMergeTags", {
                  icon: "custom-merge-tags-icon",
                  fetch: (callback) => {
                    const items: {
                      type: "menuitem";
                      text: string;
                      onAction: () => void;
                    }[] = mergeTagsList.map((tag) => ({
                      type: "menuitem",
                      text: tag.title,
                      onAction: () => editor.insertContent(`{{${tag.value}}}`),
                    }));
                    callback(items);
                  },
                });

                // Register the custom icon
                editor.ui.registry.addIcon(
                  "custom-merge-tags-icon",
                  '<svg width="24" height="24" focusable="false"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5a2 2 0 0 1 1.6.8L21 12l-4.4 6.2a2 2 0 0 1-1.6.8h-3v-2h3l3.5-5L15 7H5v3H3V7c0-1.1.9-2 2-2h10Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 12a1 1 0 0 0-1 1v2H3a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2H7v-2c0-.6-.4-1-1-1Z"></path></svg>'
                );
              },
            }}
            onEditorChange={(content) => handleEditorChange(content, "content")}
          />
        </>
      )}

      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={messageDetails.byEmail}
                onChange={(e) =>
                  setMessageDetails((prevState: any) => ({
                    ...prevState,
                    byEmail: e.target.checked,
                  }))
                }
              />
            }
            label="Send by Email"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={messageDetails.bySource}
                onChange={(e) =>
                  setMessageDetails((prevState: any) => ({
                    ...prevState,
                    bySource: e.target.checked,
                  }))
                }
              />
            }
            label="Send on Airbnb"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">Select Hour (0-23):</Typography>
          <Select
            value={messageDetails.send_at}
            onChange={handleHourChange}
            fullWidth
          >
            {Array.from({ length: 24 }).map((_, index) => (
              <MenuItem key={index} value={index}>
                {index}:00
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
            style={{
              backgroundColor: "#1976d2",
              color: "#fff",
              transition: "all 0.3s",
            }}
          >
            {isSubmitting ? "Submitting..." : "Add Message"}
          </Button>
        </Grid>
      </Grid>

      {validationError && (
        <Typography color="error" style={{ marginTop: "20px" }}>
          {validationError}
        </Typography>
      )}

      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setShowSuccessMessage(false)}
      >
        <Alert onClose={() => setShowSuccessMessage(false)} severity="success">
          Message added successfully!
        </Alert>
      </Snackbar>

      {isError && (
        <Typography color="error" style={{ marginTop: "20px" }}>
          Error adding message: {"Unknown error"}
        </Typography>
      )}
    </>
  );
};

export default MessageForm;
