// src/components/atoms/ErrorAlert.tsx
import React from "react";

interface ErrorAlertProps {
  message: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ message }) => (
  <div className="error-alert">
    <p>Error: {message}</p>
  </div>
);

export default ErrorAlert;
