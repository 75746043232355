import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Divider,
} from "@mui/material";
import {
  Link,
  Outlet,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";

const drawerWidth = 240;

const Msgs: React.FC = () => {
  const { id: propertyId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const contentRef = useRef<HTMLDivElement>(null); // Reference to the content box
  const [spacing, setSpacing] = useState(0); // State to store dynamic spacing
  const [contentHeight, setContentHeight] = useState(0); // State to store dynamic height

  // Automatically navigate to Messages Library when no sub-route is selected
  useEffect(() => {
    if (location.pathname === `/property/${propertyId}/messages`) {
      navigate(`/property/${propertyId}/messages/library`);
    }
  }, [navigate, location.pathname, propertyId]);

  // Update the spacing and height dynamically
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (contentRef.current) {
        const contentBox = contentRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;

        // Calculate the spacing between the right of the content box and the edge of the screen
        const newSpacing = windowWidth - (contentBox.left + contentBox.width);
        setSpacing(newSpacing);

        // Update the content height
        setContentHeight(contentBox.height);
      }
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, [contentRef]);

  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#f5f5f5", minHeight: "100vh" }}
    >
      {/* Sidebar Drawer */}
      <Box
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          marginLeft: `20px`, // Dynamically apply left margin to match content's right margin
          marginTop: "24px", // Apply top margin to match the content box
          height: `700px`, // Dynamically match the content box's height
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            height: "100%", // Ensure the sidebar stretches to match the content height
          }}
        >
          <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
            {/* Title at Top Left */}
            <Typography
              variant="h6"
              sx={{ padding: "8px 0", fontWeight: "bold", textAlign: "left" }}
            >
              Messages
            </Typography>

            <List>
              {/* Messages Library */}
              <ListItemButton
                component={Link}
                to={`/property/${propertyId}/messages/library`}
                sx={{ padding: "12px 16px", borderRadius: "8px" }}
              >
                <ListItemText primary="Messages Library" />
              </ListItemButton>

              <Divider />

              {/* Messages Templates */}
              <ListItemButton
                component={Link}
                to={`/property/${propertyId}/messages/templates`}
                sx={{ padding: "12px 16px", borderRadius: "8px" }}
              >
                <ListItemText primary="Messages Templates" />
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </Box>

      {/* Main content area */}
      <Box
        ref={contentRef} // Set reference to the content box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "24px",
        }}
      >
        {/* Content Wrapper for Messages */}
        <Box
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
            width: "100%",
            maxWidth: "1000px",
            padding: "24px",
            minHeight: "300px",
            marginLeft: "24px", // Ensure spacing on both sides
            marginRight: `20px`, // Ensure dynamic spacing on the right
            flexGrow: 1, // Ensure it grows dynamically with the content
          }}
        >
          <Outlet />{" "}
          {/* Child content (Messages Library or Templates) passed here */}
        </Box>
      </Box>
    </Box>
  );
};

export default Msgs;
