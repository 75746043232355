import React from "react";
import ErrorMessage from "../atoms/ErrorMessage";
import InputField from "../atoms/Input";

interface LoginInputFieldProps {
  label: string;
  type: string;
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
}

const LoginInputField: React.FC<LoginInputFieldProps> = ({
  label,
  type,
  value,
  placeholder,
  onChange,
  errorMessage,
}) => (
  <div>
    <label className="font-medium text-gray-600">{label}</label>
    <InputField
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      id={""}
      label={""}
    />
    <ErrorMessage message={errorMessage} />
  </div>
);

export default LoginInputField;
