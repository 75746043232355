import { GuestFaqs } from "../../../features/public/guestPage/guest.types";

interface GuestFAQsOrganismeProps {
  guestFaqs: GuestFaqs | undefined;
}

const GuestFAQsOrganisme: React.FC<GuestFAQsOrganismeProps> = ({
  guestFaqs,
}) => {
  if (!guestFaqs || !guestFaqs.data || guestFaqs.data.length === 0) {
    return null;
  }

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold">{guestFaqs.title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {/* Render FAQ entries from API */}
        {guestFaqs.data.map((faq) => (
          <div key={faq.id} className="bg-white p-4 rounded-lg shadow">
            <strong>{faq.question}</strong>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuestFAQsOrganisme;
