import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignupMutation } from "./signup.service";
import { SignupErrors } from "./signup.types";
import SignUpTemplate from "../../../components/templates /Authentication/SignUpTemplate";

const SignUp = () => {
  const [signup, { isLoading }] = useSignupMutation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    selectedLanguage: "", // Added selectedLanguage to formData
  });
  const [errors, setErrors] = useState<SignupErrors>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleChange =
    (field: keyof typeof formData) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        [field]: e.target.value,
      });
      if (isSubmitted) {
        setErrors(validate()); // Revalidate if form was submitted
      }
    };

  // New function to handle language selection changes
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({
      ...formData,
      selectedLanguage: e.target.value,
    });
  };

  const validate = (): SignupErrors => {
    let validationErrors: SignupErrors = {};
    if (!formData.firstName)
      validationErrors.first_name = "First name is required";
    if (!formData.lastName)
      validationErrors.last_name = "Last name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      validationErrors.email = "Email is invalid";
    if (!formData.password) validationErrors.password = "Password is required";
    if (formData.password.length < 6)
      validationErrors.password = "Password must be at least 6 characters";
    if (formData.password !== formData.confirmPassword)
      validationErrors.password = "Passwords do not match";
    if (!formData.selectedLanguage)
      validationErrors.selectedLanguage = "Please select a language";

    return validationErrors;
  };

  const handleApiErrors = (err: any): SignupErrors => {
    const apiErrors: SignupErrors = {};
    if (err?.data) {
      if (err.data.email) apiErrors.email = err.data.email.join(" ");
      if (err.data.password) apiErrors.password = err.data.password.join(" ");
      if (err.data.non_field_errors)
        apiErrors.non_field_errors = err.data.non_field_errors.join(" ");
    }
    if (Object.keys(apiErrors).length === 0) {
      apiErrors.form = "Sign-up failed. Please try again.";
    }
    return apiErrors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true); // Mark the form as submitted
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await signup({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        language: formData.selectedLanguage, // Send selected language code to API
      }).unwrap();

      localStorage.setItem("otpAllowed", "true"); // Allow access to OTP
      navigate("/otp", { state: { email: formData.email } }); // Navigate to OTP screen with email
    } catch (err: any) {
      setErrors(handleApiErrors(err)); // Handle API errors
    }
  };

  return (
    <SignUpTemplate
      formData={formData}
      errors={isSubmitted ? errors : {}} // Only show errors if the form is submitted
      isLoading={isLoading}
      handleChange={handleChange}
      handleLanguageChange={handleLanguageChange} // Pass handleLanguageChange to SignUpTemplate
      handleSubmit={handleSubmit}
    />
  );
};

export default SignUp;
