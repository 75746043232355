import React from "react";

interface SocialLoginButtonProps {
  onClick: () => void;
  iconSrc: string;
  text: string;
  type?: "button" | "submit" | "reset"; // Add the 'type' prop here
}

const SocialLoginButton: React.FC<SocialLoginButtonProps> = ({
  onClick,
  iconSrc,
  text,
  type,
}) => (
  <button
    onClick={onClick}
    type={type}
    className="w-full flex items-center gap-2 justify-center border border-gray-300 rounded-lg py-2"
  >
    <img src={iconSrc} alt="icon" className="h-6 w-6" />
    <span>{text}</span>
  </button>
);

export default SocialLoginButton;
