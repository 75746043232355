import { GuestPropertyDetails } from "../../../features/public/guestPage/guest.types";

interface GuestPropertyDetailsOrganismeProps {
  guestPropertyDetails: GuestPropertyDetails | undefined;
}

const GuestPropertyDetailsOrganisme: React.FC<
  GuestPropertyDetailsOrganismeProps
> = ({ guestPropertyDetails }) => {
  if (!guestPropertyDetails) {
    return null;
  }

  return (
    <div>
      <div className="text-center">
        <h1 className="text-3xl font-bold">{guestPropertyDetails.data.name}</h1>
      </div>
      {guestPropertyDetails.data.main_image && (
        <div className="mt-8 flex justify-center">
          <img
            src={guestPropertyDetails.data.main_image}
            alt={guestPropertyDetails.data.name}
            className="rounded-lg shadow-md w-full max-w-3xl"
          />
        </div>
      )}
      {/* Property Address */}
      <div className="text-center mt-4">
        <p className="text-gray-600">
          {guestPropertyDetails.data.address &&
            `${guestPropertyDetails.data.address}`}
          {guestPropertyDetails.data.zip &&
            `, ${guestPropertyDetails.data.zip}`}
          {guestPropertyDetails.data.city &&
            ` ${guestPropertyDetails.data.city}`}
          {guestPropertyDetails.data.state &&
            `, ${guestPropertyDetails.data.state}`}
        </p>
        {/* Google Maps Link */}
        {guestPropertyDetails.data.latitude &&
          guestPropertyDetails.data.longitude && (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${guestPropertyDetails.data.latitude},${guestPropertyDetails.data.longitude}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline mt-2 block"
            >
              {"Google Maps"}
              {/* Todo: Add static text */}
            </a>
          )}
      </div>
    </div>
  );
};
export default GuestPropertyDetailsOrganisme;
