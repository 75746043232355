import React from "react";

interface ButtonProps {
  text: string;
  isLoading?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  size?: "sm" | "md" | "lg"; // Added size prop
  color?: "blue" | "gray" | "white" | "black"; // Added color prop to handle different styles
  type?: "button" | "submit" | "reset"; // Add the 'type' prop here
}

const Button: React.FC<ButtonProps> = ({
  text,
  isLoading = false,
  onClick,
  fullWidth = false,
  size = "md",
  color = "black",
  type,
}) => {
  const baseStyles = "rounded-lg py-2 px-4 text-white transition-all";
  const sizeClasses =
    size === "lg"
      ? "h-12 text-lg"
      : size === "sm"
      ? "h-8 text-sm"
      : "h-10 text-md";
  const colorClasses =
    color === "blue"
      ? "bg-blue-600 hover:bg-blue-700"
      : color === "gray"
      ? "bg-gray-600 hover:bg-gray-700"
      : color === "black"
      ? "bg-black hover:bg-gray-700"
      : "bg-white text-black border border-gray-300";

  return (
    <button
      className={`${baseStyles} ${sizeClasses} ${colorClasses} ${
        fullWidth ? "w-full" : ""
      } ${isLoading ? "opacity-70 cursor-not-allowed" : ""}`}
      onClick={onClick}
      type={type}
      disabled={isLoading}
    >
      {isLoading ? "Loading..." : text}
    </button>
  );
};

export default Button;
