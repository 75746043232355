import React, { ChangeEvent, FormEvent } from "react";
import Button from "../../atoms/Button"; // Reuse the Button component
import ErrorMessage from "../../atoms/ErrorMessage"; // Reuse the ErrorMessage component
import InputField from "../../atoms/Input";

interface ForgetPassFormProps {
  email: string;
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  errors: string | null;
  isLoading: boolean;
}

const ForgetPassForm: React.FC<ForgetPassFormProps> = ({
  email,
  handleEmailChange,
  handleSubmit,
  errors,
  isLoading,
}) => (
  <form
    onSubmit={handleSubmit}
    className="space-y-6 w-full max-w-lg mx-auto" // Updated to ensure full width and spacing
  >
    <div className="flex flex-col gap-4">
      <InputField
        id="email"
        type="email"
        placeholder="name@mail.com"
        value={email}
        onChange={handleEmailChange}
        label="Your email"
        errorMessage={errors || ""}
      />
    </div>
    <Button
      text="Send One-time password"
      isLoading={isLoading}
      fullWidth={true}
    />{" "}
    {/* Ensure full-width button */}
    {errors && <ErrorMessage message={errors} />}
  </form>
);

export default ForgetPassForm;
