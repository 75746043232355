import React, { FormEvent, ChangeEvent } from "react";
import Button from "../../atoms/Button";
import ErrorMessage from "../../atoms/ErrorMessage";
import InputField from "../../atoms/Input";
import { SignupErrors } from "../../../features/authentication/signup/signup.types";

interface SignUpFormProps {
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    selectedLanguage: string; // Add selectedLanguage to formData
  };
  errors: SignupErrors;
  isLoading: boolean;
  handleChange: (
    field: keyof SignUpFormProps["formData"]
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleLanguageChange: (e: ChangeEvent<HTMLSelectElement>) => void; // Add handler for language selection
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

// Define language options
const languageOptions = [
  { code: "en", name: "English" },
  { code: "fr", name: "French" },
  { code: "es", name: "Spanish" },
  { code: "de", name: "German" },
  { code: "ar", name: "Arabic" },
  { code: "zh", name: "Chinese" },
];

const SignUpForm: React.FC<SignUpFormProps> = ({
  formData,
  errors,
  isLoading,
  handleChange,
  handleLanguageChange,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit} className="space-y-6">
    <div className="mb-1 flex flex-col gap-4">
      {/* Language Selection */}
      <label htmlFor="language" className="font-medium">
        Select Language
      </label>
      <select
        id="language"
        value={formData.selectedLanguage}
        onChange={handleLanguageChange}
        className="border rounded-md p-2"
      >
        <option value="">Select Language</option>
        {languageOptions.map((language) => (
          <option key={language.code} value={language.code}>
            {language.name}
          </option>
        ))}
      </select>
      {errors.selectedLanguage && (
        <ErrorMessage message={errors.selectedLanguage} />
      )}

      {/* Other input fields */}
      <InputField
        label="First Name"
        type="text"
        placeholder="First Name"
        value={formData.firstName}
        onChange={handleChange("firstName")}
        errorMessage={errors.first_name}
        id={""}
      />
      <InputField
        label="Last Name"
        type="text"
        placeholder="Last Name"
        value={formData.lastName}
        onChange={handleChange("lastName")}
        errorMessage={errors.last_name}
        id={""}
      />
      <InputField
        label="Email"
        type="email"
        placeholder="name@mail.com"
        value={formData.email}
        onChange={handleChange("email")}
        errorMessage={errors.email}
        id={""}
      />
      <InputField
        label="Password"
        type="password"
        placeholder="Password"
        value={formData.password}
        onChange={handleChange("password")}
        errorMessage={errors.password}
        id={""}
      />
      <InputField
        label="Re-enter Password"
        type="password"
        placeholder="Re-enter Password"
        value={formData.confirmPassword}
        onChange={handleChange("confirmPassword")}
        errorMessage={errors.confirmPassword}
        id={""}
      />
    </div>
    <span className="text-red-600">{errors.non_field_errors}</span>
    <Button text="Register Now" isLoading={isLoading} fullWidth={true} />
    <p className="text-center text-gray-600 font-medium mt-4">
      Already have an account?{" "}
      <a href="/login" className="text-black">
        Sign In
      </a>
    </p>
  </form>
);

export default SignUpForm;
