import React, { useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Guide,
  Media,
} from "../../../features/athenticated/dashboard/properties/properties.types";
import GuideEditor from "./GuideEditor";
import ImageGridWidget from "./ImageGridWidget";
import { usePatchGuideImportantMutation } from "../../../features/athenticated/dashboard/PropertyGuides/guides.services"; // API hook for editing guides
import parse from "html-react-parser";
import ImageIcon from "@mui/icons-material/Image";

type GuideCardProps = {
  guide: Guide;
  index: number; // Pass the index of the guide in the list
  totalGuides: number; // Pass the total number of guides
  onDeleteGuide: () => void;
  onMoveGuideUp: (guideId: string) => void;
  onMoveGuideDown: (guideId: string) => void;
  propertyId: string;
  category: string | null;
  refetchGuides: () => void;
  isLoadingUp: boolean; // New prop to indicate loading for moving up
  isLoadingDown: boolean; // New prop to indicate loading for moving down
};

const GuideCard: React.FC<GuideCardProps> = ({
  guide,
  index,
  totalGuides,
  onDeleteGuide,
  onMoveGuideUp,
  onMoveGuideDown,
  propertyId,
  isLoadingUp, // Receive the loading state for up
  isLoadingDown, // Receive the loading state for down
  category,
  refetchGuides,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isImportant, setIsImportant] = useState(guide.is_important);
  const [patchGuideImportant] = usePatchGuideImportantMutation();

  const toggleImportant = async () => {
    const newImportantValue = !isImportant;
    setIsImportant(newImportantValue);

    try {
      await patchGuideImportant({
        id: guide.id!,
        is_important: newImportantValue,
      }).unwrap();
      refetchGuides();
    } catch (error) {
      console.error("Error updating is_important:", error);
      setIsImportant(isImportant);
    }
  };

  const handleConvertToCard = () => {
    setIsEditing(false);
  };

  const mainImage = guide.main_image ?? "transparent";

  return (
    <Grid item xs={12}>
      <Card
        elevation={3}
        sx={{
          marginBottom: 4,
          borderRadius: 4,
          backgroundColor: "#fff",
          width: "100%",
        }}
      >
        <CardContent>
          {isEditing ? (
            <GuideEditor
              onCancel={() => setIsEditing(false)}
              onConvertToCard={handleConvertToCard}
              guide={guide}
              propertyId={propertyId}
              category={category}
              refetchGuides={refetchGuides}
            />
          ) : (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center" flexGrow={1}>
                  {guide.main_image ? (
                    <Box
                      component="img"
                      src={guide.main_image || "transparent"}
                      alt="Guide Main Image"
                      sx={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        borderRadius: 4,
                        marginRight: 2,
                      }}
                    />
                  ) : (
                    <ImageIcon sx={{ fontSize: 40, color: "#9e9e9e" }} />
                  )}

                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {guide.name}
                  </Typography>
                </Box>

                <Box display="flex" flexDirection="column">
                  {/* Move Guide Up with Loading Spinner */}
                  <IconButton
                    onClick={() => onMoveGuideUp(guide.id!)}
                    disabled={index === 0 || isLoadingUp} // Disable if first or loading
                    sx={{ marginBottom: "8px" }}
                  >
                    {isLoadingUp ? (
                      <CircularProgress size={20} color="inherit" /> // Will inherit the color from the parent, which can be set to black
                    ) : (
                      <ArrowUpwardIcon />
                    )}
                  </IconButton>

                  {/* Move Guide Down with Loading Spinner */}
                  <IconButton
                    onClick={() => onMoveGuideDown(guide.id!)}
                    disabled={index === totalGuides - 1 || isLoadingDown} // Disable if last or loading
                    sx={{ marginBottom: "8px" }}
                  >
                    {isLoadingDown ? (
                      <CircularProgress size={20} color="inherit" /> // Will inherit the color from the parent, which can be set to black
                    ) : (
                      <ArrowDownwardIcon />
                    )}
                  </IconButton>

                  <IconButton onClick={toggleImportant}>
                    {isImportant ? (
                      <StarIcon sx={{ color: "#FFD700" }} />
                    ) : (
                      <StarBorderIcon sx={{ color: "#000" }} />
                    )}
                  </IconButton>
                  <IconButton
                    onClick={() => setIsEditing(true)}
                    sx={{
                      backgroundColor: "#f0f0f0",
                      padding: "8px",
                      borderRadius: "50%",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                      marginTop: "8px",
                    }}
                  >
                    <EditIcon sx={{ color: "#000", fontSize: "20px" }} />
                  </IconButton>
                  <IconButton
                    onClick={onDeleteGuide}
                    sx={{
                      backgroundColor: "#f0f0f0",
                      padding: "8px",
                      borderRadius: "50%",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                      marginTop: "8px",
                    }}
                  >
                    <DeleteOutlineIcon
                      sx={{ color: "#f44336", fontSize: "20px" }}
                    />
                  </IconButton>
                </Box>
              </Box>

              <Box mt={2}>
                <Typography
                  variant="h6"
                  sx={{ color: "#000", marginBottom: 1 }}
                >
                  Description
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#666", marginTop: 1 }}
                >
                  {parse(guide.description)}
                </Typography>
              </Box>

              <ImageGridWidget
                medias={guide.medias}
                setMedias={() => {}}
                isEditing={false}
                onDelete={() => {}}
                onRetryUpload={() => {}}
                moveMedia={() => {}}
              />
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GuideCard;
