import endpoints from "../../../api/endpoints";
import { apiSlice } from "../../../services/baseApi"; // Adjust the path to your baseApi
import { ISignUpRequest, ISignUpResponse } from "./signup.types"; // We'll define these types next

// Inject the signup endpoint into the base API
const signupApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation<ISignUpResponse, ISignUpRequest>({
      query: (body) => ({
        url: endpoints.signup, // Replace with your actual signup endpoint
        method: "POST",
        body, // Send the form data as the request body
      }),
    }),
  }),
});

// Export the hook for the signup mutation
export const { useSignupMutation } = signupApi;
export {};
