import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { useGetPropertyByIdQuery } from "./propertyDetails.services";
import PropertyDetailsOrganism from "../../../../components/organisms /PropertyDetailsOrganism";

const PropertyDetailsFeature: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Safely use `useParams` with expected types
  const {
    data: property,
    isLoading,
    error,
  } = useGetPropertyByIdQuery(id ?? "");
  const [isEditing, setIsEditing] = useState(false); // To track if in edit mode

  // Error handling and loading logic
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Alert severity="error">
          Failed to load property details. Please try again later.
        </Alert>
      </div>
    );
  }

  if (!property) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Alert severity="warning">No property data available.</Alert>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <PropertyDetailsOrganism
        property={property} // Removed the force unwrapping `!`
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </div>
  );
};

export default PropertyDetailsFeature;
