import React from "react";
import SharedLayout from "../../../utils/shared/sharedLayout";
import ForgetPassForm from "../../organisms /Authentication/ForgetPassForm";

interface ForgetPassTemplateProps {
  email: string;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  errors: string | null;
  isLoading: boolean;
}

const ForgetPassTemplate: React.FC<ForgetPassTemplateProps> = ({
  email,
  handleEmailChange,
  handleSubmit,
  errors,
  isLoading,
}) => (
  <SharedLayout>
    <div className="w-full max-w-lg">
      <div className="text-center mb-8">
        <h2 className="font-bold text-4xl mb-4">Forgot Password</h2>
        <p className="text-lg font-normal text-gray-600">
          Enter your email to receive the One-time password.
        </p>
      </div>
      <ForgetPassForm
        email={email}
        handleEmailChange={handleEmailChange}
        handleSubmit={handleSubmit}
        errors={errors}
        isLoading={isLoading}
      />
    </div>
  </SharedLayout>
);

export default ForgetPassTemplate;
