import React, { useState } from "react";
import { Button, TextField, Grid, Box, Typography } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import {
  Media,
  Guide,
  UpdateGuideRequest,
  AddGuideRequest,
} from "../../../features/athenticated/dashboard/properties/properties.types";
import ImageGridWidget from "./ImageGridWidget";
import {
  useAddGuideMutation,
  useEditGuideMutation,
  useUploadMediaMutation,
  useEditGuideMainImageMutation,
} from "../../../features/athenticated/dashboard/PropertyGuides/guides.services";
import { Editor } from "@tinymce/tinymce-react";

type GuideEditorProps = {
  guide?: Guide;
  propertyId: string;
  category: string | null;
  refetchGuides: () => void;
  onCancel: () => void;
  onConvertToCard: () => void;
};

const GuideEditor: React.FC<GuideEditorProps> = ({
  guide,
  propertyId,
  category,
  onCancel,
  refetchGuides,
  onConvertToCard,
}) => {
  const [name, setName] = useState(guide?.name || "");
  const [description, setDescription] = useState(guide?.description || "");
  const [isImportant, setIsImportant] = useState(guide?.is_important || false);
  const [medias, setMedias] = useState<Media[]>(guide?.medias || []);
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [mainImagePreview, setMainImagePreview] = useState<string | null>(
    guide?.main_image
      ? typeof guide.main_image === "string" // If it's a string (URL)
        ? guide.main_image
        : URL.createObjectURL(guide.main_image) // If it's a File
      : null
  );

  const [uploadError, setUploadError] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);

  const [addGuide] = useAddGuideMutation();
  const [editGuide] = useEditGuideMutation();
  const [uploadMedia] = useUploadMediaMutation();
  const [editGuideMainImage] = useEditGuideMainImageMutation();

  // Handle main image file selection
  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploadError(null);
      setMainImageFile(file);
      setMainImagePreview(URL.createObjectURL(file));
    }
  };

  const handleMoveMedia = (fromIndex: number, toIndex: number) => {
    const updatedMedias = [...medias];
    const [movedMedia] = updatedMedias.splice(fromIndex, 1); // Remove media from the current position
    updatedMedias.splice(toIndex, 0, movedMedia); // Insert media at the new position

    // Update the order for each media after reordering
    setMedias(
      updatedMedias.map((media, index) => ({
        ...media,
        order: (index + 1).toString(),
      }))
    );
  };

  // Handle media file upload
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setUploadError(null);

      Array.from(files).forEach((file) => {
        const tempMedia: Media = {
          media_id: Date.now().toString(),
          file: URL.createObjectURL(file),
          label: file.name,
          isLoading: true,
          error: false,
          originalFile: file,
        };

        setMedias((prevMedias) => [...prevMedias, tempMedia]);

        const formData = new FormData();
        formData.append("file", file);

        uploadMedia(formData)
          .unwrap()
          .then((response: { id: string; file: string }) => {
            const uploadedMedia: Media = {
              media_id: response.id,
              file: response.file,
              label: file.name,
              isLoading: false,
              error: false,
            };
            setMedias((prevMedias) =>
              prevMedias.map((media) =>
                media.media_id === tempMedia.media_id ? uploadedMedia : media
              )
            );
          })
          .catch(() => {
            setMedias((prevMedias) =>
              prevMedias.map((media) =>
                media.media_id === tempMedia.media_id
                  ? { ...media, isLoading: false, error: true }
                  : media
              )
            );
            setUploadError("Failed to upload media. Please try again.");
          });
      });
    }
  };

  // Retry uploading media in case of failure
  const handleRetryUpload = async (file: File, tempMediaId: string) => {
    setUploadError(null);
    setMedias((prevMedias) =>
      prevMedias.map((media) =>
        media.media_id === tempMediaId
          ? { ...media, isLoading: true, error: false }
          : media
      )
    );

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await uploadMedia(formData).unwrap();
      setMedias((prevMedias) =>
        prevMedias.map((media) =>
          media.media_id === tempMediaId
            ? {
                media_id: response.id,
                file: response.file,
                label: media.label,
                isLoading: false,
                error: false,
              }
            : media
        )
      );
    } catch (error) {
      console.error("Error retrying media upload:", error);
      setUploadError("Error retrying media upload. Please try again.");
      setMedias((prevMedias) =>
        prevMedias.map((media) =>
          media.media_id === tempMediaId
            ? { ...media, isLoading: false, error: true }
            : media
        )
      );
    }
  };

  const handleDeleteMedia = (mediaId: string) => {
    setMedias((prevMedias) =>
      prevMedias.filter((media) => media.media_id !== mediaId)
    );
  };

  const handleSaveGuide = async () => {
    try {
      // Filter valid medias
      const validMedias = medias
        .filter((media) => !media.isLoading && !media.error)
        .map((media, index) => ({
          media_id: media.media_id,
          order: (index + 1).toString(),
          file: media.file, // Ensure 'file' is included
          originalFile: media.originalFile, // Preserve originalFile for uploading
        }));

      console.log("Valid medias being uploaded:", validMedias);

      let savedGuide;

      if (guide?.id) {
        // Prepare guide data for updating (without main_image)
        const guideDataForUpdate: UpdateGuideRequest = {
          id: guide.id,
          name,
          description,
          property: propertyId,
          category: category ?? "",
          is_important: isImportant,
          medias: validMedias.map(({ originalFile, ...rest }) => rest), // Send only necessary data for media
        };

        // Update the guide (excluding the main image)
        savedGuide = await editGuide(guideDataForUpdate).unwrap();
        console.log("Guide updated successfully:", savedGuide);
      } else {
        // Prepare guide data for adding (without main_image)
        const guideDataForAdd: AddGuideRequest = {
          name,
          description,
          property: propertyId,
          category: category ?? "",
          is_important: isImportant,
          medias: validMedias.map(({ originalFile, ...rest }) => rest), // Send only necessary data for media
        };

        console.log("Guide data for adding:", guideDataForAdd);

        // Add the guide (excluding the main image)
        savedGuide = await addGuide(guideDataForAdd).unwrap();
        console.log("Guide added successfully:", savedGuide);
      }

      // Once the guide is saved, handle the media upload first
      if (validMedias.length > 0 && savedGuide?.id) {
        console.log("Starting media upload for guide:", savedGuide.id);

        await Promise.all(
          validMedias.map(async (media) => {
            try {
              if (media.originalFile) {
                const formData = new FormData();
                formData.append("file", media.originalFile);

                // Upload media files one by one (or batch, depending on backend support)
                const uploadedMedia = await uploadMedia(formData).unwrap();
                console.log("Media uploaded successfully:", uploadedMedia);
              }
            } catch (error) {
              console.error("Error uploading media:", error);
            }
          })
        );
      }

      // After all media uploads are finished, handle the main image if it exists
      if (mainImageFile && savedGuide?.id) {
        console.log(
          "Starting main image upload after media upload completion."
        );

        const formData = new FormData();
        formData.append("main_image", mainImageFile);

        console.log("Main image formData:", formData);

        // Patch the main image separately after the guide is saved
        await editGuideMainImage({
          id: savedGuide.id,
          image: mainImageFile,
        }).unwrap();
        console.log("Main image uploaded successfully.");
      }

      console.log("All guide updates completed successfully.");

      // Refetch guides and return to card view
      refetchGuides();
      onConvertToCard();
    } catch (error) {
      console.error("Error saving guide:", error);
      setSaveError("Failed to save guide. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        borderRadius: guide?.id ? "none" : 4,
        padding: guide?.id ? 0 : 2,
        backgroundColor: guide?.id ? "transparent" : "#fff",
        width: "100%",
      }}
    >
      <Grid container spacing={3}>
        {/* Guide Name and Main Image */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            {/* Main Image with Clickable Upload */}
            <Box
              sx={{
                width: 120,
                height: 120,
                borderRadius: "8px",
                backgroundColor: "#f0f0f0",
                backgroundImage: `url(${
                  mainImagePreview || "https://via.placeholder.com/150"
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
                marginRight: 2,
              }}
              onClick={() =>
                document.getElementById("main-image-upload")?.click()
              }
            />
            <input
              type="file"
              id="main-image-upload"
              hidden
              accept="image/*"
              onChange={handleMainImageChange}
            />
            <TextField
              label="Guide Name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                borderRadius: "10px",
                backgroundColor: "#f7f7f7",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        </Grid>

        {/* Description Editor */}
        <Grid item xs={12}>
          <Editor
            value={description}
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            init={{
              height: 300,
              menubar: false,
              plugins: ["link", "lists", "autolink", "image"],
              toolbar:
                "bold | bullist outdent indent | addNonTranslatable removeNonTranslatable",
              images_upload_url: "/upload-image",
              automatic_uploads: true,
              file_picker_types: "image",
              content_style: `
                span[translate="no"]::before {
                  content: '[Non-Translatable] (';
                  color: #ffdd57;
                  font-weight: bold;
                }
                span[translate="no"]::after {
                  content: ')';
                  color: #ffdd57;
                  font-weight: bold;
                }
                span[translate="no"] {
                  color: #000;
                  padding: 0 2px;
                }
              `,
              setup: (editor) => {
                editor.ui.registry.addButton("addNonTranslatable", {
                  text: "Add Non-Translatable",
                  tooltip: "Mark as Non-Translatable",
                  onAction: () => {
                    const selectedContent = editor.selection.getContent({
                      format: "html",
                    });
                    if (!selectedContent.includes('translate="no"')) {
                      editor.insertContent(
                        `<span translate="no">${selectedContent}</span>`
                      );
                    }
                  },
                });

                editor.ui.registry.addButton("removeNonTranslatable", {
                  text: "Remove Non-Translatable",
                  tooltip: "Unmark as Non-Translatable",
                  onAction: () => {
                    const selectedNode = editor.selection.getNode();
                    if (
                      selectedNode &&
                      selectedNode.nodeName === "SPAN" &&
                      selectedNode.getAttribute("translate") === "no"
                    ) {
                      editor.dom.remove(selectedNode, true); // Removes the span but keeps the text
                    }
                  },
                });
              },
            }}
            onEditorChange={(content) => setDescription(content)}
          />
        </Grid>
      </Grid>

      {/* Upload Medias */}
      <Box sx={{ marginY: 2 }}>
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUpload />}
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "12px",
            paddingX: 3,
            paddingY: 1,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
          }}
        >
          Upload Media
          <input type="file" hidden multiple onChange={handleFileChange} />
        </Button>
        {uploadError && (
          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
            {uploadError}
          </Typography>
        )}
      </Box>

      <ImageGridWidget
        medias={medias}
        setMedias={setMedias}
        isEditing={true}
        onDelete={handleDeleteMedia}
        moveMedia={handleMoveMedia}
        onRetryUpload={handleRetryUpload} // Restored the retry upload function
      />

      {saveError && (
        <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
          {saveError}
        </Typography>
      )}

      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleSaveGuide}
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "12px",
              paddingX: 3,
              paddingY: 1,
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
            }}
          >
            {guide?.id ? "Save Changes" : "Add Guide"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={onCancel}
            sx={{
              borderColor: "#000",
              color: "#000",
              borderRadius: "12px",
              paddingX: 3,
              paddingY: 1,
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuideEditor;
