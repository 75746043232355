import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { setAuthPrompt } from "../../../redux/Slices/appStateSlice";

const AuthStatus: React.FC = () => {
  const open = useSelector((state: RootState) => state.appState.authPrompt);
  const requiresAuth = useSelector(
    (state: RootState) => state.appState.requiresAuth
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (open && requiresAuth) {
      const timer = setTimeout(() => {
        dispatch(setAuthPrompt(false));
        navigate("/login");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [open, requiresAuth, dispatch, navigate]);

  if (!open || !requiresAuth) return null;

  return (
    <Dialog open={open} onClose={() => {}}>
      <DialogTitle>Authentication Required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You need to be logged in to access this page. Redirecting to login...
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AuthStatus;
