import React, { useState, useMemo, useEffect, useCallback } from "react";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import {
  useGetPropertiesReservationQuery,
  useGetReservationsQuery,
} from "./calendar.services";
import CalendarToolbar from "../../../../components/organisms /Calendar/ToolbarComponent";
import TimelineComponent from "../../../../components/organisms /Calendar/TimelineComponent";
import Sidebar from "../../../../components/organisms /Calendar/SideBarComponent";

const Calendar: React.FC = () => {
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleTimeStart, setVisibleTimeStart] = useState(
    moment().startOf("week").valueOf()
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment().startOf("week").add(14, "days").valueOf() // End exactly after 14 days
  );

  const [reservationDate, setReservationDate] = useState<Date | null>(null);

  const { data: reservations = [], isLoading: reservationsLoading } =
    useGetReservationsQuery();
  const { data: properties = [], isLoading: propertiesLoading } =
    useGetPropertiesReservationQuery();

  // Memoized callback to handle item clicks
  const handleItemClick = useCallback((itemId: string) => {
    setSelectedBookingId(itemId);
  }, []);

  // Memoize filtered reservations
  const filteredReservations = useMemo(() => {
    return reservations.filter((reservation) => {
      const { id, title, source, status, arrival, departure } = reservation;
      const property = properties.find(
        (property) => property.id === reservation.property
      );

      const reservationMatches =
        id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        source?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        moment(arrival).format("YYYY-MM-DD").includes(searchQuery) ||
        moment(departure).format("YYYY-MM-DD").includes(searchQuery);

      const propertyMatches =
        property?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        property?.internal_name
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        property?.address?.toLowerCase().includes(searchQuery.toLowerCase());

      return reservationMatches || propertyMatches;
    });
  }, [reservations, properties, searchQuery]);

  // Effect to handle reservation date changes
  useEffect(() => {
    if (filteredReservations.length === 1) {
      setReservationDate(moment(filteredReservations[0].arrival).toDate());
    } else {
      setReservationDate(null);
    }
  }, [filteredReservations]);

  // Memoize groups and items to prevent re-renders
  const groups = useMemo(() => {
    return properties.map((property) => ({
      id: property.id,
      content: property.internal_name || property.name,
    }));
  }, [properties]);

  const items = useMemo(() => {
    return filteredReservations.map((reservation) => {
      const status = reservation.status.toLowerCase();

      const backgroundColor =
        status === "booked"
          ? "#4caf50" // Green
          : status === "pending"
          ? "#ff9800" // Orange
          : "#f44336"; // Red for cancelled

      return {
        id: reservation.id,
        group: reservation.property,
        content: reservation.title,
        start: moment(reservation.arrival).toDate(),
        end: moment(reservation.departure).toDate(),
        style: `background-color: ${backgroundColor};`,
      };
    });
  }, [filteredReservations]);

  if (reservationsLoading || propertiesLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box
      sx={{ padding: "20px", backgroundColor: "#f9f9f9", minHeight: "100vh" }}
    >
      {/* Color Coding Legend */}
      <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box
            sx={{ width: "15px", height: "15px", backgroundColor: "#4caf50" }}
          />
          <Typography variant="body2">Booked</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box
            sx={{ width: "15px", height: "15px", backgroundColor: "#ff9800" }}
          />
          <Typography variant="body2">Pending</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box
            sx={{ width: "15px", height: "15px", backgroundColor: "#f44336" }}
          />
          <Typography variant="body2">Cancelled</Typography>
        </Box>
      </Box>
      {/* <CalendarToolbar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        colorMode={colorMode}
        setColorMode={setColorMode}
        availableSources={[]}
        availableStatuses={[]}
      /> */}
      {/* TimelineComponent should always be rendered */}
      <TimelineComponent
        setVisibleTimeStart={setVisibleTimeStart} // Pass these state setters
        setVisibleTimeEnd={setVisibleTimeEnd}
        groups={groups}
        items={items}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        handleItemClick={handleItemClick}
        searchQuery={searchQuery}
        reservationDate={reservationDate || undefined}
        isLoading={false} // Always pass isLoading=false to prevent re-renders
      />
      <Sidebar
        isOpen={!!selectedBookingId}
        onClose={() => setSelectedBookingId(null)}
        reservationId={selectedBookingId}
      />
    </Box>
  );
};

export default Calendar;
