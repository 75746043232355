import React, { ChangeEvent, FormEvent } from "react";
import { Link } from "react-router-dom";
import Button from "../../atoms/Button";
import LoginInputField from "../../molecules /LoginInputField";
import SocialLoginButton from "../../molecules /SocialLoginButton";
import { LoginErrors } from "../../../features/authentication/login/login.types";
import ErrorMessage from "../../atoms/ErrorMessage";

interface LoginFormProps {
  handleLogin: (e: FormEvent<HTMLFormElement>) => void;
  handleGoogleSignIn: () => void;
  isLoading: boolean;
  errors: LoginErrors;
  authentication: { email: string; password: string };
  handleChange: (
    field: "email" | "password",
    isManualInput?: boolean
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  handleLogin,
  handleGoogleSignIn,
  isLoading,
  errors,
  authentication,
  handleChange,
}) => (
  <form onSubmit={handleLogin} className="space-y-6">
    <LoginInputField
      label="Your email"
      type="email"
      value={authentication.email}
      placeholder="name@mail.com"
      onChange={handleChange("email", true)}
      errorMessage={errors.email}
    />
    <LoginInputField
      label="Password"
      type="password"
      value={authentication.password}
      placeholder="********"
      onChange={handleChange("password", true)}
      errorMessage={errors.password}
    />
    {errors.non_field_errors && (
      <span className="text-red-600">{errors.non_field_errors}</span>
    )}

    {/* Sign In Button - should be of type 'submit' */}
    <Button
      type="submit"
      isLoading={isLoading}
      text="Sign In"
      fullWidth={true}
    />

    <div className="flex justify-end mt-2">
      <Link to="/forgetpass" className="text-black underline">
        Forgot Password?
      </Link>
    </div>

    {/* Google Sign-In Button - should be of type 'button' */}
    <SocialLoginButton
      type="button"
      onClick={handleGoogleSignIn}
      iconSrc="/img/google-icon-logo.svg"
      text="Sign in with Google"
    />

    <p className="text-center text-gray-600">
      Not registered?{" "}
      <Link to="/signup" className="text-black underline">
        Sign up
      </Link>
    </p>

    <p className="text-center text-gray-600">
      <Link to="/PrivacyAndPolicy" className="text-black underline">
        Privacy and Policy
      </Link>
    </p>
  </form>
);

export default LoginForm;
