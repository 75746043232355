import React, { useState } from "react";
import { useLoginMutation, useGoogleLoginMutation } from "./login.service";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../googleLogin/firebase_config";
import LoginTemplate from "../../../components/templates /Authentication/LoginTemplate";
import { LoginErrors } from "./login.types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [loginCall, { isLoading }] = useLoginMutation();
  const [googleLoginCall] = useGoogleLoginMutation();
  const [authentication, setAuth] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState<LoginErrors>({});

  const navigate = useNavigate();

  const handleChange =
    (field: keyof typeof authentication, isManualInput: boolean = true) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(
        `[handleChange] Field: ${field}, Value: ${e.target.value}, Manual: ${isManualInput}`
      );

      setAuth((prevState) => {
        console.log(`[handleChange] Updating state:`, {
          ...prevState,
          [field]: e.target.value,
        });
        return {
          ...prevState,
          [field]: e.target.value,
        };
      });

      // Only clear errors if it's a manual input
      if (isManualInput) {
        setErrors((prevState) => {
          console.log(`[handleChange] Clearing errors for field: ${field}`);
          return {
            ...prevState,
            [field]: undefined,
          };
        });
      }
    };

  const validate = (): LoginErrors => {
    const errors: LoginErrors = {};

    // Validate email
    if (!authentication.email) errors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(authentication.email))
      errors.email = "Email is invalid";

    // Validate password
    if (!authentication.password) errors.password = "Password is required";
    else if (authentication.password.length < 6)
      errors.password = "Password must be at least 6 characters";

    return errors;
  };

  const handleApiErrors = (err: any): LoginErrors => {
    const apiErrors: LoginErrors = {};
    if (err?.data) {
      if (err.data.email) apiErrors.email = err.data.email.join(" ");
      if (err.data.password) apiErrors.password = err.data.password.join(" ");
      if (err.data.non_field_errors)
        apiErrors.non_field_errors = err.data.non_field_errors.join(" ");

      // Check if user is not verified
      if (err.data.verified && err.data.verified[0] === "False") {
        apiErrors.form = "User account is not verified.";
        apiErrors.notVerified = true; // Custom field to indicate unverified user
      }
    }
    if (Object.keys(apiErrors).length === 0) {
      apiErrors.form = "Login failed. Please try again.";
    }
    return apiErrors;
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("[handleLogin] Starting login process");

    const validationErrors = validate();
    console.log("[handleLogin] Validation errors:", validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const result = await loginCall({
        email: authentication.email,
        password: authentication.password,
      }).unwrap();

      if (result.access_token) {
        console.log("[handleLogin] Login successful, access token received");
        navigate("/properties");
      } else {
        console.error("[handleLogin] Login failed: No access token received");
      }
    } catch (err: any) {
      console.error("[handleLogin] Login failed:", err);
      const apiErrors = handleApiErrors(err);
      setErrors(apiErrors);
    }
  };

  const handleGoogleSignIn = async () => {
    console.log(`[handleGoogleSignIn] Attempting Google sign-in`);
    setErrors({}); // Clear previous errors

    try {
      // Step 1: Sign in with Google Popup
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const googleAccessToken = credential?.accessToken;

      // Step 2: Check if the Google access token is available
      if (!googleAccessToken) {
        console.log("[handleGoogleSignIn] No Google access token found.");
        toast.error("Google sign-in failed. No access token found.");
        setErrors({ form: "Google sign-in failed. No access token found." });
        return;
      }

      const googleEmail = result.user.email || "unknown-email@example.com";
      console.log(`[handleGoogleSignIn] Google email: ${googleEmail}`);

      // Step 3: Authenticate with your backend using the Google access token
      try {
        const googleLoginResult = await googleLoginCall({
          email: googleEmail,
          password: googleAccessToken,
        }).unwrap();

        // Step 4: Store tokens in local storage
        localStorage.setItem("access_token", googleLoginResult.access_token);
        localStorage.setItem("refresh_token", googleLoginResult.refresh_token);

        // Step 5: Navigate immediately after successful login
        console.log("Google sign-in successful. Navigating to homepage...");
        toast.success("Google sign-in successful! Redirecting...");
        navigate("/properties");

        // Step 6: Optionally set authentication state afterward
        setAuth((prevState) => ({
          ...prevState,
          email: googleEmail,
        }));
      } catch (err: any) {
        // Step 7: Handle API errors
        console.error("Google API login failed:", err);
        const googleErrors = handleApiErrors(err);
        setErrors(googleErrors);

        // Optionally update authentication state even if login fails
        setAuth((prevState) => ({
          ...prevState,
          email: googleEmail,
        }));
      }
    } catch (error: any) {
      console.error("[handleGoogleSignIn] Google sign-in failed:", error);

      // Handle Firebase-specific errors
      if (error.code) {
        switch (error.code) {
          case "auth/cancelled-popup-request":
            toast.warn("Sign-in process was cancelled. Please try again.");
            break;
          case "auth/popup-blocked":
            toast.error("Popup blocked. Please allow popups in your browser.");
            break;
          default:
            toast.error(`Google sign-in failed: ${error.message}`);
            break;
        }
      }

      // Handle general errors
      if (error?.data?.non_field_errors) {
        const apiErrorMessage =
          error.data.non_field_errors[0] || "Unexpected error occurred.";
        toast.error(apiErrorMessage);
        setErrors({ form: apiErrorMessage });
      } else if (error?.message) {
        toast.error(error.message);
        setErrors({ form: error.message });
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <LoginTemplate
      handleLogin={handleLogin}
      handleGoogleSignIn={handleGoogleSignIn}
      isLoading={isLoading}
      errors={errors} // Show errors immediately
      authentication={authentication}
      handleChange={handleChange}
    />
  );
};

export default Login;
