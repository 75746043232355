import React, { ChangeEvent, FormEvent } from "react";
import SharedLayout from "../../../utils/shared/sharedLayout";
import { SignupErrors } from "../../../features/authentication/signup/signup.types";
import SignUpForm from "../../organisms /Authentication/SignUpForm";

interface SignUpTemplateProps {
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    selectedLanguage: string; // Added selectedLanguage to formData
  };
  errors: SignupErrors;
  isLoading: boolean;
  handleChange: (
    field: keyof SignUpTemplateProps["formData"]
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleLanguageChange: (e: ChangeEvent<HTMLSelectElement>) => void; // Added handler for language selection
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const SignUpTemplate: React.FC<SignUpTemplateProps> = ({
  formData,
  errors,
  isLoading,
  handleChange,
  handleLanguageChange, // Added handleLanguageChange
  handleSubmit,
}) => (
  <SharedLayout>
    <div className="w-full max-w-lg">
      <div className="text-center mb-8">
        <h2 className="font-bold text-4xl mb-4">Sign Up</h2>
        <p className="text-lg font-normal text-gray-600">
          Please enter your details to create an account.
        </p>
      </div>
      <SignUpForm
        formData={formData}
        errors={errors}
        isLoading={isLoading}
        handleChange={handleChange}
        handleLanguageChange={handleLanguageChange} // Pass the handler to SignUpForm
        handleSubmit={handleSubmit}
      />
    </div>
  </SharedLayout>
);

export default SignUpTemplate;
