import React, { useState } from "react";
import { Grid, CircularProgress, Box, Alert, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useMessagesPage } from "../../../../hooks/useMessagesPage";
import MessageSelector from "../../../../components/molecules /PropertyMessages/MessageSelector";
import MessageForm from "../../../../components/organisms /PropertyMessages/MessageForm";
import {
  checkSchedulesWithMessages,
  ScheduleKey,
  useGetMessagesByPropertyQuery,
} from "./messageService";

const MessagesTemplates: React.FC = () => {
  const { id: propertyId } = useParams<{ id: string }>();
  const [multipleNights, setMultipleNights] = useState(false); // Checkbox state

  const {
    schedules,
    selectedSchedule,
    messageDetails,
    setMessageDetails,
    handleMessageChange,
    isLoadingSchedules,
    isLoadingMessages,
    schedulesError,
    messagesError,
  } = useMessagesPage(propertyId);

  const { data: messagesData = [], refetch } = useGetMessagesByPropertyQuery(
    propertyId ?? ""
  );

  // Step 1: Debugging checkSchedulesWithMessages output
  const schedulesWithTemplates =
    schedules && messagesData
      ? checkSchedulesWithMessages(schedules, messagesData)
      : schedules;
  console.log("Schedules with Templates:", schedulesWithTemplates);

  // Define a union type for the keys in `scheduleKeyMap`
  // Define the `scheduleKeyMap` object with keys matching `ScheduleKey` type
  const scheduleKeyMap: Record<ScheduleKey, string> = {
    "Reservation creation - Immediately": "CREATION",
    "Arrival - 30 Days before": "ARRIVAL_30B",
    "Arrival - 7 Days before": "ARRIVAL_7B",
    "Arrival - 1 Day before": "ARRIVAL_1B",
    Arrival: "ARRIVAL",
    "Arrival - 1 Day after": "ARRIVAL_1A",
    "Departure - 1 Day before": "DEPARTURE_1B",
    Departure: "DEPARTURE",
    "Departure - 1 Day after": "DEPARTURE_1A",
  };

  const templateExists = (scheduleKey: ScheduleKey): boolean => {
    const normalizedKey = scheduleKeyMap[scheduleKey];

    return schedulesWithTemplates.some((schedule) => {
      if (schedule.key !== normalizedKey || !schedule.hasTemplate) {
        return false; // No matching template
      }

      // Get all relevant messages for the schedule
      const relevantMessages = messagesData.filter(
        (msg) => msg.schedule === schedule.key
      );

      // Check if there's at least one message with an active toggle
      return relevantMessages.some(
        (msg) => msg.by_email || msg.by_whatsapp || msg.by_source
      );
    });
  };

  if (isLoadingSchedules || isLoadingMessages) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (schedulesError || messagesError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <Alert severity="error">
          Error loading data. Please try again later.
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" component="h1" sx={{ marginBottom: "24px" }}>
        Message Templates
      </Typography>

      <Grid container spacing={3}>
        {/* Events on the left side */}
        <Grid item xs={3}>
          <MessageSelector
            selectedMessage={selectedSchedule.value}
            onChange={handleMessageChange}
            messages={schedules}
            templateExists={templateExists} // Pass template checking function
          />
        </Grid>

        {/* Form on the right side */}
        <Grid item xs={9}>
          {selectedSchedule.value && (
            <MessageForm
              messageDetails={messageDetails}
              setMessageDetails={setMessageDetails}
              propertyId={propertyId ?? ""}
              selectedScheduleKey={selectedSchedule.key}
              refetchMessages={refetch} // Pass refetch to MessageForm
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessagesTemplates;
