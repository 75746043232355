import React from "react";
import {
  Category,
  Guide,
  Media,
} from "../../../features/athenticated/dashboard/properties/properties.types";
import GuideOverview from "../../organisms /PropertyGuide/GuideOverview";

type GuidePageTemplateProps = {
  property: any; // Adjust based on your property type
  guides: Guide[];
  categories: Category[];
  selectedCategory: string | null;
  setSelectedCategory: (id: string) => void;
  onDeleteGuide: (id: string) => void;
  medias: Media[]; // Add medias prop to pass to the GuideOverview
  onAddCategory: (name: string, image: File) => void;
  onEditCategory: (id: string, name: string, image?: File | null) => void; // Accept image as File or null
  onDeleteCategory: (id: string) => void;
  isAddingGuide: boolean;
  setIsAddingGuide: (value: boolean) => void;
  propertyId: string; // Required property ID for adding/editing guides
  refetchGuides: () => void; // Pass the refetchGuides function
  moveCategoryUp: (id: string) => void; // Passing function to move category up
  moveCategoryDown: (id: string) => void; // Passing function to move category down
};

const GuidePageTemplate: React.FC<GuidePageTemplateProps> = ({
  property,
  guides,
  categories,
  selectedCategory,
  setSelectedCategory,
  onDeleteGuide,
  medias, // Pass medias to GuideOverview
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  isAddingGuide,
  setIsAddingGuide,
  propertyId,
  refetchGuides, // Add refetchGuides prop
  moveCategoryDown,
  moveCategoryUp,
}) => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-6">
        <GuideOverview
          property={property}
          guides={guides}
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          onDeleteGuide={onDeleteGuide}
          medias={medias} // Pass medias to GuideOverview
          onAddCategory={onAddCategory}
          onEditCategory={onEditCategory}
          onDeleteCategory={onDeleteCategory}
          isAddingGuide={isAddingGuide}
          setIsAddingGuide={setIsAddingGuide}
          propertyId={propertyId}
          refetchGuides={refetchGuides} // Pass refetchGuides to GuideOverview
          moveCategoryUp={moveCategoryUp}
          moveCategoryDown={moveCategoryDown}
        />
      </main>
    </div>
  );
};

export default GuidePageTemplate;
