import React, { useState } from "react";
import { useVerifyOtpMutation, useResendOtpMutation } from "./otp.service";
import { useNavigate } from "react-router-dom";
import OtpTemplate from "../../../components/templates /Authentication/OtpTemplate";

const Otp = ({ email }: { email: string }) => {
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if the form has been submitted
  const [verifyOtp, { isLoading: isVerifying }] = useVerifyOtpMutation();
  const [resendOtp, { isLoading: isResending }] = useResendOtpMutation();
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    if (isSubmitted) setErrors(null); // Clear errors on input change if the form was already submitted
  };

  const validate = (): string | null => {
    if (!otp) return "OTP is required.";
    return null;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true); // Mark form as submitted

    const validationError = validate();
    if (validationError) {
      setErrors(validationError);
      return;
    }

    try {
      const response = await verifyOtp({ email, code: otp }).unwrap();
      if (response.access_token) {
        navigate("/properties"); // Navigate to the welcome page on successful OTP verification
      }
    } catch (err: any) {
      setErrors("Failed to verify One-time password. Please try again.");
    }
  };

  const handleResendOtp = async () => {
    try {
      await resendOtp({ email }).unwrap();
    } catch (err) {
      setErrors("Failed to resend One-time password. Please try again.");
    }
  };

  return (
    <OtpTemplate
      otp={otp}
      errors={isSubmitted ? errors : null} // Only show errors after submission
      isVerifying={isVerifying}
      isResending={isResending}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      handleResendOtp={handleResendOtp}
    />
  );
};

export default Otp;
