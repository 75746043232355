import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import {
  useAddPropertyFaqMutation,
  useDeletePropertyFaqMutation,
  useEditPropertyFaqMutation,
  useGetPropertyFaqsQuery,
} from "./propertyFaqsService";
import { AddFaqRequest, Faq } from "./faq.types";
import PropertyFaqOrganism from "../../../../components/organisms /PropertyFaq/propertyFaqOrganism";

const PropertyFaqFeature: React.FC = () => {
  const { id: propertyId } = useParams<{ id: string }>();
  const [isEditing, setIsEditing] = useState(false);

  // Hooks for fetching and mutating FAQs
  const {
    data: faqs,
    isLoading,
    error,
  } = useGetPropertyFaqsQuery(propertyId || "");
  const [addFaq] = useAddPropertyFaqMutation();
  const [editFaq] = useEditPropertyFaqMutation();
  const [deleteFaq] = useDeletePropertyFaqMutation();
  const [editingFaqId, setEditingFaqId] = useState<string | null>(null);

  // Handler functions for CRUD operations
  const handleAddFaq = async (newFaq: AddFaqRequest) => {
    try {
      await addFaq(newFaq);
    } catch (err) {
      console.error("Failed to add FAQ:", err);
    }
  };

  console.log("FAQs in Component (should be updated):", faqs);

  const handleEditFaq = async (faqId: string, updatedFaq: Partial<Faq>) => {
    try {
      await editFaq({ id: faqId, ...updatedFaq });
    } catch (err) {
      console.error("Failed to edit FAQ:", err);
    }
  };

  const handleDeleteFaq = async (faqId: string) => {
    try {
      await deleteFaq(faqId);
    } catch (err) {
      console.error("Failed to delete FAQ:", err);
    }
  };

  const handleSaveFaq = async (updatedFaq: Faq) => {
    try {
      await handleEditFaq(updatedFaq.id, updatedFaq);
      setEditingFaqId(null); // Exit edit mode after saving
    } catch (err) {
      console.error("Failed to save FAQ:", err);
    }
  };

  const handleCancelEdit = () => {
    setEditingFaqId(null); // Exit edit mode without saving
  };

  // Loading state
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Alert severity="error">
          Failed to load FAQs. Please try again later.
        </Alert>
      </div>
    );
  }

  // // No data state
  if (!faqs) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Alert severity="warning">No FAQ data available.</Alert>
      </div>
    );
  }

  // Render component with fetched data
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <PropertyFaqOrganism
        key={faqs ? faqs.map((faq) => faq.id).join("-") : "no-data"}
        faqs={faqs}
        editingFaqId={editingFaqId}
        setEditingFaqId={async (faqId) => setEditingFaqId(faqId)} // Wrapped in async function
        onAddFaq={handleAddFaq}
        onEditFaq={async (faqId, updatedFaq) =>
          await handleEditFaq(faqId, updatedFaq)
        } // Adjusted for async
        onDeleteFaq={handleDeleteFaq}
        onSaveFaq={handleSaveFaq}
        onCancelEdit={handleCancelEdit}
        propertyId={propertyId ?? ""}
      />
    </div>
  );
};

export default PropertyFaqFeature;
