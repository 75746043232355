import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar, Alert } from "@mui/material";
import { Property } from "../../features/athenticated/dashboard/properties/properties.types";
import TableRow from "../molecules /TableRow";
import { useTranslation } from "react-i18next";

interface PropertiesTemplateProps {
  properties: Property[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
  currentPage: number;
  showHidden: boolean;
  loading: boolean; // Receive loading state
  fetched: boolean; // Receive fetched state
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onVisibilityChange: (id: string, newIsHidden: boolean) => void;
  isConnected: boolean; // Boolean flag for connection state
  onButtonClick: () => void; // Function to call API for fetching or connecting
  apiKey: string;
  setApiKey: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onConnect: () => void;
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
}

const PropertiesTemplate: React.FC<PropertiesTemplateProps> = ({
  properties,
  onNextPage,
  onPreviousPage,
  hasNext,
  hasPrevious,
  currentPage,
  showHidden,
  onCheckboxChange,
  onVisibilityChange,
  isConnected,
  onButtonClick,
  apiKey,
  setApiKey,
  onConnect,
  showSidebar,
  setShowSidebar,
  loading,
  fetched,
}) => {
  const safeProperties = properties || [];
  const [isNextLoading, setNextLoading] = useState(false);
  const [isPreviousLoading, setPreviousLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { t } = useTranslation(); // Initialize translation function

  const handleNextClick = () => {
    if (!hasNext) return;
    setNextLoading(true);
    onNextPage();
    setTimeout(() => setNextLoading(false), 1000);
  };

  const handlePreviousClick = () => {
    if (!hasPrevious) return;
    setPreviousLoading(true);
    onPreviousPage();
    setTimeout(() => setPreviousLoading(false), 1000);
  };

  const handleSnackbarClose = () => setOpenSnackbar(false);

  return (
    <div
      className="mt-12 mb-8 flex flex-col gap-4 px-6"
      style={{ height: "calc(100vh - 150px)" }}
    >
      {/* Properties Title with Navigation Icons, Checkbox, and Button */}
      <div className="flex justify-between items-center bg-black text-white p-4 rounded-t-lg">
        <h3 className="text-lg font-bold">{t("propertiesTemplate.title")}</h3>{" "}
        {/* Translated Title */}
        <div className="flex gap-4 items-center">
          {/* ... */}
          <div className="flex items-center gap-2">
            <label htmlFor="show-hidden" className="text-sm">
              {t("propertiesTemplate.showHidden")}{" "}
              {/* Translated Checkbox Label */}
            </label>
            <input
              id="show-hidden"
              type="checkbox"
              checked={showHidden}
              onChange={onCheckboxChange}
              className="cursor-pointer"
            />
          </div>

          <button
            onClick={onButtonClick}
            className="bg-white text-black px-4 py-2 rounded"
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center">
                <AiOutlineLoading3Quarters className="animate-spin mr-2" />
                {t("propertiesTemplate.fetching")}{" "}
                {/* Translated Loading Text */}
              </div>
            ) : fetched ? (
              t("propertiesTemplate.fetched")
            ) : (
              t("propertiesTemplate.fetchProperties")
            )}
          </button>
        </div>
      </div>

      {/* Properties List */}
      <div
        className="bg-white shadow-md rounded-lg p-4 flex flex-col gap-4 overflow-y-auto"
        style={{ height: "100%", padding: "16px" }}
      >
        {Array.isArray(safeProperties) && safeProperties.length > 0 ? (
          safeProperties.map((property, index) => (
            <div key={property.id} className={index === 0 ? "pt-0" : "pt-4"}>
              <TableRow
                id={property.id}
                image={property.main_image ?? ""}
                name={property.name}
                address={property.address ?? ""}
                guidesCount={property.number_of_guides ?? 0}
                messagesCount={property.number_of_messages ?? 0}
                isHidden={property.is_hidden}
                onVisibilityChange={() =>
                  onVisibilityChange(property.id, !property.is_hidden)
                }
                faqCount={property.number_of_faqs ?? 0}
              />
            </div>
          ))
        ) : (
          <div className="text-center py-4">
            {t("propertiesTemplate.noProperties")}
          </div>
        )}
      </div>

      {/* Sidebar for API key input */}
      <div
        className={`fixed top-0 right-0 w-80 h-full bg-white text-black p-4 shadow-lg transition-transform duration-300 ease-in-out ${
          showSidebar ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">
            {t("propertiesTemplate.connectTitle")}
          </h3>
          <button
            onClick={() => setShowSidebar(false)}
            className="text-black text-xl"
          >
            &times;
          </button>
        </div>
        <p className="mb-4">{t("propertiesTemplate.connectDescription")}</p>{" "}
        {/* Translated Sidebar Description */}
        <input
          type="text"
          placeholder={t("propertiesTemplate.enterApiKey")}
          value={apiKey}
          onChange={setApiKey}
          className="w-full p-2 border border-gray-300 rounded"
        />
        <button
          onClick={onConnect}
          className="w-full mt-4 bg-black p-2 rounded text-white"
        >
          {t("propertiesTemplate.connectButton")}{" "}
          {/* Translated Connect Button */}
        </button>
      </div>

      {/* Snackbar for errors */}
      {error && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {t("propertiesTemplate.error")} {/* Translated Snackbar Error */}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default PropertiesTemplate;
