import React, { CSSProperties } from "react";
import { TextField } from "@mui/material";

interface InputAtomProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties; // Allow custom inline styling
}

const InputAtom: React.FC<InputAtomProps> = ({
  label,
  value,
  onChange,
  style,
}) => (
  <TextField
    label={label}
    value={value}
    onChange={onChange}
    fullWidth
    style={style}
  />
);

export default InputAtom;
