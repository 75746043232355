import React from "react";
import PropertyDetails from "./PropertyDetails";
import GuideManagement from "./GuideManagement";
import {
  Category,
  Guide,
  Media,
} from "../../../features/athenticated/dashboard/properties/properties.types";

type GuideOverviewProps = {
  property: any; // Adjust this based on your property type
  categories: Category[];
  guides: Guide[];
  selectedCategory: string | null;
  setSelectedCategory: (id: string) => void;
  onDeleteGuide: (id: string) => void;
  medias: Media[]; // Pass medias for handling media state
  onAddCategory: (name: string, image: File) => void;
  onEditCategory: (id: string, name: string, image?: File | null) => void; // Accept image as File or null
  onDeleteCategory: (id: string) => void;
  isAddingGuide: boolean;
  setIsAddingGuide: (value: boolean) => void;
  propertyId: string; // Required property ID for adding/editing guides
  refetchGuides: () => void; // Pass the refetchGuides function
  moveCategoryUp: (id: string) => void; // Passing function to move category up
  moveCategoryDown: (id: string) => void; // Passing function to move category down
};

const GuideOverview: React.FC<GuideOverviewProps> = ({
  property,
  categories,
  guides,
  selectedCategory,
  setSelectedCategory,
  onDeleteGuide,
  medias, // Pass medias for managing guide media
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  isAddingGuide,
  refetchGuides,
  setIsAddingGuide,
  propertyId,
  moveCategoryDown,
  moveCategoryUp,
}) => {
  return (
    <div>
      {/* Property Details */}
      <PropertyDetails
        name={property.name}
        description={property.description}
        address={property.address}
        latitude={property.latitude}
        longitude={property.longitude}
        image={property.main_image}
        id={property.id}
      />

      {/* Guide Management */}
      <GuideManagement
        guides={guides}
        propertyId={propertyId}
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        onDeleteGuide={onDeleteGuide}
        medias={medias} // Pass medias to GuideManagement for handling guide media
        onAddCategory={onAddCategory}
        onEditCategory={onEditCategory}
        onDeleteCategory={onDeleteCategory}
        isAddingGuide={isAddingGuide}
        setIsAddingGuide={setIsAddingGuide}
        refetchGuides={refetchGuides}
        moveCategoryUp={moveCategoryUp}
        moveCategoryDown={moveCategoryDown}
      />
    </div>
  );
};

export default GuideOverview;
