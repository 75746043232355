// src/services/propertyFaqsService.ts
import { FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { apiSlice } from "../../../../services/baseApi";
import { Faq, AddFaqRequest, EditFaqRequest } from "./faq.types";

export const propertyFaqsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch FAQs for a specific property
    getPropertyFaqs: builder.query<Faq[], string>({
      query: (propertyId) => ({
        url: `/faqs/`,
        params: { property: propertyId },
      }),
      providesTags: [{ type: "Faqs", id: "LIST" }],
    }),

    // Add a new FAQ
    addPropertyFaq: builder.mutation<Faq, AddFaqRequest>({
      query: (newFaq) => ({
        url: `/faqs/`,
        method: "POST",
        body: newFaq,
      }),
      invalidatesTags: [{ type: "Faqs", id: "LIST" }],
    }),

    // Edit an existing FAQ
    editPropertyFaq: builder.mutation<Faq, EditFaqRequest>({
      query: ({ id, ...changes }) => ({
        url: `/faqs/${id}/`,
        method: "PATCH",
        body: changes,
      }),
      invalidatesTags: [{ type: "Faqs", id: "LIST" }],
    }),

    // Delete an FAQ
    deletePropertyFaq: builder.mutation<
      { success: boolean; id: string },
      string
    >({
      query: (id) => ({
        url: `/faqs/${id}/`,
        method: "DELETE",
      }),
      transformResponse: (
        response: void,
        meta: FetchBaseQueryMeta | undefined,
        arg
      ) => ({
        success: meta?.response?.status === 204, // Now meta is typed, allowing access to response
        id: arg, // Pass the ID sent for deletion
      }),
      invalidatesTags: [{ type: "Faqs", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPropertyFaqsQuery,
  useAddPropertyFaqMutation,
  useEditPropertyFaqMutation,
  useDeletePropertyFaqMutation,
} = propertyFaqsApi;
