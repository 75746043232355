import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Drawer,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
  Divider,
  Button,
  Alert,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme, styled } from "@mui/system";
import { useGetReservationDetailsQuery } from "../../../features/athenticated/dashboard/calendar/calendar.services";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import CountryFlag from "react-country-flag";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  reservationId: string | null;
}

const DetailItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: "12px",
  backgroundColor: "#f8f8f8",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.05)",
}));

const MainImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  borderRadius: "12px",
  marginBottom: theme.spacing(2),
}));

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onClose,
  reservationId,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view

  // Fetch reservation details when reservationId is passed
  const {
    data: reservationDetails,
    isLoading,
    isError,
  } = useGetReservationDetailsQuery(reservationId!, {
    skip: !reservationId,
  });

  useEffect(() => {
    if (reservationId) {
      console.log(`Fetching details for reservation ID: ${reservationId}`);
    }
  }, [reservationId]);

  const formatPhoneNumber = (
    phoneNumber: string | null | undefined,
    countryCode: CountryCode | undefined // Accepts countryCode dynamically
  ): string => {
    if (!phoneNumber) return "";

    // Add "+" to the phone number if it’s missing
    const formattedPhoneNumber = phoneNumber.startsWith("+")
      ? phoneNumber
      : `+${phoneNumber}`;

    const phoneNumberObj = parsePhoneNumberFromString(formattedPhoneNumber, {
      defaultCountry: countryCode,
    });
    return phoneNumberObj ? phoneNumberObj.formatInternational() : phoneNumber;
  };

  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // 24-hour format
    }).format(date);
  };

  // Function to handle copying the guest link
  const handleCopyClick = () => {
    if (reservationDetails?.guest_link) {
      navigator.clipboard.writeText(reservationDetails.guest_link);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    }
  };

  // Render the content inside Dialog or Drawer
  const renderContent = () => (
    <>
      {reservationDetails?.property.main_image && (
        <MainImage
          src={reservationDetails.property.main_image}
          alt={reservationDetails.property.name}
        />
      )}

      <Typography variant="h5" sx={{ fontWeight: 600, mb: 3 }}>
        Booking Details
      </Typography>

      <DetailItem>
        <Typography variant="body2" color="textSecondary">
          Guest
        </Typography>
        <Typography variant="h6">
          <CountryFlag
            countryCode={reservationDetails?.guest.country_code ?? ""}
            svg
            style={{ marginRight: 8 }}
          />
          {reservationDetails?.guest.name}
        </Typography>
        <Typography variant="body2">
          {reservationDetails?.guest.email}
        </Typography>
        <Typography variant="body2">
          {formatPhoneNumber(
            reservationDetails?.guest.phone_number,
            reservationDetails?.guest.country_code as CountryCode
          )}
        </Typography>

        <Box mt={2} display="flex" justifyContent="space-between">
          {reservationDetails?.guest.email && (
            <Button
              variant="contained"
              startIcon={<EmailIcon />}
              href={`mailto:${reservationDetails.guest.email}`}
              sx={{
                backgroundColor: "black",
                color: "white",
                textTransform: "none",
              }}
            >
              Email Now
            </Button>
          )}
          {reservationDetails?.guest.phone_number && (
            <>
              <Button
                variant="contained"
                startIcon={<PhoneIcon />}
                href={`tel:${reservationDetails.guest.phone_number}`}
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  textTransform: "none",
                  ml: 1,
                }}
              >
                Call Now
              </Button>
              <Button
                variant="contained"
                startIcon={<WhatsAppIcon />}
                href={`https://wa.me/${reservationDetails.guest.phone_number}`}
                target="_blank"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  textTransform: "none",
                  ml: 1,
                }}
              >
                Message Now
              </Button>
            </>
          )}
        </Box>
      </DetailItem>

      <DetailItem>
        <Typography variant="body2" color="textSecondary">
          Property
        </Typography>
        <Typography variant="h6">
          {reservationDetails?.property.name}
        </Typography>
        <Typography variant="body2">
          {reservationDetails?.property.address}
        </Typography>
      </DetailItem>

      <DetailItem>
        <Typography variant="body2" color="textSecondary">
          Stay
        </Typography>
        <Typography variant="body2">
          <strong>Arrival:</strong> {formatDate(reservationDetails?.arrival)}
        </Typography>
        <Typography variant="body2">
          <strong>Departure:</strong>{" "}
          {formatDate(reservationDetails?.departure)}
        </Typography>
      </DetailItem>

      <Divider sx={{ my: 2 }} />

      <DetailItem>
        <Typography variant="body2" color="textSecondary">
          Guest Link
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography
            component="a"
            href={reservationDetails?.guest_link}
            target="_blank"
            rel="noopener noreferrer"
            variant="body2"
            sx={{
              color: "black",
              wordWrap: "break-word",
              flexGrow: 1,
              marginRight: 1,
              textDecoration: "underline", // Optional: make it look like a clickable link
            }}
          >
            {reservationDetails?.guest_link}
          </Typography>
          <IconButton aria-label="copy" onClick={handleCopyClick} size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
        {copySuccess && (
          <Typography
            variant="caption"
            sx={{ color: "green", mt: 1, display: "block" }}
          >
            Link copied!
          </Typography>
        )}
      </DetailItem>
    </>
  );

  return isMobile ? (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Reservation Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ maxHeight: "80vh", overflowY: "auto" }}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <Alert severity="error">Failed to load booking details.</Alert>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {"An error occurred while fetching data."}
            </Typography>
          </Box>
        ) : (
          renderContent()
        )}
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "calc(100% - 32px)",
          maxWidth: 400,
          margin: "16px",
          borderRadius: "16px",
        },
      }}
    >
      <Box sx={{ p: "24px 16px 32px", height: "100%", overflowY: "auto" }}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <Alert severity="error">Failed to load booking details.</Alert>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {"An error occurred while fetching data."}
            </Typography>
          </Box>
        ) : (
          renderContent()
        )}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
