import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
  Paper,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom"; // Import useParams
import {
  useGetMessagesLibraryByPropertyQuery,
  useUpdateMessageStatusMutation, // Import mutation hook
} from "./messageService"; // API fetching hook
import BlockIcon from "@mui/icons-material/Block"; // Import icon for Deactivate button
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import icon for Activate button
import { skipToken } from "@reduxjs/toolkit/query";

// Function to convert scheduled_time_str (cron-style) to readable format
const parseScheduledTime = (cronString: string): string => {
  const [minute, hour, day, month] = cronString.split(" ");

  // Create a JavaScript Date object using the parsed values
  const date = new Date();
  date.setMonth(parseInt(month) - 1); // JavaScript months are 0-indexed
  date.setDate(parseInt(day));
  date.setHours(parseInt(hour), parseInt(minute), 0, 0); // Set hours and minutes

  // Options to format the date as "Weekday, Month Day, Hour:Minute"
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleDateString("en-US", options);
};

const MessagesLibrary: React.FC = () => {
  const { id: propertyId } = useParams<{ id: string }>(); // Extract propertyId from the URL
  const [page, setPage] = useState<number>(1);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const rowsPerPage = 5;

  // Fetch messages from the API for the specific propertyId
  const { data, isLoading, isError, error, refetch } =
    useGetMessagesLibraryByPropertyQuery(
      propertyId ? { propertyId, page, page_size: rowsPerPage } : skipToken
    );

  // Mutation hook for updating message status
  const [updateMessageStatus, { isLoading: isUpdating }] =
    useUpdateMessageStatusMutation();

  useEffect(() => {
    if (isError) {
      console.error("Error fetching messages:", error);
    }
  }, [isError, error]);

  const handleToggleStatus = async (taskId: number, currentStatus: boolean) => {
    try {
      await updateMessageStatus({ taskId, enabled: !currentStatus }).unwrap();
      setSnackbarMessage(
        `Message successfully ${currentStatus ? "deactivated" : "activated"}.`
      );
      setSnackbarSeverity("success");
      refetch(); // Refresh the list to reflect the status change
    } catch (err) {
      setSnackbarMessage("Failed to update message status.");
      setSnackbarSeverity("error");
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <Typography variant="h6">Error loading messages</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" component="h1" sx={{ marginBottom: "24px" }}>
        Messages Library
      </Typography>

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Guest Name</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Scheduled Time</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.results.map((msg) => (
                <TableRow key={msg.task_id}>
                  <TableCell>{msg.guest_name}</TableCell>
                  <TableCell>{msg.message_schedule}</TableCell>
                  <TableCell>
                    {msg.enabled === true ? "Activated" : "Deactivated"}
                  </TableCell>
                  <TableCell>
                    {parseScheduledTime(msg.scheduled_time_str)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: msg.enabled ? "black" : "white",
                        color: msg.enabled ? "white" : "black",
                        // border: "1px solid",
                        "&:hover": {
                          backgroundColor: msg.enabled ? "#333" : "#f0f0f0",
                        },
                      }}
                      disabled={isUpdating}
                      onClick={() =>
                        handleToggleStatus(msg.task_id, msg.enabled === true)
                      }
                      startIcon={
                        msg.enabled ? <BlockIcon /> : <CheckCircleIcon />
                      }
                    >
                      {msg.enabled ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Pagination
        count={Math.ceil(data.count / rowsPerPage)}
        page={page}
        onChange={(event, value) => setPage(value)}
        sx={{ marginTop: "16px" }}
      />

      {/* Snackbar for success/error messages */}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={snackbarSeverity}
          onClose={() => setSnackbarMessage(null)}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MessagesLibrary;
