import { useState, useEffect, useMemo, useCallback } from "react";
import {
  useGetMessagesByPropertyQuery,
  useGetSchedulesQuery,
} from "../features/athenticated/dashboard/PropertyMessages/messageService";

export const useMessagesPage = (propertyId: string | undefined) => {
  // Fetch schedules and messages
  const {
    data: schedules = [],
    isLoading: isLoadingSchedules,
    error: schedulesError,
    refetch: refetchSchedules, // Get refetch method for schedules
  } = useGetSchedulesQuery();

  const {
    data: messages = [],
    isLoading: isLoadingMessages,
    error: messagesError,
    refetch: refetchMessages, // Get refetch method for messages
  } = useGetMessagesByPropertyQuery(propertyId ?? "");

  const [selectedSchedule, setSelectedSchedule] = useState<{
    key: string;
    value: string;
  }>({
    key: "",
    value: "",
  });

  const [messageDetails, setMessageDetails] = useState({
    subject: "",
    content: "",
    byEmail: false,
    byWhatsapp: false,
    bySource: false,
    onlyMoreThanOneNight: false,
    send_at: 0,
  });

  // Set default schedule on load
  useEffect(() => {
    if (schedules.length > 0) {
      setSelectedSchedule({ key: schedules[0].key, value: schedules[0].value });
    }
  }, [schedules]);
  const matchedMessage = useMemo(() => {
    const match = messages.find((msg) => msg.schedule === selectedSchedule.key);
    return match;
  }, [selectedSchedule, messages]);

  // Set message details based on the matched message
  useEffect(() => {
    if (matchedMessage) {
      setMessageDetails({
        subject: matchedMessage.subject,
        content: matchedMessage.template,
        byEmail: matchedMessage.by_email,
        byWhatsapp: matchedMessage.by_whatsapp,
        bySource: matchedMessage.by_source,
        onlyMoreThanOneNight: matchedMessage.only_more_than_one_night,
        send_at: matchedMessage.send_at,
      });
    } else {
      setMessageDetails({
        subject: "",
        content: "",
        byEmail: false,
        byWhatsapp: false,
        bySource: false,
        onlyMoreThanOneNight: false,
        send_at: 0,
      });
    }
  }, [matchedMessage]);

  // Handle changing of the message schedule
  const handleMessageChange = useCallback(
    (event: any) => {
      const selectedValue = event.target.value;
      const foundSchedule = schedules.find(
        (schedule) => schedule.value === selectedValue
      );
      if (foundSchedule) {
        setSelectedSchedule(foundSchedule);
      }
    },
    [schedules]
  );

  // Function to trigger refetching of messages
  const refetchData = useCallback(() => {
    refetchMessages(); // Refetch the messages
    refetchSchedules(); // Optionally, refetch the schedules if needed
  }, [refetchMessages, refetchSchedules]);

  return {
    schedules,
    selectedSchedule,
    setSelectedSchedule,
    messageDetails,
    setMessageDetails,
    handleMessageChange,
    isLoadingSchedules,
    isLoadingMessages,
    schedulesError,
    messagesError,
    refetchData, // Return refetch function to be called after a message is added
  };
};
