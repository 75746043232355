// src/components/layouts/MainLayout.tsx
import React from "react";
import { useSelector } from "react-redux";
import {
  selectError,
  selectIsOnline,
  selectLoading,
} from "../../../app/selectors";
import useConnectionStatus from "../../../hooks/useConnectionStatus";
import NoInternetBanner from "../States/NoInternetBanner";
import LoadingSpinner from "../States/LoadingSpinner";
import ErrorAlert from "../States/ErrorAlert";
import AuthStatus from "../States/AuthStatus";
import MainRouter from "../../../routes/mainRouter";

const MainLayout: React.FC = () => {
  const isLoading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const isOnline = useSelector(selectIsOnline);

  useConnectionStatus();

  return (
    <>
      <NoInternetBanner open={!isOnline} />
      {isLoading && <LoadingSpinner />}
      {error && <ErrorAlert message={error} />}
      <AuthStatus /> {/* Displays login prompt if authPrompt is true */}
      <MainRouter />
    </>
  );
};

export default MainLayout;
