import React, { ChangeEvent } from "react";

interface InputFieldProps {
  id: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  errorMessage?: string;
  size?: "sm" | "md" | "lg"; // Added size prop to match Material Tailwind
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  type,
  placeholder,
  value,
  onChange,
  label,
  errorMessage,
  size = "lg", // Default to medium
}) => (
  <div className="flex flex-col gap-2">
    <label htmlFor={id} className="font-medium text-blue-gray-600">
      {label}
    </label>
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:border-gray-900 transition-all ${
        size === "lg" ? "h-12" : size === "sm" ? "h-8" : "h-10"
      }`}
    />
    {errorMessage && <span className="text-red-600">{errorMessage}</span>}
  </div>
);

export default InputField;
