import endpoints from "../../../../api/endpoints";
import { apiSlice } from "../../../../services/baseApi";
import {
  ExtendedProperty,
  Contact,
  PropertiesResponse,
  Property,
  EditableProperty,
} from "./properties.types";

const propertiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<
      PropertiesResponse,
      { page?: number; is_hidden?: boolean }
    >({
      query: ({ page = 1, is_hidden }) => ({
        url: endpoints.properties,
        method: "GET",
        params: {
          page,
          ...(is_hidden !== undefined && { is_hidden }),
        },
      }),
    }),

    getConnections: builder.query<{ lodgify: boolean }, void>({
      query: () => ({
        url: `auth/connections/`,
        method: "GET",
      }),
    }),

    connectToAccount: builder.mutation<
      { id: number; api_key: string },
      { api_key: string }
    >({
      query: ({ api_key }) => ({
        url: `lodgify/connect/`,
        method: "POST",
        body: { api_key },
      }),
    }),

    checkNewProperties: builder.query<PropertiesResponse, void>({
      query: () => ({
        url: `lodgify/properties/`,
        method: "GET",
      }),
    }),

    getPropertyById: builder.query<ExtendedProperty, string>({
      query: (id) => ({
        url: `${endpoints.properties}${id}`,
        method: "GET",
      }),
    }),

    editPropertyVisibility: builder.mutation<
      Property,
      { id: string; is_hidden: boolean }
    >({
      query: ({ id, is_hidden }) => ({
        url: `${endpoints.properties}${id}/`,
        method: "PATCH",
        body: { is_hidden },
      }),
    }),
  }),
});

// Export hooks for use in components
export const {
  useGetPropertiesQuery,
  useGetPropertyByIdQuery,
  useEditPropertyVisibilityMutation,
  useCheckNewPropertiesQuery,
  useGetConnectionsQuery,
  useConnectToAccountMutation,
} = propertiesApi;

export default propertiesApi;
