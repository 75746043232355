import { apiSlice } from "../../../../services/baseApi";

export interface Placeholder {
  key: string;
  value: string;
}
// Define the type for the schedule
export interface Schedule {
  hasTemplate: boolean;
  key: string;
  value: string;
}
export interface AddMessageRequest {
  property: string; // Property ID
  schedule: string; // e.g. "CREATION"
  subject: string; // Subject of the message (HTML)
  template: string; // Body of the message (HTML)
  by_email: boolean;
  by_whatsapp: boolean;
  by_source: boolean;
  only_more_than_one_night: boolean; // Boolean value
  send_at: number;
}
export interface Message {
  id: string;
  created_at: string;
  updated_at: string;
  schedule: string;
  subject: string;
  template: string;
  by_email: boolean;
  by_whatsapp: boolean;
  by_source: boolean;
  only_more_than_one_night: boolean;
  property: string;
  send_at: number;
}

// Define the type for the fetched message
export interface MessageItem {
  reservation_id: string; // Reservation ID
  guest_name: string; // Name of the guest
  message_subject: string; // Subject of the message (HTML)
  message_template: string; // Body of the message (HTML)
  message_schedule: string; // Schedule (e.g., DEPARTURE_1B, ARRIVAL_7B, CREATION)
  scheduled_time_str: string; // Cron-style time string when the message is scheduled
  task_id: number; // Unique ID of the task
  enabled: boolean;
}

// Paginated response interface
export interface PaginatedMessages {
  count: number;
  next: string | null;
  previous: string | null;
  results: MessageItem[];
}

const messageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPlaceholders: builder.query<Placeholder[], void>({
      query: () => ({
        url: `messages/placeholders/`,
        method: "GET",
      }),
    }),
    getMessagesByProperty: builder.query<Message[], string>({
      query: (propertyId) => ({
        url: `messages/`,
        method: "GET",
        params: {
          property: propertyId, // Query parameter to filter by propertyId
        },
      }),
    }),
    getMessagesLibraryByProperty: builder.query<
      PaginatedMessages,
      { propertyId: string; page: number; page_size: number }
    >({
      query: ({ propertyId, page, page_size }) => ({
        url: `properties/${propertyId}/message-bank/`,
        method: "GET",
        params: {
          page,
          page_size, // Control the number of messages per page
        },
      }),
    }),
    updateMessageStatus: builder.mutation<
      void,
      { taskId: number; enabled: boolean }
    >({
      query: ({ taskId, enabled }) => ({
        url: `message-bank/${taskId}/`, // Use taskId in the URL
        method: "PATCH",
        body: { enabled },
      }),
    }),
    addMessage: builder.mutation<void, AddMessageRequest>({
      query: (newMessage) => ({
        url: `messages/`, // The URL for submitting the message
        method: "POST", // POST request
        body: newMessage, // The body containing the message data
      }),
    }),
    getSchedules: builder.query<Schedule[], void>({
      query: () => ({
        url: `messages/schedules/`,
        method: "GET",
      }),
    }),
  }),
});

// Export hooks for use in components
export const {
  useGetPlaceholdersQuery,
  useGetSchedulesQuery,
  useAddMessageMutation,
  useGetMessagesByPropertyQuery,
  useGetMessagesLibraryByPropertyQuery,
  useUpdateMessageStatusMutation,
} = messageApi;
export default messageApi;

export function checkSchedulesWithMessages(
  schedules: Schedule[],
  messages: Message[]
): Schedule[] {
  // Map each schedule's `key` to see if there's a corresponding message's `schedule`
  return schedules.map((schedule) => ({
    ...schedule,
    hasTemplate: messages.some((msg) => msg.schedule === schedule.key),
  }));
}

export type ScheduleKey =
  | "Reservation creation - Immediately"
  | "Arrival - 30 Days before"
  | "Arrival - 7 Days before"
  | "Arrival - 1 Day before"
  | "Arrival"
  | "Arrival - 1 Day after"
  | "Departure - 1 Day before"
  | "Departure"
  | "Departure - 1 Day after";
