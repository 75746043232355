// src/store/slices/propertyFaqSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { propertyFaqsApi } from "../../../features/athenticated/dashboard/PropertyFaq/propertyFaqsService";
import { Faq } from "../../../features/athenticated/dashboard/PropertyFaq/faq.types";
import produce from "immer";

interface PropertyFaqState {
  faqs: Faq[]; // Holds the list of FAQs
  isLoading: boolean;
  error: string | null;
}

const initialState: PropertyFaqState = {
  faqs: [],
  isLoading: false,
  error: null,
};

const propertyFaqSlice = createSlice({
  name: "propertyFaq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch FAQs
    builder
      .addMatcher(
        propertyFaqsApi.endpoints.getPropertyFaqs.matchPending,
        (state) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        propertyFaqsApi.endpoints.getPropertyFaqs.matchFulfilled,
        (state, action: PayloadAction<Faq[]>) => {
          state.isLoading = false;
          state.faqs = action.payload;
        }
      )
      .addMatcher(
        propertyFaqsApi.endpoints.getPropertyFaqs.matchRejected,
        (state, action: PayloadAction<FetchBaseQueryError | undefined>) => {
          state.isLoading = false;
          state.error =
            action.payload && "data" in action.payload
              ? JSON.stringify(action.payload.data)
              : "Failed to load FAQs";
        }
      );

    // Add FAQ
    builder
      .addMatcher(
        propertyFaqsApi.endpoints.addPropertyFaq.matchFulfilled,
        (state, action: PayloadAction<Faq>) => {
          state.faqs.push(action.payload); // Add the new FAQ to the faqs array
          state.error = null; // Clear any error state after a successful addition
        }
      )
      .addMatcher(
        propertyFaqsApi.endpoints.addPropertyFaq.matchRejected,
        (state, action: PayloadAction<FetchBaseQueryError | undefined>) => {
          state.error =
            action.payload && "data" in action.payload
              ? JSON.stringify(action.payload.data)
              : "Failed to add FAQ";
        }
      );

    // Edit FAQ
    builder
      .addMatcher(
        propertyFaqsApi.endpoints.editPropertyFaq.matchFulfilled,
        (state, action: PayloadAction<Faq>) => {
          console.log("Edit Response Payload:", action.payload); // Log the API response

          const updatedFaq = action.payload;
          state.faqs = state.faqs.map((faq) =>
            faq.id === updatedFaq.id ? { ...faq, ...updatedFaq } : faq
          );

          console.log("Updated FAQs in State:", state.faqs); // Log the updated FAQs array
          state.error = null; // Clear any error after a successful edit
        }
      )
      .addMatcher(
        propertyFaqsApi.endpoints.editPropertyFaq.matchRejected,
        (state, action: PayloadAction<FetchBaseQueryError | undefined>) => {
          state.error =
            action.payload && "data" in action.payload
              ? JSON.stringify(action.payload.data)
              : "Failed to edit FAQ";
        }
      );

    // Delete FAQ
    builder
      .addMatcher(
        propertyFaqsApi.endpoints.deletePropertyFaq.matchFulfilled,
        (state, action: PayloadAction<{ success: boolean; id: string }>) => {
          if (action.payload.success) {
            state.faqs = state.faqs.filter(
              (faq) => faq.id !== action.payload.id
            ); // Remove FAQ with the matching ID
          }
          state.error = null; // Clear any existing error state
        }
      )
      .addMatcher(
        propertyFaqsApi.endpoints.deletePropertyFaq.matchRejected,
        (state, action: PayloadAction<FetchBaseQueryError | undefined>) => {
          state.error =
            action.payload && "data" in action.payload
              ? JSON.stringify(action.payload.data)
              : "Failed to delete FAQ";
        }
      );
  },
});

// Selector for accessing the FAQ state in Redux
export const selectPropertyFaqs = (state: RootState) => state.propertyFaq.faqs;

export default propertyFaqSlice.reducer;
