import React, { ChangeEvent, FormEvent } from "react";
import Button from "../../atoms/Button";
import ErrorMessage from "../../atoms/ErrorMessage";
import InputField from "../../atoms/Input";

interface OtpFormProps {
  otp: string;
  errors: string | null;
  isVerifying: boolean;
  isResending: boolean;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleResendOtp: () => void;
}

const OtpForm: React.FC<OtpFormProps> = ({
  otp,
  errors,
  isVerifying,
  isResending,
  handleInputChange,
  handleSubmit,
  handleResendOtp,
}) => (
  <form onSubmit={handleSubmit} className="space-y-6">
    <div className="mb-1 flex flex-col gap-4">
      <InputField
        id="otp"
        type="text"
        placeholder="Enter One-time password"
        value={otp}
        onChange={handleInputChange}
        label="Enter One-time password"
        errorMessage={errors || ""}
      />
    </div>

    <Button text="Verify OTP" isLoading={isVerifying} fullWidth={true} />

    <div className="text-right mt-4">
      <button
        type="button"
        onClick={handleResendOtp}
        disabled={isResending}
        className="text-black underline"
      >
        {isResending ? "Resending OTP..." : "Didn't receive the OTP? Resend"}
      </button>
    </div>
  </form>
);

export default OtpForm;
