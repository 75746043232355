import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Menu, MenuItem, Chip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEditPropertyVisibilityMutation } from "../../features/athenticated/dashboard/properties/properties.service";
import PersonIcon from "@mui/icons-material/Person";

interface TableRowProps {
  id: string;
  image: string;
  name: string;
  address: string;
  guidesCount: number;
  messagesCount: number;
  faqCount: number;
  isHidden: boolean;
  onVisibilityChange: (id: string, isHidden: boolean) => void;
}

const TableRow: React.FC<TableRowProps> = ({
  id,
  image,
  name,
  address,
  guidesCount,
  faqCount,
  messagesCount,
  isHidden,
  onVisibilityChange,
}) => {
  const imageUrl = image;
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editPropertyVisibility] = useEditPropertyVisibilityMutation();

  const handleClick = () => {
    navigate(`/property/${id}`);
  };

  const handleGuidesClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/property/${id}/guides`);
  };

  const handleMessagesClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/property/${id}/messages`);
  };

  const handleFaqClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/property/${id}/faq`);
  };

  const handleUserPropertyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/guest?id=${id}&mode=template`);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHideProperty = async () => {
    try {
      await editPropertyVisibility({ id, is_hidden: !isHidden }).unwrap();
      onVisibilityChange(id, !isHidden);
      handleMenuClose();
    } catch (error) {
      console.error("Failed to update property visibility", error);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`flex flex-col sm:flex-row items-start sm:items-center gap-4 p-3 sm:p-4 border border-gray-200 rounded-lg hover:bg-gray-100 cursor-pointer relative ${
        isHidden ? "opacity-50" : ""
      }`}
    >
      {/* Wrapper div for image and three dots */}
      <div className="flex items-center justify-between">
        {/* Image */}
        <div className="w-24 h-20 sm:w-32 sm:h-24">
          <img
            src={imageUrl}
            alt={name}
            className="w-full h-full rounded-lg object-cover"
          />
        </div>

        {/* Three dots (more options) next to the name on mobile */}
        <div className="block sm:hidden ">
          <IconButton aria-label="more" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>

      {/* Name and Address */}
      <div className="flex-1 flex flex-col sm:flex sm:items-center sm:justify-start sm:flex-row sm:gap-0 gap-2">
        <div>
          <p className="text-md sm:text-lg font-semibold text-gray-800 flex items-center">
            {name}
            {isHidden && (
              <Chip
                label="Hidden"
                size="small"
                color="default"
                className="ml-2"
                style={{ fontSize: "10px" }}
              />
            )}
          </p>
          <p className="text-sm text-gray-600">{address}</p>
        </div>
      </div>

      {/* Guides, Messages, and Guest */}
      <div className="flex gap-4 sm:gap-6 items-center">
        <div className="flex flex-col items-center">
          <p className="text-xs sm:text-sm text-gray-600 mb-1">Guides</p>
          <Button
            onClick={handleGuidesClick}
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "100%",
              width: "40px", // Adjusted for perfect circle
              height: "60px",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {guidesCount}
          </Button>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-xs sm:text-sm text-gray-600 mb-1">Messages</p>
          <Button
            onClick={handleMessagesClick}
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "100%",
              width: "40px", // Perfect circle adjustment
              height: "60px",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {messagesCount}
          </Button>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-xs sm:text-sm text-gray-600 mb-1">FAQs</p>
          <Button
            onClick={handleFaqClick}
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "100%",
              width: "40px", // Perfect circle adjustment
              height: "60px",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {faqCount}
          </Button>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-xs sm:text-sm text-gray-600 mb-1">Guest</p>
          <Button
            onClick={handleUserPropertyClick}
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "100%",
              width: "20px", // Perfect circle
              height: "60px",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PersonIcon style={{ fontSize: "18px", color: "white" }} />
          </Button>
        </div>
      </div>

      {/* Three dots (more options) for desktop */}
      <div className="hidden sm:flex items-center justify-center">
        <IconButton aria-label="more" onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
      </div>

      {/* Menu for the three dots */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem onClick={handleHideProperty}>
          {isHidden ? "Unhide Property" : "Hide Property"}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TableRow;
