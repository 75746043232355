import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import parse from "html-react-parser";
import {
  GuestGuidesDetails,
  Guide,
} from "../../../features/public/guestPage/guest.types";

interface GuestFeaturedGuidesOrganismeProps {
  guestGuidesDetails: GuestGuidesDetails | undefined;
}

const GuestFeaturedGuidesOrganisme: React.FC<
  GuestFeaturedGuidesOrganismeProps
> = ({ guestGuidesDetails }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGuide, setSelectedGuide] = useState<Guide | null>(null);

  const handleOpenDialog = (guide: Guide) => {
    setSelectedGuide(guide);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGuide(null);
  };

  if (
    !guestGuidesDetails ||
    !guestGuidesDetails.data.featured_guides ||
    guestGuidesDetails.data.featured_guides.length === 0
  ) {
    return null;
  }

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold">{guestGuidesDetails.title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
        {guestGuidesDetails.data.featured_guides.map((guide, index) => (
          <div
            key={index}
            className=" bg-white p-4 rounded-lg shadow cursor-pointer"
            onClick={() => handleOpenDialog(guide)}
          >
            {guide.main_image ? (
              <img
                src={guide.main_image}
                alt={guide.name}
                className="rounded-lg shadow-md w-full h-24 object-cover mb-2"
              />
            ) : (
              guide.medias &&
              guide.medias.length > 0 && (
                <img
                  src={guide.medias[0]?.file}
                  alt={guide.name}
                  className="rounded-lg shadow-md w-full h-24 object-cover mb-2"
                />
              )
            )}
            <h3 className="text-lg font-bold">{guide.name}</h3>
          </div>
        ))}
      </div>

      {/* Dialog for Guide Details */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        {selectedGuide && (
          <>
            <DialogTitle>
              {selectedGuide.name}
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              dividers
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <div className="w-full">
                {selectedGuide.main_image ? (
                  <img
                    src={selectedGuide.main_image}
                    alt={selectedGuide.name || "Guide"}
                    className="w-auto h-48 mb-4 rounded-t-lg mx-auto"
                  />
                ) : (
                  <div className="h-48 bg-gray-200 mb-4 rounded-t-lg flex items-center justify-center">
                    <span className="text-gray-500">
                      {/* {staticText.noMediaImages} */}
                    </span>
                  </div>
                )}
                {selectedGuide.name ? (
                  <h2 className="text-xl font-semibold mb-4">
                    {selectedGuide.name}
                  </h2>
                ) : (
                  <h2 className="text-xl font-semibold mb-4 text-gray-500">
                    {/* {staticText.untitledGuide} */}
                  </h2>
                )}
                {selectedGuide.description ? (
                  <div className="text-gray-700 mb-6">
                    {parse(selectedGuide.description)}
                  </div>
                ) : (
                  <div className="text-gray-500 mb-6">
                    {/* {staticText.noDescription} */}
                  </div>
                )}
                <hr className="border-t border-gray-300 my-4" />
                {selectedGuide.medias && selectedGuide.medias.length > 0 ? (
                  <div className="grid grid-cols-1 gap-4">
                    {selectedGuide.medias.map((media, index) => (
                      <div key={index}>
                        <img
                          src={media.file}
                          alt=""
                          className="w-full h-auto mb-4 rounded-lg"
                        />
                        {index < selectedGuide.medias.length - 1 && (
                          <hr className="border-t border-gray-300 my-4" />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default GuestFeaturedGuidesOrganisme;
