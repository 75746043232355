import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import {
  Category,
  Guide,
  Media,
} from "../../../features/athenticated/dashboard/properties/properties.types";
import CategoryList from "../../molecules /PropertyGuide/CategoryList";
import GuideEditor from "../../molecules /PropertyGuide/GuideEditor";
import GuideCard from "../../molecules /PropertyGuide/GuideCard";
import { useUpdateGuideOrderMutation } from "../../../features/athenticated/dashboard/PropertyGuides/guides.services";

type GuideManagementProps = {
  guides: Guide[];
  categories: Category[];
  selectedCategory: string | null;
  setSelectedCategory: (id: string) => void;
  onDeleteGuide: (id: string) => void;
  medias: Media[];
  onAddCategory: (name: string, image: File) => void;
  onEditCategory: (id: string, name: string, image?: File | null) => void;
  onDeleteCategory: (id: string) => void;
  isAddingGuide: boolean;
  setIsAddingGuide: (value: boolean) => void;
  propertyId: string;
  refetchGuides: () => void;
  moveCategoryUp: (id: string) => void; // Passing function to move category up
  moveCategoryDown: (id: string) => void; // Passing function to move category down
};

const GuideManagement: React.FC<GuideManagementProps> = ({
  guides,
  categories,
  selectedCategory,
  setSelectedCategory,
  onDeleteGuide,
  medias,
  refetchGuides,
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  isAddingGuide,
  setIsAddingGuide,
  moveCategoryDown,
  moveCategoryUp,
  propertyId,
}) => {
  const [updateGuideOrder, { isLoading }] = useUpdateGuideOrderMutation();
  const [loadingGuideUp, setLoadingGuideUp] = useState<string | null>(null);
  const [loadingGuideDown, setLoadingGuideDown] = useState<string | null>(null);

  const moveGuideUp = async (guideId: string) => {
    setLoadingGuideUp(guideId); // Start loading for the up button
    const index = guides.findIndex((g: Guide) => g.id === guideId); // Explicitly typed as Guide

    if (index <= 0) {
      setLoadingGuideUp(null); // Stop loading
      return;
    }

    const updatedGuides = JSON.parse(JSON.stringify(guides)); // Deep copy with explicit Guide type

    [updatedGuides[index], updatedGuides[index - 1]] = [
      updatedGuides[index - 1],
      updatedGuides[index],
    ];

    // Reassign the order sequentially (1 to x)
    updatedGuides.forEach((g: Guide, idx: number) => {
      g.order = idx + 1;
    });

    const payload = updatedGuides.map((g: Guide) => ({
      guide: g.id!,
      order: g.order!,
    }));

    try {
      await updateGuideOrder({ payload }).unwrap();
      refetchGuides();
    } catch (error) {
      console.error("Error updating guide order:", error);
    } finally {
      setLoadingGuideUp(null); // Stop loading
    }
  };

  const moveGuideDown = async (guideId: string) => {
    setLoadingGuideDown(guideId); // Start loading for the down button
    const index = guides.findIndex((g: Guide) => g.id === guideId); // Explicitly typed as Guide

    if (index === -1 || index === guides.length - 1) {
      setLoadingGuideDown(null); // Stop loading
      return;
    }

    const updatedGuides = JSON.parse(JSON.stringify(guides)); // Deep copy with explicit Guide type

    [updatedGuides[index], updatedGuides[index + 1]] = [
      updatedGuides[index + 1],
      updatedGuides[index],
    ];

    // Reassign the order sequentially (1 to x)
    updatedGuides.forEach((g: Guide, idx: number) => {
      g.order = idx + 1;
    });

    const payload = updatedGuides.map((g: Guide) => ({
      guide: g.id!,
      order: g.order!,
    }));

    try {
      await updateGuideOrder({ payload }).unwrap();
      refetchGuides();
    } catch (error) {
      console.error("Error updating guide order:", error);
    } finally {
      setLoadingGuideDown(null); // Stop loading
    }
  };

  return (
    <Box p={2} sx={{ backgroundColor: "#fff", color: "black" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <CategoryList
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            onAddCategory={onAddCategory}
            onEditCategory={onEditCategory}
            onDeleteCategory={onDeleteCategory}
            moveCategoryUp={moveCategoryUp}
            moveCategoryDown={moveCategoryDown}
          />
        </Grid>

        <Grid item xs={12} md={8} lg={9}>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ color: "#000", letterSpacing: 0.5 }}
              >
                Guides
              </Typography>
              <IconButton
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: "50%",
                  padding: "4px",
                  "&:hover": { backgroundColor: "#333" },
                  marginLeft: 1,
                  width: 32,
                  height: 32,
                }}
                onClick={() => setIsAddingGuide(true)}
              >
                <AddIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Box>

            {guides.length === 0 && (
              <Typography sx={{ color: "#555" }}>
                No guides available for this category.
              </Typography>
            )}

            {isAddingGuide && (
              <Box
                mt={2}
                p={2}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              >
                <GuideEditor
                  onCancel={() => setIsAddingGuide(false)}
                  category={selectedCategory}
                  propertyId={propertyId}
                  onConvertToCard={() => setIsAddingGuide(false)}
                  refetchGuides={refetchGuides}
                />
              </Box>
            )}

            <List>
              {guides.map((guide, idx) => (
                <ListItem key={guide.id} sx={{ padding: "12px 0" }}>
                  <GuideCard
                    guide={guide}
                    index={idx}
                    totalGuides={guides.length}
                    onDeleteGuide={() => onDeleteGuide(guide.id!)}
                    onMoveGuideUp={moveGuideUp}
                    onMoveGuideDown={moveGuideDown}
                    propertyId={propertyId}
                    category={selectedCategory}
                    refetchGuides={refetchGuides}
                    isLoadingUp={loadingGuideUp === guide.id} // Pass loading state for up
                    isLoadingDown={loadingGuideDown === guide.id} // Pasß
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuideManagement;
