import React from "react";
import ButtonAtom from "../../atoms/PropertyFaqs/ButtonAtom";
import InputAtom from "../../atoms/PropertyFaqs/InputAtom";
import TextAtom from "../../atoms/PropertyFaqs/TextAtom";
import { Faq } from "../../../features/athenticated/dashboard/PropertyFaq/faq.types";

interface FaqItemProps {
  faq: Faq;
  isEditing: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onSave: (updatedFaq: Faq) => void;
  onCancel: () => void;
}

const FaqItem: React.FC<FaqItemProps> = ({
  faq,
  isEditing,
  onEdit,
  onDelete,
  onSave,
  onCancel,
}) => {
  const [question, setQuestion] = React.useState(faq.question);
  const [answer, setAnswer] = React.useState(faq.answer);

  return (
    <div
      style={{
        marginBottom: "20px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      {isEditing ? (
        <>
          <InputAtom
            label="Edit Question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <InputAtom
            label="Edit Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            style={{ marginBottom: "15px" }}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <ButtonAtom
              label="Save"
              onClick={() => onSave({ ...faq, question, answer })}
              variant="contained"
              color="primary"
            />
            <ButtonAtom
              label="Cancel"
              onClick={onCancel}
              variant="outlined"
              color="secondary"
            />
          </div>
        </>
      ) : (
        <>
          <TextAtom
            content={faq.question}
            variant="p"
            style={{ fontWeight: "bold", marginBottom: "8px" }}
          />
          <TextAtom
            content={faq.answer}
            variant="p"
            style={{ color: "#555", marginBottom: "12px" }}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <ButtonAtom
              label="Edit"
              onClick={onEdit}
              variant="outlined"
              color="primary"
            />
            <ButtonAtom
              label="Delete"
              onClick={onDelete}
              variant="outlined"
              color="secondary"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FaqItem;
