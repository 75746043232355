import { apiSlice } from "../../../../services/baseApi";
import { Property, Reservation, ReservationDetails } from "./ calendar.types";

export const calendarApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch all reservations
    getReservations: builder.query<Reservation[], void>({
      query: () => "reservations/",
      transformResponse: (response: any) => {
        // Check if the response contains a `data` field, or return the full response if it's an array
        return response.data ? response.data : response;
      },
    }),

    // Fetch all properties
    getPropertiesReservation: builder.query<Property[], void>({
      query: () => "properties/reservations/",
      transformResponse: (response: any) => {
        return response.data ? response.data : response;
      },
    }),

    // Fetch reservation details by ID
    getReservationDetails: builder.query<ReservationDetails, string>({
      query: (id) => `reservations/${id}`,
      transformResponse: (response: any) => {
        return response.data ? response.data : response;
      },
    }),
  }),
});

export const {
  useGetReservationsQuery,
  useGetPropertiesReservationQuery,
  useGetReservationDetailsQuery,
} = calendarApi;
