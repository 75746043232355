import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import guestApiSlice from "./guest.services";
import {
  GuestFaqs,
  GuestGuidesDetails,
  GuestPropertyDetails,
  GuestReservationDetails,
} from "./guest.types";
import { RootState } from "../../../app/store";

interface GuestState {
  faqs: GuestFaqs | null;
  propertyDetails: GuestPropertyDetails | null;
  reservationDetails: GuestReservationDetails | null;
  guidesDetails: GuestGuidesDetails | null;
  contactDetails: any;
  loading: boolean;
  error: string | null;
}

const initialState: GuestState = {
  faqs: null,
  propertyDetails: null,
  reservationDetails: null,
  guidesDetails: null,
  contactDetails: null,
  loading: false,
  error: null,
};

const guestSlice = createSlice({
  name: "guest",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle FAQs response
      .addMatcher(
        guestApiSlice.endpoints.getGuestFaqs.matchFulfilled,
        (state, { payload }) => {
          state.faqs = payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestFaqs.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestFaqs.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error?.message || "Failed to fetch FAQs";
        }
      )
      // Handle Property Details response
      .addMatcher(
        guestApiSlice.endpoints.getGuestPropertyDetails.matchFulfilled,
        (state, { payload }) => {
          state.propertyDetails = payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestPropertyDetails.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestPropertyDetails.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error?.message || "Failed to fetch property details";
        }
      )
      // Handle Reservation Details response
      .addMatcher(
        guestApiSlice.endpoints.getGuestReservationDetails.matchFulfilled,
        (state, { payload }) => {
          state.reservationDetails = payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestReservationDetails.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestReservationDetails.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error?.message || "Failed to fetch reservation details";
        }
      )
      // Handle Guides Details response
      .addMatcher(
        guestApiSlice.endpoints.getGuestGuidesDetails.matchFulfilled,
        (state, { payload }) => {
          state.guidesDetails = payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestGuidesDetails.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestGuidesDetails.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error?.message || "Failed to fetch guides details";
        }
      )
      // Handle Contact Details response
      .addMatcher(
        guestApiSlice.endpoints.getGuestContactDetails.matchFulfilled,
        (state, { payload }) => {
          state.contactDetails = payload;
          state.loading = false;
          state.error = null;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestContactDetails.matchPending,
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        guestApiSlice.endpoints.getGuestContactDetails.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error?.message || "Failed to fetch contact details";
        }
      );
  },
});

export const { setLoading, setError } = guestSlice.actions;

export const selectGuestState = (state: RootState) => state.guest;
export const selectFaqs = (state: RootState) => state.guest.faqs;
export const selectPropertyDetails = (state: RootState) =>
  state.guest.propertyDetails;
export const selectReservationDetails = (state: RootState) =>
  state.guest.reservationDetails;
export const selectGuidesDetails = (state: RootState) =>
  state.guest.guidesDetails;
export const selectContactDetails = (state: RootState) =>
  state.guest.contactDetails;

export default guestSlice.reducer;
