import React from "react";

interface SharedLayoutProps {
  children: React.ReactNode;
}

const SharedLayout: React.FC<SharedLayoutProps> = ({ children }) => {
  return (
    <section className="flex h-screen">
      {/* Left side: form container takes full width on small screens */}
      <div className="w-full lg:w-3/5 flex items-center justify-center p-8 lg:p-16">
        {children}
      </div>
      {/* Right side: image container, only visible on larger screens */}
      <div className="hidden lg:block w-2/5 h-full relative">
        <img
          src="/img/pattern.png" // Add your image here
          alt="Pattern"
          className="object-cover h-full w-full rounded-lg"
        />
      </div>
    </section>
  );
};

export default SharedLayout;
