import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
} from "@mui/material";

const PrivacyAndPolicy: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box mb={6}>
          <Typography variant="h4" component="h1" gutterBottom>
            Privacy and Policy
          </Typography>
          <Typography variant="body1">
            Welcome to our Property Management Service. We are committed to
            protecting your privacy and ensuring that your personal information
            is handled in a safe and responsible manner.
          </Typography>
        </Box>

        <Box mb={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Information Collection
          </Typography>
          <Typography variant="body1">
            We collect information about your properties through API keys
            provided by third-party services such as Logify, Airbnb, and
            Booking.com. This information may include property details, booking
            information, and other relevant data.
          </Typography>
        </Box>

        <Box mb={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Use of Information
          </Typography>
          <Typography variant="body1" paragraph>
            The information collected is used to manage your properties
            effectively, provide you with the best possible service, and improve
            our offerings. We may use this information to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Manage and update property listings" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Process bookings and payments" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Communicate with you regarding your properties and bookings" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Analyze and improve our services" />
            </ListItem>
          </List>
        </Box>

        <Box mb={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Data Sharing
          </Typography>
          <Typography variant="body1">
            We do not sell or rent your personal information to third parties.
            We may share your information with trusted partners who assist us in
            operating our service, conducting our business, or servicing you, so
            long as those parties agree to keep this information confidential.
          </Typography>
        </Box>

        <Box mb={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Data Security
          </Typography>
          <Typography variant="body1">
            We implement a variety of security measures to maintain the safety
            of your personal information. Your data is stored in secure networks
            and is only accessible by a limited number of persons who have
            special access rights to such systems.
          </Typography>
        </Box>

        <Box mb={6}>
          <Typography variant="h5" component="h2" gutterBottom>
            Changes to Our Privacy Policy
          </Typography>
          <Typography variant="body1">
            We may update our Privacy and Policy from time to time. We will
            notify you of any changes by posting the new Privacy and Policy on
            this page. You are advised to review this Privacy and Policy
            periodically for any changes.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" component="h2" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1">
            If you have any questions about this Privacy and Policy, please
            contact us at info@portas.io.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyAndPolicy;
