import React, { useState, useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CircularProgress from "@mui/material/CircularProgress";
import { store, persistor } from "./app/store";
import i18n from "./i18n/i18n";
import MainLayout from "./components/atoms/layout/MainLayout";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  const [isI18nReady, setI18nReady] = useState(false);

  // Check if i18next is initialized
  useEffect(() => {
    if (i18n.isInitialized) {
      setI18nReady(true);
    } else {
      i18n.on("initialized", () => setI18nReady(true));
    }
  }, []);

  if (!isI18nReady) {
    return <CircularProgress />; // Show a loading spinner until i18n is ready
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <PersistGate loading={<CircularProgress />} persistor={persistor}>
          <BrowserRouter>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
            />
            <MainLayout />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </DndProvider>
  );
};

export default App;
