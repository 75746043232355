import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken } from "../../utils/shared/tokenService";
import {
  setAuthPrompt,
  setRequiresAuth,
} from "../../redux/Slices/appStateSlice";
import { AppDispatch, RootState } from "../../app/store";

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedFrom?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  allowedFrom,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = !!getAccessToken();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");

  const authPrompt = useSelector(
    (state: RootState) => state.appState.authPrompt
  );

  // Determine if the route requires authentication
  const requiresAuth =
    allowedFrom !== "otp" &&
    allowedFrom !== "resetpass" &&
    mode !== "reservation";

  useEffect(() => {
    if (requiresAuth && !isAuthenticated) {
      dispatch(setRequiresAuth(true));
      dispatch(setAuthPrompt(true));
    } else {
      dispatch(setRequiresAuth(false));
    }
  }, [isAuthenticated, requiresAuth, dispatch]);

  // Handle cases for specific modes and access control
  if (mode === "template" && !isAuthenticated) {
    dispatch(setAuthPrompt(true)); // Show the auth prompt for template mode if not authenticated
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedFrom === "otp" && !localStorage.getItem("otpAllowed")) {
    return <Navigate to="/login" replace />;
  }

  if (
    allowedFrom === "resetpass" &&
    !localStorage.getItem("resetPassAllowed")
  ) {
    return <Navigate to="/forgetpass" replace />;
  }

  // If authentication is required but the user is not authenticated, redirect to login
  if (requiresAuth && !isAuthenticated && !authPrompt) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
