import React, { FormEvent, ChangeEvent } from "react";
import LoginForm from "../../organisms /Authentication/LoginForm";
import SharedLayout from "../../../utils/shared/sharedLayout";
import { LoginErrors } from "../../../features/authentication/login/login.types";

interface LoginTemplateProps {
  handleLogin: (e: React.FormEvent<HTMLFormElement>) => void;
  handleGoogleSignIn: () => void;
  isLoading: boolean;
  errors: LoginErrors;
  authentication: { email: string; password: string };
  handleChange: (
    field: keyof LoginTemplateProps["authentication"],
    isManualInput?: boolean
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LoginTemplate: React.FC<LoginTemplateProps> = ({
  handleLogin,
  handleGoogleSignIn,
  isLoading,
  errors,
  authentication,
  handleChange,
}) => (
  <SharedLayout>
    <div className="w-full max-w-lg">
      <div className="text-center mb-8">
        <h2 className="font-bold text-4xl mb-4">Sign In</h2>
        <p className="text-lg font-normal text-gray-600">
          Enter your email and password to Sign In.
        </p>
      </div>
      <LoginForm
        handleLogin={handleLogin}
        handleGoogleSignIn={handleGoogleSignIn}
        isLoading={isLoading}
        errors={errors}
        authentication={authentication}
        handleChange={(field) => handleChange(field, true)}
      />
    </div>
  </SharedLayout>
);

export default LoginTemplate;
