import { useSelector, useDispatch } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "../../../app/store";
import {
  setLoading,
  setError,
  selectGuestState,
  selectFaqs,
  selectPropertyDetails,
  selectReservationDetails,
  selectGuidesDetails,
  selectContactDetails,
} from "./guest.slice";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Custom hooks for accessing guest state
export const useGuestState = () => useAppSelector(selectGuestState);
export const useFaqs = () => useAppSelector(selectFaqs);
export const usePropertyDetails = () => useAppSelector(selectPropertyDetails);
export const useReservationDetails = () =>
  useAppSelector(selectReservationDetails);
export const useGuidesDetails = () => useAppSelector(selectGuidesDetails);
export const useContactDetails = () => useAppSelector(selectContactDetails);

// Custom hooks for dispatching actions
export const useSetLoading = () => {
  const dispatch = useAppDispatch();
  return (isLoading: boolean) => dispatch(setLoading(isLoading));
};

export const useSetError = () => {
  const dispatch = useAppDispatch();
  return (error: string | null) => dispatch(setError(error));
};
// Interface for language options
export interface LanguageOption {
  code: string; //Hey
  label: string;
}

export const getCountryCode = (languageCode: string) => {
  const fallbackCountryCode: { [key: string]: string } = {
    en: "US",
    fr: "FR",
    es: "ES",
    de: "DE",
    ar: "SA",
    zh: "CN",
    it: "IT",
    pt: "BR",
  };
  return (
    fallbackCountryCode[languageCode as keyof typeof fallbackCountryCode] ||
    languageCode.toUpperCase()
  );
};
