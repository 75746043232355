import React, { useEffect, useRef, useState, useCallback } from "react";
import { Timeline, TimelineTimeAxisScaleType } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";
import { CircularProgress, Box, Typography } from "@mui/material";
import moment from "moment";

interface TimelineComponentProps {
  groups: any[];
  items: any[];
  visibleTimeStart: number;
  visibleTimeEnd: number;
  handleItemClick: (itemId: string) => void;
  isLoading: boolean;
  searchQuery: string;
  reservationDate?: Date;
  setVisibleTimeStart: (time: number) => void;
  setVisibleTimeEnd: (time: number) => void;
}

const TimelineComponent: React.FC<TimelineComponentProps> = ({
  groups,
  items,
  visibleTimeStart,
  visibleTimeEnd,
  handleItemClick,
  isLoading,
  setVisibleTimeStart,
  setVisibleTimeEnd,
  searchQuery,
  reservationDate,
}) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const timelineInstance = useRef<any>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [displayedRange, setDisplayedRange] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [propertySearchInput, setPropertySearchInput] = useState<string>("");

  const propertyCount = groups.length;
  const reservationCount = items.length;

  // Initialize the timeline and set the visible time window
  const initializeTimeline = () => {
    if (timelineInstance.current) {
      timelineInstance.current.destroy();
      timelineInstance.current = null;
    }

    if (timelineRef.current) {
      const options = {
        start: new Date(visibleTimeStart),
        end: new Date(visibleTimeEnd),
        stack: true,
        editable: false,
        orientation: "top",
        selectable: true,
        moveable: false,
        zoomMin: 1000 * 60 * 60 * 24 * 14, // 1 day in milliseconds
        zoomMax: 1000 * 60 * 60 * 24 * 14, // 1 day in milliseconds
        format: {
          minorLabels: {
            day: "ddd D", // Display abbreviated day name and day number (e.g., "Mon 4")
          },
          majorLabels: {
            month: "MMM", // Display abbreviated month (e.g., "Oct")
          },
        },
        timeAxis: { scale: "day" as TimelineTimeAxisScaleType, step: 1 }, // Cast "day" to TimelineTimeAxisScaleType
      };

      // Apply CSS class and log className for each item
      const styledItems = items.map((item) => {
        const colorClass =
          item.status === "confirmed"
            ? "bg-green-500 text-white" // Green for confirmed
            : item.status === "pending"
            ? "bg-yellow-500 text-white" // Yellow for pending
            : "bg-red-500 text-white"; // Red for cancelled or other statuses

        console.log(`Item ${item.id} assigned Tailwind classes: ${colorClass}`);

        return { ...item, className: colorClass };
      });

      timelineInstance.current = new Timeline(
        timelineRef.current,
        styledItems,
        groups,
        options
      );

      timelineInstance.current.on("select", (properties: any) => {
        const selectedItem = properties.items[0];
        if (selectedItem) {
          setSelectedItemId(selectedItem);
          handleItemClick(selectedItem);
        }
      });

      // Set initial view window
      if (timelineInstance.current) {
        timelineInstance.current.setWindow(
          new Date(visibleTimeStart),
          new Date(visibleTimeEnd)
        );
      }
    }
  };

  // Update the timeline range when navigating
  const updateTimelineRange = () => {
    if (timelineInstance.current) {
      timelineInstance.current.setWindow(
        new Date(visibleTimeStart),
        new Date(visibleTimeEnd)
      );
    }
  };

  // Function to navigate to the reservation date
  const navigateToReservationDate = () => {
    if (timelineInstance.current && reservationDate) {
      timelineInstance.current.moveTo(reservationDate, {
        animation: { duration: 1000, easingFunction: "easeInOutQuad" },
      });
    }
  };

  // Week navigation handlers
  const handlePrevWeek = useCallback(() => {
    const newStart = moment(visibleTimeStart).subtract(2, "weeks").valueOf();
    const newEnd = moment(visibleTimeEnd).subtract(2, "weeks").valueOf();

    setVisibleTimeStart(newStart);
    setVisibleTimeEnd(newEnd);
  }, [
    visibleTimeStart,
    visibleTimeEnd,
    setVisibleTimeStart,
    setVisibleTimeEnd,
  ]);

  const handleNextWeek = useCallback(() => {
    const newStart = moment(visibleTimeStart).add(2, "weeks").valueOf();
    const newEnd = moment(visibleTimeEnd).add(2, "weeks").valueOf();

    setVisibleTimeStart(newStart);
    setVisibleTimeEnd(newEnd);
  }, [
    visibleTimeStart,
    visibleTimeEnd,
    setVisibleTimeStart,
    setVisibleTimeEnd,
  ]);

  // Update displayed range
  useEffect(() => {
    const start = moment(visibleTimeStart).format("MMM DD, YYYY");
    const end = moment(visibleTimeEnd).format("MMM DD, YYYY");
    setDisplayedRange(`${start} - ${end}`);
  }, [visibleTimeStart, visibleTimeEnd]);

  // Initialize timeline on mount
  useEffect(() => {
    initializeTimeline();
    if (timelineInstance.current) {
      timelineInstance.current.redraw(); // Ensure it refreshes styles
    }
  }, [items, groups]);

  // Update timeline range when visibleTimeStart or visibleTimeEnd changes
  useEffect(() => {
    updateTimelineRange();
  }, [visibleTimeStart, visibleTimeEnd]);

  // Navigate to the reservation date on change
  useEffect(() => {
    if (reservationDate) {
      navigateToReservationDate();
    }
  }, [reservationDate]);

  // Search and scroll to the matching reservation
  useEffect(() => {
    if (searchInput && timelineInstance.current) {
      const matchingItem = items.find((item) =>
        item.content.toLowerCase().includes(searchInput.toLowerCase())
      );

      if (matchingItem) {
        timelineInstance.current.setSelection([matchingItem.id]);
        timelineInstance.current.moveTo(matchingItem.start, {
          animation: { duration: 1000, easingFunction: "easeInOutQuad" },
        });
      }
    }
  }, [searchInput, items]);
  useEffect(() => {
    if (timelineInstance.current) {
      if (propertySearchInput) {
        const matchingProperty = groups.filter((group) =>
          group.content
            .toLowerCase()
            .includes(propertySearchInput.toLowerCase())
        );

        if (matchingProperty.length > 0) {
          const filteredItems = items.filter(
            (item) => item.group === matchingProperty[0].id
          );

          // Ensure the instance exists before calling setItems and setGroups
          timelineInstance.current.setItems(filteredItems);
          timelineInstance.current.setGroups(matchingProperty);

          if (filteredItems.length > 0) {
            timelineInstance.current.moveTo(filteredItems[0].start, {
              animation: { duration: 1000, easingFunction: "easeInOutQuad" },
            });
          }
        }
      } else {
        // Reset to show all properties and reservations if search input is cleared
        timelineInstance.current.setItems(items);
        timelineInstance.current.setGroups(groups);
      }
    }
  }, [propertySearchInput, groups, items]);

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: 400,
        padding: "20px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Week navigator and property/reservation counts */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Properties: {propertyCount} | Reservations: {reservationCount}
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button onClick={handlePrevWeek} style={{ marginRight: "20px" }}>
            Previous Week
          </button>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            {displayedRange}
          </span>
          <button onClick={handleNextWeek} style={{ marginLeft: "20px" }}>
            Next Week
          </button>
        </div>
      </div>
      {/* Search input for property names */}
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search by property name"
          value={propertySearchInput}
          onChange={(e) => setPropertySearchInput(e.target.value)}
          style={{ padding: "5px", fontSize: "14px", width: "300px" }}
        />
      </div>

      {/* Search input for reservation */}
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search by reservation name"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{ padding: "5px", fontSize: "14px", width: "300px" }}
        />
      </div>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 400,
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : items.length === 0 || groups.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 400,
            textAlign: "center",
            color: "#777",
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Results Found
          </Typography>
          <Typography variant="body1">
            {searchQuery
              ? `No properties or reservations match "${searchQuery}".`
              : "Try adjusting your search or adding new data."}
          </Typography>
        </Box>
      ) : (
        <div
          ref={timelineRef}
          style={{
            height: 400,
            transition: "height 0.3s ease-in-out",
          }}
        />
      )}
    </Box>
  );
};

export default TimelineComponent;
