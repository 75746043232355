import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { useResetPassMutation } from "./resetpass.service";
import ResetPassTemplate from "../../../components/templates /Authentication/ResetPassTemplate";
import { useForgetPassMutation } from "../forgetPass/forgetpass.service";

const ResetPass = () => {
  const location = useLocation(); // Get location object to extract state
  const email = location.state?.email || ""; // Extract email from state, provide a fallback if not present
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission
  const [resetPass, { isLoading }] = useResetPassMutation();
  const navigate = useNavigate();
  const [forgetPass] = useForgetPassMutation();

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
      if (isSubmitted) setErrors(null); // Clear errors after input change if form was submitted
    };

  const validatePasswords = (): string | null => {
    if (password !== confirmPassword) {
      return "Passwords do not match.";
    }
    if (password.length < 6) {
      return "Password must be at least 6 characters.";
    }
    return null;
  };

  const handleResendOtp = async () => {
    try {
      const response = await forgetPass({ email }).unwrap();
    } catch (err: any) {
      setErrors(
        err?.data?.message ||
          "Failed to send One-time password. Please try again."
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true); // Mark the form as submitted

    const validationError = validatePasswords();
    if (validationError) {
      setErrors(validationError);
      return;
    }

    try {
      const response = await resetPass({
        email,
        code: otp,
        password,
      }).unwrap();

      // Check if the reset was successful by ensuring email or other required fields exist in the response
      if (response.email) {
        localStorage.removeItem("resetPassAllowed"); // Remove permission to access reset pass page
        navigate("/login"); // Navigate to login screen after success
      } else {
        setErrors("Password reset failed. Please try again.");
      }
    } catch (err: any) {
      setErrors(
        err?.data?.message || "Failed to reset password. Please try again."
      );
    }
  };

  return (
    <ResetPassTemplate
      email={email} // Pass email from location state
      otp={otp}
      password={password}
      confirmPassword={confirmPassword}
      errors={isSubmitted ? errors : null} // Only show errors after form submission
      isLoading={isLoading}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      setOtp={setOtp} // Pass setter to the form
      setPassword={setPassword} // Pass setter to the form
      setConfirmPassword={setConfirmPassword} // Pass setter to the form
      resendOtp={handleResendOtp}
    />
  );
};

export default ResetPass;
