import React from "react";
import { List, ListItemButton, ListItemText, Box } from "@mui/material";
import {
  Schedule,
  ScheduleKey,
} from "../../../features/athenticated/dashboard/PropertyMessages/messageService";
import { SelectChangeEvent } from "@mui/material";

interface MessageSelectorProps {
  selectedMessage: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  messages: Schedule[];
  templateExists: (scheduleKey: ScheduleKey) => boolean;
}

const MessageSelector: React.FC<MessageSelectorProps> = ({
  selectedMessage,
  onChange,
  messages,
  templateExists, // Passed from parent to check if a template exists for the schedule
}) => {
  const handleListItemClick = (messageValue: string) => {
    const event = {
      target: { value: messageValue },
    } as SelectChangeEvent<string>; // Mimic dropdown behavior
    onChange(event);
  };

  return (
    <List>
      {messages.map((message) => (
        <ListItemButton
          key={message.value}
          selected={selectedMessage === message.value}
          onClick={() => handleListItemClick(message.value)}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "100%",
              backgroundColor: templateExists(message.value as ScheduleKey)
                ? "green"
                : "red",
              // marginLeft: "auto",
              flexShrink: 0, // Ensures the box remains a perfect circle
              marginRight: "8px",
              display: "inline-block",
            }}
          />
          <ListItemText primary={message.value} />
          {/* Show green circle if template exists, red if not */}
        </ListItemButton>
      ))}
    </List>
  );
};

export default MessageSelector;
