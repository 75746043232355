import endpoints from "../../../api/endpoints";
import { apiSlice } from "../../../services/baseApi";
import { IForgetPassRequest, IForgetPassResponse } from "./forgetpass.types";

// Inject the forget password API endpoint into the base API
const forgetPassApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    forgetPass: builder.mutation<IForgetPassResponse, IForgetPassRequest>({
      query: (body) => ({
        url: endpoints.forgetPass,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useForgetPassMutation } = forgetPassApi;
