import React from "react";
import ResetPassForm from "../../organisms /Authentication/ResetPassForm";
import SharedLayout from "../../../utils/shared/sharedLayout";

interface ResetPassTemplateProps {
  email: string;
  otp: string;
  password: string;
  confirmPassword: string;
  errors: string | null;
  isLoading: boolean;
  handleInputChange: (
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setOtp: React.Dispatch<React.SetStateAction<string>>; // Pass setter for OTP
  setPassword: React.Dispatch<React.SetStateAction<string>>; // Pass setter for password
  resendOtp: () => void; // Add this to pass the resendOtp function
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>; // Pass setter for confirmPassword
}

const ResetPassTemplate: React.FC<ResetPassTemplateProps> = ({
  email,
  otp,
  password,
  confirmPassword,
  errors,
  isLoading,
  handleInputChange,
  handleSubmit,
  setOtp,
  setPassword,
  setConfirmPassword,
  resendOtp,
}) => (
  <SharedLayout>
    <div className="w-full max-w-lg">
      <div className="text-center mb-8">
        <h2 className="font-bold text-4xl mb-4">Reset Password</h2>
        <p className="text-lg font-normal text-gray-600">
          Enter the One-time password sent to{" "}
          <span className="font-semibold">{email}</span> and reset your
          password.
        </p>
      </div>
      <ResetPassForm
        email={email}
        otp={otp}
        password={password}
        confirmPassword={confirmPassword}
        errors={errors}
        isLoading={isLoading}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        setOtp={setOtp}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        resendOtp={resendOtp}
      />
    </div>
  </SharedLayout>
);

export default ResetPassTemplate;
