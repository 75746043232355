import endpoints from "../../../api/endpoints";
import { apiSlice } from "../../../services/baseApi";
import { setUser } from "../../../redux/Slices/Authentication/authSlice";
import { IOtpRequest, IOtpResponse } from "./otp.types";

// Inject the OTP verification API endpoint into the base API
const otpApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyOtp: builder.mutation<IOtpResponse, IOtpRequest>({
      query: (body) => ({
        url: endpoints.verifyOtp, // Replace with your actual API endpoint
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Dispatch action to update tokens in Redux store
          dispatch(
            setUser({
              access_token: data.access_token,
              refresh_token: data.refresh_token,
            })
          );
        } catch (error) {
          console.error("OTP verification failed: ", error);
        }
      },
    }),

    resendOtp: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: endpoints.resendOtp, // Replace with your actual API endpoint for resending OTP
        method: "POST",
        body,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          console.error("Resend OTP failed: ", error);
        }
      },
    }),
  }),
});

// Export the hooks for components to use the mutations
export const { useVerifyOtpMutation, useResendOtpMutation } = otpApi;
