import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForgetPassMutation } from "./forgetpass.service";
import ForgetPassTemplate from "../../../components/templates /Authentication/ForgetPassTemplate";

const ForgetPass = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<string | null>(null);
  const [forgetPass, { isLoading }] = useForgetPassMutation();
  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrors(null); // Clear errors on change
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setErrors("Please enter a valid email address.");
      return;
    }

    try {
      const response = await forgetPass({ email }).unwrap();
      if (response?.email) {
        localStorage.setItem("resetPassAllowed", "true"); // Allow access to reset password
        navigate("/resetpass", { state: { email } });
      } else {
        setErrors("Failed to send One-time password. Please try again.");
      }
    } catch (err: any) {
      setErrors(
        err?.data?.message ||
          "Failed to send One-time password. Please try again."
      );
    }
  };

  return (
    <ForgetPassTemplate
      email={email}
      handleEmailChange={handleEmailChange}
      handleSubmit={handleSubmit}
      errors={errors}
      isLoading={isLoading}
    />
  );
};

export default ForgetPass;
