import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Avatar,
  IconButton,
} from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { Profile } from "./profile.types"; // Importing profile structure from profile.types

const ProfilePage: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState<Profile>({
    firstName: "Sébastien",
    lastName: "Garnier",
    email: "sebastien@hamacsuites.com",
    phone: "+33 6 42 98 00 99",
    profilePicture: "", // Default is empty
    accountCreationDate: "Monday 08/07/2024 @ 11:21:25",
    company: {
      name: "Hamac Investissements & Patrimoine",
      address: "19 boulevard Baron du Marais",
      zipCode: "69110",
      city: "Sainte Foy lès Lyon",
      siretNumber: "89954018100026",
      vatNumber: "FR45899540181",
      socialLinks: {
        website: "",
      },
    },
  });

  const [editedProfile, setEditedProfile] = useState<Profile>(profile);

  // Handle profile picture change
  const handleProfilePictureChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setEditedProfile((prev) => ({
        ...prev,
        profilePicture: URL.createObjectURL(file), // Temporary display of the image
      }));
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      setProfile(editedProfile); // Save changes
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: "1000px",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          My Profile
        </Typography>

        {/* Personal Information */}
        <Grid container spacing={3}>
          {/* Profile Picture */}
          <Grid item xs={12} sm={3}>
            <div style={{ textAlign: "center", position: "relative" }}>
              <Avatar
                src={editedProfile.profilePicture || ""}
                alt="Profile Picture"
                sx={{ width: 150, height: 150 }}
                style={{
                  borderRadius: "50%",
                  border: "4px solid #ddd",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Show the person icon if no profile picture and not in edit mode */}
                {!editedProfile.profilePicture && !isEditing && (
                  <Typography variant="h5" style={{ color: "gray" }}>
                    <i className="fas fa-user" />{" "}
                    {/* FontAwesome person icon */}
                  </Typography>
                )}
              </Avatar>

              {/* Edit Profile Picture Button */}
              {isEditing && (
                <IconButton
                  component="label"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "#f5f5f5",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                >
                  <FaEdit style={{ color: "black" }} />
                  <input
                    type="file"
                    hidden
                    onChange={handleProfilePictureChange}
                  />
                </IconButton>
              )}
            </div>
          </Grid>

          {/* Personal Info */}
          <Grid item xs={12} sm={9}>
            <CardContent>
              <Typography variant="h6">Personal Information</Typography>

              <TextField
                label="E-mail"
                value={profile.email}
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                label="First Name"
                value={editedProfile.firstName || ""}
                onChange={(e) =>
                  setEditedProfile((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  readOnly: !isEditing,
                }}
              />

              <TextField
                label="Last Name"
                value={editedProfile.lastName || ""}
                onChange={(e) =>
                  setEditedProfile((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  readOnly: !isEditing,
                }}
              />

              <TextField
                label="Phone"
                value={editedProfile.phone || ""}
                onChange={(e) =>
                  setEditedProfile((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }))
                }
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  readOnly: !isEditing,
                }}
              />
            </CardContent>
          </Grid>

          {/* Company Information */}
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              My Company
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Company Name"
              value={editedProfile.company.name || ""}
              onChange={(e) =>
                setEditedProfile((prev) => ({
                  ...prev,
                  company: {
                    ...prev.company,
                    name: e.target.value,
                  },
                }))
              }
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditing,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              value={editedProfile.company.address || ""}
              onChange={(e) =>
                setEditedProfile((prev) => ({
                  ...prev,
                  company: {
                    ...prev.company,
                    address: e.target.value,
                  },
                }))
              }
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditing,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Zip Code"
              value={editedProfile.company.zipCode || ""}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditing,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              value={editedProfile.company.city || ""}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditing,
              }}
            />
          </Grid>

          {/* Social Networks */}
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Social Networks
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Website"
              value={editedProfile.company.socialLinks.website || ""}
              onChange={(e) =>
                setEditedProfile((prev) => ({
                  ...prev,
                  company: {
                    ...prev.company,
                    socialLinks: {
                      ...prev.company.socialLinks,
                      website: e.target.value,
                    },
                  },
                }))
              }
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditing,
              }}
            />
          </Grid>
        </Grid>

        {/* Edit/Save Buttons */}
        <div style={{ textAlign: "right", marginTop: "40px" }}>
          <Button
            variant={isEditing ? "contained" : "outlined"}
            style={{
              backgroundColor: isEditing ? "black" : "white",
              color: isEditing ? "white" : "black",
              fontWeight: "bold",
              borderRadius: "8px",
              padding: "10px 20px",
              marginRight: "10px",
            }}
            onClick={handleEditToggle}
          >
            {isEditing ? "Save Changes" : "Edit Profile"}
          </Button>

          {isEditing && (
            <Button
              variant="outlined"
              style={{
                color: "black",
                borderColor: "grey",
                fontWeight: "bold",
                borderRadius: "8px",
                padding: "10px 20px",
              }}
              onClick={() => {
                setEditedProfile(profile); // Revert changes
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ProfilePage;
