import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "../features/authentication/login/login";
import SignUp from "../features/authentication/signup/signup";
import ProtectedRoute from "./protectedRoutes/protectedRoute";
import ForgetPass from "../features/authentication/forgetPass/forgetpass";
import ResetPass from "../features/authentication/resetPass/resetpass";
import Otp from "../features/authentication/otp/otp";
import Properties from "../features/athenticated/dashboard/properties/properties";
import Calendar from "../features/athenticated/dashboard/calendar/calendar";
import PropertyDetail from "../features/athenticated/dashboard/PropertyDetails/PropertyDetail";
import Profile from "../features/athenticated/profile/profile";
import PropertyGuides from "../features/athenticated/dashboard/PropertyGuides/PropertyGuides";
import PropertyMessages from "../features/athenticated/dashboard/PropertyMessages/PropertyMessages";
import MessagesLibrary from "../features/athenticated/dashboard/PropertyMessages/MessagesLibrary";
import MessagesTemplates from "../features/athenticated/dashboard/PropertyMessages/MessagesTemplate";
import Msgs from "../features/athenticated/dashboard/PropertyMessages/PropertyMessages"; // Adjust this import if Msgs is elsewhere
import Navbar from "../components/organisms /Navbar";
// import UserPropertyPage from "../features/public/guestPage/guest";
import PropertyFaqFeature from "../features/athenticated/dashboard/PropertyFaq/propertyFaq";
import { getAccessToken } from "../utils/shared/tokenService";
import PrivacyAndPolicy from "../features/public/PrivacyAndPolicy/privacy";
import Guest from "../features/public/guestPage/guest.page";

const OtpWrapper = () => {
  const location = useLocation();
  const { email } = location.state || {};

  if (!email) {
    return <Navigate to="/login" replace />;
  }

  return <Otp email={email} />;
};

const MainRouter: React.FC = () => {
  const location = useLocation();
  const isAuthenticated = !!getAccessToken(); // Check if token exists

  return (
    <Routes location={location}>
      {/* Authentication Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgetpass" element={<ForgetPass />} />

      <Route path="/privacyandpolicy" element={<PrivacyAndPolicy />} />

      {/* OTP Route - Protected and conditional */}
      <Route
        path="/otp"
        element={
          <ProtectedRoute allowedFrom="otp">
            <OtpWrapper />
          </ProtectedRoute>
        }
      />

      {/* Reset Password Route */}
      <Route
        path="/resetpass"
        element={
          <ProtectedRoute allowedFrom="resetpass">
            <ResetPass />
          </ProtectedRoute>
        }
      />

      {/* Guest Page with Mode Handling */}
      <Route
        path="/guest"
        element={
          <ProtectedRoute>
            <Guest />
          </ProtectedRoute>
        }
      />

      {/* Authenticated Routes with Navbar */}
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <>
              <Navbar /> {/* Navbar for authenticated users */}
              <Routes>
                <Route path="/messages-library" element={<MessagesLibrary />} />
                <Route path="/properties" element={<Properties />} />
                <Route path="/property/:id" element={<PropertyDetail />} />
                <Route
                  path="/property/:id/faq"
                  element={<PropertyFaqFeature />}
                />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/property/:id/guides"
                  element={<PropertyGuides />}
                />
                <Route
                  path="/property/:id/messages"
                  element={<PropertyMessages />}
                />
                <Route path="/property/:id/messages" element={<Msgs />}>
                  <Route path="library" element={<MessagesLibrary />} />
                  <Route path="templates" element={<MessagesTemplates />} />
                </Route>
              </Routes>
            </>
          </ProtectedRoute>
        }
      />

      {/* Redirect unauthenticated users to login */}
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/properties" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
    </Routes>
  );
};

export default MainRouter;
