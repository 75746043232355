// src/components/atoms/LoadingSpinner.tsx
import React from "react";

const LoadingSpinner: React.FC = () => (
  <div className="loading-spinner">
    <div className="spinner">Loading...</div>
  </div>
);

export default LoadingSpinner;
