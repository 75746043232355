import React from "react";
import SharedLayout from "../../../utils/shared/sharedLayout";
import OtpForm from "../../organisms /Authentication/OtpForm";

interface OtpTemplateProps {
  otp: string;
  errors: string | null;
  isVerifying: boolean;
  isResending: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleResendOtp: () => void;
}

const OtpTemplate: React.FC<OtpTemplateProps> = ({
  otp,
  errors,
  isVerifying,
  isResending,
  handleInputChange,
  handleSubmit,
  handleResendOtp,
}) => (
  <SharedLayout>
    <div className="w-full max-w-lg">
      <div className="text-center mb-8">
        <h2 className="font-bold text-4xl mb-4">Verify OTP</h2>
        <p className="text-lg font-normal text-gray-600">
          Enter the One-time password sent to your email.
        </p>
      </div>
      <OtpForm
        otp={otp}
        errors={errors}
        isVerifying={isVerifying}
        isResending={isResending}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        handleResendOtp={handleResendOtp}
      />
    </div>
  </SharedLayout>
);

export default OtpTemplate;
