import {
  GuestContactDetails,
  GuestPropertyDetails,
} from "../../../features/public/guestPage/guest.types";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";

interface GuestContactsOrganismeProps {
  guestPropertyDetails: GuestPropertyDetails | undefined;
  guestContactsDetails: GuestContactDetails | undefined;
}

const GuestContactsOrganisme: React.FC<GuestContactsOrganismeProps> = ({
  guestPropertyDetails,
  guestContactsDetails,
}) => (
  <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
    {/* Google Maps Embed */}
    {guestPropertyDetails?.data.latitude &&
    guestPropertyDetails?.data.longitude ? (
      <div className="rounded-lg overflow-hidden shadow-lg w-full h-64 md:h-auto">
        <iframe
          src={`https://www.google.com/maps?q=${guestPropertyDetails.data.latitude},${guestPropertyDetails.data.longitude}&z=15&output=embed`}
          width="100%"
          height="100%"
          className="iframe-style"
          allowFullScreen={true}
          title="Google Maps"
        ></iframe>
      </div>
    ) : (
      <p>{""}</p>
    )}

    {/* Contact Details */}
    {guestContactsDetails?.data && (
      <div className="flex flex-col justify-center items-start space-y-4 sm:space-y-6 bg-gray-50 p-4 sm:p-6 rounded-lg shadow-lg">
        {guestContactsDetails.title && (
          <h2 className="text-xl sm:text-2xl font-semibold">
            {guestContactsDetails.title}
          </h2>
        )}

        {/* Contact Information */}
        <div className="text-base sm:text-lg">
          {guestContactsDetails.data.phone_number && (
            <p className="flex items-center space-x-2">
              <CallIcon />
              <span>{guestContactsDetails.data.phone_number}</span>
            </p>
          )}
          {guestContactsDetails.data.email && (
            <p className="flex items-center space-x-2">
              <MailIcon />
              <span>{guestContactsDetails.data.email}</span>
            </p>
          )}
        </div>

        {/* Contact Buttons */}
        <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-3 sm:space-y-0">
          {guestContactsDetails.data.phone_number && (
            <>
              <a
                href={`https://wa.me/${guestContactsDetails.data.phone_number}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center space-x-2 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition text-sm sm:text-base"
              >
                <WhatsAppIcon />
                <span>WhatsApp</span>
              </a>
              <a
                href={`tel:${guestContactsDetails.data.phone_number}`}
                className="flex items-center justify-center space-x-2 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition text-sm sm:text-base"
              >
                <CallIcon />
                <span>Call</span>
              </a>
            </>
          )}
          {guestContactsDetails.data.email && (
            <a
              href={`mailto:${guestContactsDetails.data.email}`}
              className="flex items-center justify-center space-x-2 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg shadow hover:bg-gray-100 transition text-sm sm:text-base"
            >
              <MailIcon />
              <span>Email</span>
            </a>
          )}
        </div>
      </div>
    )}
  </div>
);

export default GuestContactsOrganisme;
