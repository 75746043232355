import { useState } from "react";
import { GuestGuidesDetails } from "../../../features/public/guestPage/guest.types";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import parse from "html-react-parser";
import CloseIcon from "@mui/icons-material/Close";

interface GuestGuidesOrganismeProps {
  guestGuidesDetails: GuestGuidesDetails | undefined;
}

const GuestGuidesOrganisme: React.FC<GuestGuidesOrganismeProps> = ({
  guestGuidesDetails,
}) => {
  const [selectedGuide, setSelectedGuide] = useState<any | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState<{
    [key: number]: boolean;
  }>({});
  const handleToggleCategory = (index: number) => {
    setExpandedCategories((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleGuideClick = (guide: any) => {
    setSelectedGuide(guide);
    setOpenDialog(true);
  };

  // Error handling and empty state handling
  if (!guestGuidesDetails) {
    return <div className="container mx-auto"></div>;
  }

  if (
    !guestGuidesDetails.data.categories ||
    guestGuidesDetails.data.categories.length === 0
  ) {
    return <div className="container mx-auto"></div>;
  }
  return (
    <div className="container mx-auto">
      <div className="mt-8">
        <h2 className="text-2xl font-semibold">{guestGuidesDetails.title}</h2>
        {guestGuidesDetails.data.categories
          .filter((category) => category.guides && category.guides.length > 0)
          .map((category, categoryIndex) => (
            <div
              key={categoryIndex}
              className="mt-6 bg-white p-4 rounded-lg shadow"
            >
              <h3
                className="text-xl font-bold cursor-pointer p-2 flex justify-between items-center"
                onClick={() => handleToggleCategory(categoryIndex)}
              >
                {/* Category Image with Rounded Corners */}
                <div className="flex items-center">
                  {category.image && (
                    <img
                      src={category.image}
                      alt={category.name}
                      className="w-10 h-10 rounded-lg mr-4 object-cover"
                    />
                  )}
                  <span>{category.name}</span>
                </div>

                <span className="ml-2">
                  {expandedCategories[categoryIndex] ? "−" : "+"}
                </span>
              </h3>

              {/* Guides inside the category */}
              {expandedCategories[categoryIndex] && (
                <div className="grid grid-cols-1 gap-4 mt-4">
                  {category.guides.map((guide, guideIndex) => (
                    <div
                      key={guideIndex}
                      className="bg-gray-100 p-4 rounded-lg shadow cursor-pointer flex items-center"
                      onClick={() => handleGuideClick(guide)}
                    >
                      {guide.main_image && (
                        <img
                          src={guide.main_image}
                          alt={guide.name}
                          className="w-16 h-16 rounded-lg object-cover mr-4"
                        />
                      )}
                      <div>
                        <h4 className="text-lg font-semibold">{guide.name}</h4>
                        {/* <div className="mt-2">{parse(guide.description)}</div> */}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>

      {/* Dialog for Guide Details */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        {selectedGuide && (
          <>
            <DialogTitle>
              {selectedGuide.name}
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              dividers
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <div className="w-full">
                {/* Main Image */}
                {selectedGuide.main_image && (
                  <img
                    src={selectedGuide.main_image}
                    alt={selectedGuide.name || "Guide"}
                    className="w-auto h-48 mb-4 rounded-t-lg mx-auto"
                  />
                )}

                {/* Guide Title */}
                {/* {selectedGuide.name && (
                  <h2 className="text-xl font-semibold mb-4">
                    {selectedGuide.name}
                  </h2>
                )} */}

                {/* Guide Description */}
                {selectedGuide.description && (
                  <div className="text-gray-700 mb-6">
                    {parse(selectedGuide.description)}
                  </div>
                )}

                {/* Media Images */}
                {selectedGuide.medias && selectedGuide.medias.length > 0 && (
                  <>
                    <hr className="border-t border-gray-300 my-4" />
                    <div className="grid grid-cols-1 gap-4">
                      {selectedGuide.medias.map((media: any, index: number) => (
                        <div key={index}>
                          <img
                            src={media.file}
                            alt=""
                            className="w-full h-auto mb-4 rounded-lg"
                          />
                          {index < selectedGuide.medias.length - 1 && (
                            <hr className="border-t border-gray-300 my-4" />
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};
export default GuestGuidesOrganisme;
