import endpoints from "../../../../api/endpoints";
import { apiSlice } from "../../../../services/baseApi";
import {
  AddGuideRequest,
  Category,
  Guide,
  UpdateGuideRequest,
} from "../properties/properties.types";

const guideApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Add Guide (regular guide data, no FormData)
    addGuide: builder.mutation<Guide, AddGuideRequest>({
      query: (newGuide) => ({
        url: endpoints.guide,
        method: "POST",
        body: newGuide,
      }),
    }),

    // Edit Guide (regular guide data, no FormData)
    editGuide: builder.mutation<Guide, UpdateGuideRequest>({
      query: (guide) => ({
        url: `${endpoints.guide}${guide.id}/`,
        method: "PUT",
        body: guide, // Use the guide object directly
      }),
    }),

    // Patch Guide Main Image (separate mutation for image upload via FormData)
    editGuideMainImage: builder.mutation<void, { id: string; image: File }>({
      query: ({ id, image }) => {
        const formData = new FormData();
        formData.append("main_image", image);

        return {
          url: `${endpoints.guide}${id}/`, // Assuming "/main-image" for PATCH
          method: "PATCH",
          body: formData,
        };
      },
    }),

    // Add a new mutation for patching the is_important field
    patchGuideImportant: builder.mutation<
      void,
      { id: string; is_important: boolean }
    >({
      query: ({ id, is_important }) => ({
        url: `${endpoints.guide}${id}/`, // Assuming your API accepts PATCH on this endpoint
        method: "PATCH",
        body: { is_important }, // Only update is_important field
      }),
    }),

    // Delete Guide
    deleteGuide: builder.mutation<void, string>({
      query: (id) => ({
        url: `${endpoints.guide}${id}`,
        method: "DELETE",
      }),
    }),

    // Fetch Guides by property and category
    getGuides: builder.query<
      Guide[],
      { propertyId: string; categoryKey: string }
    >({
      query: ({ propertyId, categoryKey }) => ({
        url: endpoints.guide,
        method: "GET",
        params: {
          property: propertyId,
          category: categoryKey,
        },
      }),
    }),

    // Upload media to guide
    uploadMedia: builder.mutation<{ id: string; file: string }, FormData>({
      query: (formData) => ({
        url: `${endpoints.baseUrl}medias/`,
        method: "POST",
        body: formData,
      }),
    }),

    // Add Category using FormData for name and image
    addCategory: builder.mutation<void, { name: string; image?: File }>({
      query: (newCategory) => {
        const formData = new FormData();
        formData.append("name", newCategory.name);
        if (newCategory.image) {
          formData.append("image", newCategory.image);
        }

        return {
          url: endpoints.guideCategories,
          method: "POST",
          body: formData,
        };
      },
    }),

    // Edit Category using FormData for name and image
    editCategory: builder.mutation<
      void,
      { id: string; name: string; image?: File }
    >({
      query: ({ id, name, image }) => {
        const formData = new FormData();
        formData.append("name", name);
        if (image) {
          formData.append("image", image);
        }

        return {
          url: `${endpoints.guideCategories}${id}/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    updateCategoryOrder: builder.mutation<
      void,
      { categories: { order: number; category: string }[]; property: string }
    >({
      query: ({ categories, property }) => ({
        url: `properties/${property}/categories/`, // Dynamically inject property into URL
        method: "POST",
        body: categories, // Send the updated categories with order
      }),
    }),

    getCategories: builder.query<Category[], { propertyId: string }>({
      query: ({ propertyId }) => ({
        url: endpoints.guideCategories,
        method: "GET",
        params: {
          property: propertyId, // Add the propertyId as a query parameter
        },
      }),
    }),

    updateGuideOrder: builder.mutation<
      void,
      { payload: { guide: string; order: number }[] }
    >({
      query: ({ payload }) => ({
        url: "guides/order/",
        method: "POST",
        body: payload,
      }),
    }),

    deleteCategory: builder.mutation<void, string>({
      query: (id) => ({
        url: `${endpoints.guideCategories}${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

// Export hooks for use in components
export const {
  useAddGuideMutation,
  useEditGuideMutation,
  useDeleteGuideMutation,
  useGetGuidesQuery,
  useUploadMediaMutation,
  useAddCategoryMutation,
  usePatchGuideImportantMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useEditGuideMainImageMutation, // Export for image patch
  useUpdateCategoryOrderMutation,
  useUpdateGuideOrderMutation,
} = guideApi;

export default guideApi;
