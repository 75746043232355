import React from "react";
import { FaTimes, FaExclamationCircle } from "react-icons/fa";
import { useDrag, useDrop } from "react-dnd";
import ReactPlayer from "react-player";
import { Media } from "../../features/athenticated/dashboard/properties/properties.types";

interface DragItem {
  index: number;
  type: string;
}

interface DraggableMediaGridItemProps {
  media: Media;
  index: number;
  moveImage: (fromIndex: number, toIndex: number) => void;
  isDragging: boolean;
  draggedOverIndex: number | null;
  setDraggedOverIndex: (index: number | null) => void;
  isEditing: boolean;
  onDelete: (mediaId: string) => void;
  onRetryUpload: (file: File, tempMediaId: string) => void;
}

const DraggableMediaGridItem: React.FC<DraggableMediaGridItemProps> = ({
  media,
  index,
  moveImage,
  isDragging,
  draggedOverIndex,
  setDraggedOverIndex,
  isEditing,
  onDelete,
  onRetryUpload,
}) => {
  // Set up drag
  const [{ isDraggingItem }, dragRef] = useDrag({
    type: "IMAGE",
    item: { index },
    canDrag: () => isEditing, // Only allow dragging in edit mode
    collect: (monitor) => ({
      isDraggingItem: monitor.isDragging(),
    }),
  });

  // Set up drop
  const [{ canDrop }, dropRef] = useDrop<DragItem, void, { canDrop: boolean }>({
    accept: "IMAGE",
    hover: (item) => {
      if (draggedOverIndex !== index && !isDraggingItem) {
        setDraggedOverIndex(index);
      }
    },
    drop: (item: DragItem) => {
      if (item.index !== index) {
        moveImage(item.index, index); // Reorder items
      }
      setDraggedOverIndex(null);
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  // Combined drag and drop ref
  const combinedRef = (node: HTMLDivElement | null) => {
    dragRef(node);
    dropRef(node);
  };

  // Determine if the media is an image or a video (supports .mp4, .mov, and other video types)
  const isVideo = /\.(mp4|mov|avi|webm|mkv)$/i.test(media.file);

  return (
    <div
      ref={combinedRef}
      style={{
        position: "relative",
        padding: "4px",
        cursor: isEditing ? "move" : "not-allowed",
        opacity: isDraggingItem ? 0.4 : 1,
        borderRadius: "8px",
        width: "100%",
        height: "100%",
        transition: "transform 0.2s ease-in-out",
        zIndex: isDraggingItem ? 999 : "auto",
        transform: draggedOverIndex === index && canDrop ? "scale(1.05)" : "",
      }}
    >
      {/* Media Box */}
      <div
        style={{
          width: "120px",
          height: "200px",
          borderRadius: "8px",
          overflow: "visible",
          position: "relative",
        }}
      >
        {isVideo ? (
          // Render the video using ReactPlayer
          <ReactPlayer
            url={media.file}
            playing={true}
            controls={true}
            width="100%"
            height="100%"
            style={{ borderRadius: "8px" }}
          />
        ) : (
          // Render the image
          <img
            src={media.file}
            alt={media.label}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            onError={(e) => {
              (e.target as HTMLImageElement).src =
                "https://via.placeholder.com/120x200.png?text=No+Image"; // Fallback for broken images
            }}
          />
        )}

        {/* Delete Button */}
        {isEditing && (
          <FaTimes
            onClick={() => onDelete(media.media_id)}
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              background: "rgba(0, 0, 0, 0.6)",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              padding: "8px",
              zIndex: 2,
              fontSize: "25px",
            }}
          />
        )}

        {/* Retry Upload Button */}
        {media.error && media.originalFile && (
          <FaExclamationCircle
            onClick={() =>
              onRetryUpload(media.originalFile as File, media.media_id)
            }
            style={{
              position: "absolute",
              top: "90px",
              left: "45px",
              background: "rgba(255, 0, 0, 0.6)",
              borderRadius: "50%",
              color: "white",
              cursor: "pointer",
              padding: "5px",
              zIndex: 1,
              fontSize: "32px",
            }}
          />
        )}

        {/* Loading State */}
        {media.isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
            }}
          >
            <div
              className="loader"
              style={{
                width: "30px",
                height: "30px",
                border: "4px solid #f3f3f3",
                borderRadius: "50%",
                borderTop: "4px solid #3498db",
                animation: "spin 1s linear infinite",
              }}
            ></div>
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
          </div>
        )}

        {/* Visual cue for dragging */}
        {draggedOverIndex === index && !isDraggingItem && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: "2px dashed #007bff",
              borderRadius: "8px",
              backgroundColor: "rgba(0, 123, 255, 0.1)",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DraggableMediaGridItem;
