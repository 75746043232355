import endpoints from "../../../../api/endpoints";
import { apiSlice } from "../../../../services/baseApi";
import {
  ExtendedProperty,
  Contact,
  Property,
  EditableProperty,
} from "../properties/properties.types"; // Import the types

const propertyDetailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyById: builder.query<ExtendedProperty, string>({
      query: (id) => ({
        url: `${endpoints.properties}${id}`,
        method: "GET",
      }),
    }), //   void,
    //   { propertyId: string; contact: string }
    // >({
    //   query: ({ propertyId, contact }) => ({
    //     url: `/properties/${propertyId}`,
    //     method: "PATCH",
    //     body: { contact }, // Assuming the backend expects `contactId` in the request body
    //   }),
    // }),

    getContacts: builder.query<Contact[], void>({
      query: () => ({
        url: "contacts/",
        method: "GET",
      }),
    }),

    addContact: builder.mutation<Contact, Contact>({
      query: (newOwner) => ({
        url: "contacts/",
        method: "POST",
        body: newOwner, // Remove extra object wrapping
      }),
    }),

    updateAmenities: builder.mutation<
      Property,
      { propertyId: string; updates: Partial<EditableProperty> }
    >({
      query: ({ propertyId, updates }) => ({
        url: `/amenities/${propertyId}/`, // Assuming `endpoints.amenities` holds the base URL for amenities
        method: "PATCH",
        body: updates, // Send only updated fields
      }),
    }),

    //   Property,
    //   { id: string; internal_name: string }
    // >({
    //   query: ({ id, internal_name }) => ({
    //     url: `${endpoints.properties}${id}`,
    //     method: "PATCH",
    //     body: { internal_name },
    //   }),
    // }),

    // Define the updateProperty mutation in your API slice
    updateProperty: builder.mutation<
      Property,
      { propertyId: string; updates: Partial<EditableProperty> }
    >({
      query: ({ propertyId, updates }) => ({
        url: `${endpoints.properties}${propertyId}/`,
        method: "PATCH",
        body: updates, // Only send the fields included in `updates`
      }),
    }),
    // New endpoint for getting available languages for a property
    getLanguagesByPropertyId: builder.query<{ languages: string[] }, string>({
      query: (propertyId) => ({
        url: `${endpoints.properties}${propertyId}/languages/`,
        method: "GET",
      }),
    }),

    // New endpoint for updating available languages for a property
    updateLanguagesByPropertyId: builder.mutation<
      void,
      { propertyId: string; languages: string[] }
    >({
      query: ({ propertyId, languages }) => ({
        url: `${endpoints.properties}${propertyId}/languages/`,
        method: "POST",
        body: { languages },
      }),
    }),
  }),
});

export const {
  useGetPropertyByIdQuery,
  useUpdatePropertyMutation,
  useUpdateAmenitiesMutation,
  useAddContactMutation,
  useGetContactsQuery,
  useGetLanguagesByPropertyIdQuery,
  useUpdateLanguagesByPropertyIdMutation,
} = propertyDetailsApi;

export default propertyDetailsApi;
