import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostHogProvider } from "posthog-js/react";
import posthog, { PostHogConfig } from "posthog-js";

// Define PostHog options with the correct type
const posthogOptions: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_POSTHOG_HOST || "https://us.i.posthog.com",
  persistence: "localStorage", // TypeScript now recognizes this value
  loaded: (posthogInstance) => {
    console.log("PostHog loaded", posthogInstance);
  },
};

// Initialize PostHog
posthog.init(process.env.REACT_APP_POSTHOG_KEY || "", posthogOptions);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

// reportWebVitals();
