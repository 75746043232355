import React, { ChangeEvent, FormEvent } from "react";
import Button from "../../atoms/Button"; // Reuse Button component
import ErrorMessage from "../../atoms/ErrorMessage"; // Reuse ErrorMessage component
import InputField from "../../atoms/Input";

interface ResetPassFormProps {
  email: string;
  otp: string;
  password: string;
  confirmPassword: string;
  errors: string | null;
  isLoading: boolean;
  handleInputChange: (
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  resendOtp: () => void; // Add this to pass the resendOtp function
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
}

const ResetPassForm: React.FC<ResetPassFormProps> = ({
  email,
  otp,
  password,
  confirmPassword,
  errors,
  isLoading,
  handleInputChange,
  handleSubmit,
  resendOtp, // Include the resendOtp function here
  setOtp,
  setPassword,
  setConfirmPassword,
}) => (
  <form onSubmit={handleSubmit} className="space-y-6">
    <div className="mb-1 flex flex-col gap-4">
      <InputField
        id="otp"
        type="text"
        placeholder="Enter One-time password"
        value={otp}
        onChange={handleInputChange(setOtp)}
        label="Enter OTP"
        errorMessage={errors || ""}
      />

      <InputField
        id="password"
        type="password"
        placeholder="New Password"
        value={password}
        onChange={handleInputChange(setPassword)}
        label="New Password"
        errorMessage={errors || ""}
      />

      <InputField
        id="confirmPassword"
        type="password"
        placeholder="Re-enter New Password"
        value={confirmPassword}
        onChange={handleInputChange(setConfirmPassword)}
        label="Confirm New Password"
        errorMessage={errors || ""}
      />

      {errors && <ErrorMessage message={errors} />}
    </div>

    <Button text="Reset Password" isLoading={isLoading} fullWidth={true} />

    {/* Text under the button for resending OTP */}
    <div className="flex justify-end mt-4">
      <p className="text-sm text-gray-600">
        Didn't receive the code?{" "}
        <button
          type="button"
          onClick={resendOtp} // Call the resendOtp function here
          className="text-blue-600 underline"
        >
          Click here to resend.
        </button>
      </p>
    </div>
  </form>
);

export default ResetPassForm;
