import endpoints from "../../../api/endpoints";
import { apiSlice } from "../../../services/baseApi";
import { IResetPassRequest, IResetPassResponse } from "./resetpass.types";

// Inject the reset password API endpoint into the base API
const resetPassApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    resetPass: builder.mutation<IResetPassResponse, IResetPassRequest>({
      query: (body) => ({
        url: endpoints.resetPass, // Replace with your actual API endpoint
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useResetPassMutation } = resetPassApi;
