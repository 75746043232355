import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

type PropertyDetailsProps = {
  name: string;
  description: string;
  address: string;
  latitude: number;
  longitude: number;
  image?: string;
  id: string;
};

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  name,
  description,
  address,
  latitude,
  longitude,
  image,
  id,
}) => {
  const navigate = useNavigate();

  const handleUserPropertyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    window.open(`/guest?id=${id}&mode=template`, "_blank");
  };

  return (
    <div className="bg-gray-100 p-4 sm:p-6 rounded-lg shadow-lg flex flex-col sm:flex-row items-center sm:items-start">
      {image && (
        <img
          src={image}
          alt={name}
          className="w-24 h-24 sm:w-32 sm:h-32 object-cover mb-4 sm:mb-0 sm:mr-4 rounded"
        />
      )}
      <div className="flex-grow text-center sm:text-left">
        <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start mb-2">
          <h1 className="text-2xl sm:text-3xl font-bold mb-2 sm:mb-0">
            {name}
          </h1>
          <Button
            onClick={handleUserPropertyClick}
            style={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "4px",
              width: "100px",
              height: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="sm:w-36"
          >
            <PersonIcon
              style={{ fontSize: "18px", color: "white", marginRight: 4 }}
            />
            <span className="text-xs font-semibold text-white">Guest View</span>
          </Button>
        </div>
        <div className="text-xs sm:text-sm text-gray-500 space-y-1 sm:space-y-0">
          <p>
            <strong>Address:</strong> {address || "N/A"}
          </p>
          <p>
            <strong>Latitude:</strong> {latitude}
          </p>
          <p>
            <strong>Longitude:</strong> {longitude}
          </p>
          <p className="text-gray-600">Guest</p>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
