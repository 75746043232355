import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  isLoading: boolean;
  error: string | null;
  isOnline: boolean;
  isLoggedIn: boolean;
  authPrompt: boolean;
  requiresAuth: boolean; // New state for indicating if a page requires authentication
}

const initialState: AppState = {
  isLoading: false,
  error: null,
  isOnline: true,
  isLoggedIn: false,
  authPrompt: false,
  requiresAuth: false, // Initialize as not requiring authentication
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setOnlineStatus(state, action: PayloadAction<boolean>) {
      state.isOnline = action.payload;
    },
    setAuthStatus(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    setAuthPrompt(state, action: PayloadAction<boolean>) {
      // Only show the prompt if the current route requires authentication
      if (state.requiresAuth) {
        state.authPrompt = action.payload;
      } else {
        state.authPrompt = false;
      }
    },
    setRequiresAuth(state, action: PayloadAction<boolean>) {
      // Set whether the current page requires authentication
      state.requiresAuth = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setOnlineStatus,
  setAuthStatus,
  setAuthPrompt,
  setRequiresAuth,
} = appStateSlice.actions;

export default appStateSlice.reducer;
