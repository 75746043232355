import React, { CSSProperties } from "react";
import { Typography } from "@mui/material";

interface TextAtomProps {
  content: string;
  variant?: "h2" | "h3" | "p";
  style?: CSSProperties; // Allow custom styling
}

const TextAtom: React.FC<TextAtomProps> = ({
  content,
  variant = "p",
  style,
}) => (
  <Typography variant={variant as any} component={variant} style={style}>
    {content}
  </Typography>
);

export default TextAtom;
