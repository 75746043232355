import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import DraggableMediaGridItem from "../../atoms/DraggableMediaGridItem";
import { Media } from "../../../features/athenticated/dashboard/properties/properties.types";

interface ImageGridWidgetProps {
  medias: Media[];
  setMedias: (medias: Media[] | ((prevMedias: Media[]) => Media[])) => void;
  isEditing: boolean;
  onRetryUpload: (file: File, tempMediaId: string) => void;
  onDelete: (mediaId: string) => void;
  moveMedia: (fromIndex: number, toIndex: number) => void;
}

const ImageGridWidget: React.FC<ImageGridWidgetProps> = ({
  medias,
  setMedias,
  isEditing,
  onRetryUpload,
  onDelete,
  moveMedia,
}) => {
  const [draggedOverIndex, setDraggedOverIndex] = React.useState<number | null>(
    null
  );

  // Log the medias array when the component renders
  useEffect(() => {}, [medias]);

  const moveImage = (fromIndex: number, toIndex: number) => {
    moveMedia(fromIndex, toIndex); // Call the external moveMedia function
  };

  // Ensure there are always 8 columns per row by adding empty grid items
  const emptySlots = Array(8 - (medias.length % 8)).fill(null);

  return (
    <Grid container spacing={1}>
      {medias.map((media, index) => (
        <Grid
          item
          xs={3} // Adjust grid item width to take up more space (4 items per row on small screens)
          sm={1.5} // Adjust for medium and larger screens (8 items per row)
          key={media.media_id}
          sx={{
            height: "auto", // Remove fixed height
            width: "100%", // Allow images to take up full width of the container
          }}
        >
          <DraggableMediaGridItem
            media={media}
            index={index}
            moveImage={moveImage}
            isDragging={draggedOverIndex !== null}
            draggedOverIndex={draggedOverIndex}
            setDraggedOverIndex={setDraggedOverIndex}
            isEditing={isEditing}
            onRetryUpload={onRetryUpload}
            onDelete={onDelete}
          />
        </Grid>
      ))}

      {/* Render empty grid items to fill the row */}
      {emptySlots.map((_, index) => (
        <Grid
          item
          xs={3}
          sm={1.5}
          key={`empty-${index}`}
          sx={{
            height: "auto",
            width: "100%",
            visibility: "hidden", // Keep the space but hide empty items
          }}
        />
      ))}
    </Grid>
  );
};

export default ImageGridWidget;
