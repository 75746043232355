// src/components/atoms/NoInternetBanner.tsx
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { DialogActions } from "@mui/material";

interface NoInternetBannerProps {
  open: boolean;
}

const NoInternetBanner: React.FC<NoInternetBannerProps> = ({ open }) => {
  const handleClose = () => {
    // Optionally, handle any actions when the dialog is closed.
    // This could involve retrying connection or simply dismissing the popup.
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>No Internet Connection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are currently offline. Please check your network settings and try
          again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Retry
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoInternetBanner;
