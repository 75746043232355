import React from "react";
import { Card, Typography, Divider } from "@mui/material";
import {
  AddFaqRequest,
  Faq,
} from "../../../features/athenticated/dashboard/PropertyFaq/faq.types";
import ButtonAtom from "../../atoms/PropertyFaqs/ButtonAtom";
import FaqForm from "../../molecules /PropertyFaqs/FaqForm";
import FaqItem from "../../molecules /PropertyFaqs/FaqItem";

interface PropertyFaqOrganismProps {
  faqs: Faq[];
  editingFaqId: string | null;
  setEditingFaqId: React.Dispatch<React.SetStateAction<string | null>>;
  onAddFaq: (newFaq: AddFaqRequest) => Promise<void>;
  onEditFaq: (faqId: string, updatedFaq: Partial<Faq>) => Promise<void>;
  onDeleteFaq: (faqId: string) => Promise<void>;
  onSaveFaq: (updatedFaq: Faq) => void;
  onCancelEdit: () => void;
  propertyId: string;
}

const PropertyFaqOrganism: React.FC<PropertyFaqOrganismProps> = ({
  faqs,
  editingFaqId,
  setEditingFaqId,
  onAddFaq,
  onEditFaq,
  onDeleteFaq,
  onSaveFaq,
  onCancelEdit,
  propertyId,
}) => {
  return (
    <Card
      style={{
        padding: "24px",
        maxWidth: "800px",
        margin: "auto",
        backgroundColor: "#fff",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        style={{ fontWeight: "bold", marginBottom: "16px" }}
      >
        Property FAQs
      </Typography>
      <Divider style={{ marginBottom: "24px" }} />

      {faqs.length > 0 ? (
        faqs.map((faq) => (
          <FaqItem
            key={faq.id}
            faq={faq}
            isEditing={editingFaqId === faq.id}
            onEdit={() => setEditingFaqId(faq.id)}
            onDelete={() => onDeleteFaq(faq.id)}
            onSave={onSaveFaq}
            onCancel={onCancelEdit}
          />
        ))
      ) : (
        <Typography variant="body1" align="center" color="textSecondary">
          No FAQs available.
        </Typography>
      )}

      {editingFaqId === null && (
        <>
          <Divider style={{ margin: "24px 0" }} />
          <FaqForm propertyId={propertyId} onAdd={onAddFaq} />
        </>
      )}
    </Card>
  );
};

export default PropertyFaqOrganism;
