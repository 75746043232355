//================================================Guest FAQs Details================================================
export interface GuestFaqs {
  title: string;
  data: Datum[];
}

export interface Datum {
  id: string;
  created_at: Date;
  updated_at: Date;
  question: string;
  answer: string;
  created_by: string;
  updated_by: string;
  property: string;
}
// Mock data for GuestFaqs
export const mockGuestFaqs: GuestFaqs = {
  title: "Frequently Asked Questions",
  data: [
    {
      id: "faq1",
      created_at: new Date("2023-01-01T00:00:00Z"),
      updated_at: new Date("2023-01-02T00:00:00Z"),
      question: "What is the check-in time?",
      answer: "Check-in time is 3 PM.",
      created_by: "admin",
      updated_by: "admin",
      property: "property123",
    },
    {
      id: "faq2",
      created_at: new Date("2023-01-01T00:00:00Z"),
      updated_at: new Date("2023-01-02T00:00:00Z"),
      question: "Is breakfast included?",
      answer: "Yes, breakfast is included.",
      created_by: "admin",
      updated_by: "admin",
      property: "property123",
    },
  ],
};
//================================================Guest Guides Details================================================
export interface GuestGuidesDetails {
  title: string;
  data: Data;
}

export interface Data {
  featured_guides: Guide[];
  categories: Category[];
}

export interface Category {
  id: string;
  name: string;
  order: number;
  image: string;
  guides: Guide[];
}

export interface Guide {
  id: string;
  name: string;
  description: string;
  medias: Media[];
  property: string;
  category: string;
  is_important: boolean;
  main_image: null | string;
  order: number;
}

export interface Media {
  file: string;
  order: number;
  media_id: string;
}
// Mock data for GuestGuidesDetails
export const mockGuestGuidesDetails: GuestGuidesDetails = {
  title: "Featured Guides",
  data: {
    id: "data1",
    name: "Sample Data",
    description: "Sample description",
    address: "123 Sample Address",
    latitude: "0.0000",
    longitude: "0.0000",
    medias: [],
    main_image: "sample_image.jpg",
    is_hidden: false,
    amenities: {
      id: "amenities1",
      breakfast_included: false,
      has_parking: false,
      adults_only: false,
      pets_allowed: false,
      max_people: 0,
      units: 0,
      has_wifi: false,
      has_meal_plan: false,
      bedrooms: 0,
      bathrooms: 0,
      area_unit: "sqft",
      area: 0,
    },
    contact: "contact1",
    city: "Sample City",
    state: "Sample State",
    zip: "00000",
    guest: {
      id: "guest1",
      created_at: new Date(),
      updated_at: new Date(),
      name: "Sample Guest",
      email: null,
      phone_number: "0000000000",
      country: "Sample Country",
      country_code: "SC",
      external_details: {
        uid: "external1",
        name: "Sample External",
        email: null,
        country: null,
        country_code: null,
        phone_number: "0000000000",
      },
      created_by: "admin",
      updated_by: "admin",
    },
    reservation: {
      id: "reservation1",
      arrival: new Date(),
      departure: new Date(),
      property: "property1",
      source: "source1",
      title: "Sample Reservation",
      status: "confirmed",
      guest_link: "http://example.com",
      number_of_guests: 1,
      number_of_nights: 1,
      language: "en",
    },
    language: "en",
    featured_guides: [
      {
        id: "guide1",
        name: "Local Attractions",
        description: "Explore the best local attractions.",
        medias: [
          {
            id: "media1",
            file: "attraction1.jpg",
            order: 1,
            media_id: "media1",
          },
          {
            id: "media2",
            file: "attraction2.jpg",
            order: 2,
            media_id: "media2",
          },
        ],
        property: "property123",
        category: "category1",
        is_important: true,
        main_image: "main_image.jpg",
        order: 1,
      },
    ],
    categories: [
      {
        id: "category1",
        name: "Attractions",
        order: 1,
        image: "category_image.jpg",
        guides: [
          {
            id: "guide1",
            name: "Local Attractions",
            description: "Explore the best local attractions.",
            medias: [
              {
                id: "media1",
                file: "attraction1.jpg",
                order: 1,
                media_id: "media1",
              },
              {
                id: "media2",
                file: "attraction2.jpg",
                order: 2,
                media_id: "media2",
              },
            ],
            property: "property123",
            category: "category1",
            is_important: true,
            main_image: "main_image.jpg",
            order: 1,
          },
        ],
      },
    ],
  },
};
//================================================Guest Property Details================================================
export interface GuestPropertyDetails {
  title: string;
  data: Data;
}

export interface Data {
  id: string;
  name: string;
  description: string;
  address: string;
  latitude: string;
  longitude: string;
  medias: Media[];
  main_image: string;
  is_hidden: boolean;
  amenities: Amenities;
  contact: string;
  city: string;
  state: string;
  zip: string;
  guest: Guest;
  reservation: Reservation;
  language: string;
}

export interface Amenities {
  id: string;
  breakfast_included: boolean;
  has_parking: boolean;
  adults_only: boolean;
  pets_allowed: boolean;
  max_people: number;
  units: number;
  has_wifi: boolean;
  has_meal_plan: boolean;
  bedrooms: number;
  bathrooms: number;
  area_unit: string;
  area: number;
}

export interface Guest {
  id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  email: null;
  phone_number: string;
  country: string;
  country_code: string;
  external_details: ExternalDetails;
  created_by: string;
  updated_by: string;
}

export interface ExternalDetails {
  uid: string;
  name: string;
  email: null;
  country: null;
  country_code: null;
  phone_number: string;
}

export interface Media {
  id: string;
  file: string;
}

export interface Reservation {
  id: string;
  arrival: Date;
  departure: Date;
  property: string;
  source: string;
  title: string;
  status: string;
  guest_link: string;
  number_of_guests: number;
  number_of_nights: number;
  language: string;
}
// Mock data for GuestPropertyDetails
export const mockGuestPropertyDetails: GuestPropertyDetails = {
  title: "Property Details",
  data: {
    id: "property123",
    name: "Beautiful Beach House",
    description: "A beautiful beach house with stunning ocean views.",
    address: "123 Ocean Drive, Miami, FL",
    latitude: "25.7617",
    longitude: "-80.1918",
    medias: [
      { id: "media1", file: "image1.jpg", order: 1, media_id: "media1" },
      { id: "media2", file: "image2.jpg", order: 2, media_id: "media2" },
    ],
    main_image: "main_image.jpg",
    is_hidden: false,
    amenities: {
      id: "amenities123",
      breakfast_included: true,
      has_parking: true,
      adults_only: false,
      pets_allowed: true,
      max_people: 6,
      units: 1,
      has_wifi: true,
      has_meal_plan: false,
      bedrooms: 3,
      bathrooms: 2,
      area_unit: "sqft",
      area: 1500,
    },
    contact: "contact123",
    city: "Miami",
    state: "FL",
    zip: "33101",
    guest: {
      id: "guest123",
      created_at: new Date("2023-01-01T00:00:00Z"),
      updated_at: new Date("2023-01-02T00:00:00Z"),
      name: "John Doe",
      email: null,
      phone_number: "+1234567890",
      country: "USA",
      country_code: "US",
      external_details: {
        uid: "external123",
        name: "John Doe",
        email: null,
        country: null,
        country_code: null,
        phone_number: "+1234567890",
      },
      created_by: "admin",
      updated_by: "admin",
    },
    reservation: {
      id: "res123",
      arrival: new Date("2023-02-01T00:00:00Z"),
      departure: new Date("2023-02-10T00:00:00Z"),
      property: "property123",
      source: "website",
      title: "Vacation Stay",
      status: "confirmed",
      guest_link: "http://example.com/guest123",
      number_of_guests: 2,
      number_of_nights: 9,
      language: "en",
    },
    language: "en",
    featured_guides: [],
    categories: [],
  },
};

//================================================Guest Reservation Details================================================
export interface GuestReservationDetails {
  guest: Guest;
  reservation: Reservation;
  language: string;
}

export interface Guest {
  id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  email: null;
  phone_number: string;
  country: string;
  country_code: string;
  external_details: ExternalDetails;
  created_by: string;
  updated_by: string;
}

export interface ExternalDetails {
  uid: string;
  name: string;
  email: null;
  country: null;
  country_code: null;
  phone_number: string;
}

export interface Reservation {
  id: string;
  arrival: Date;
  departure: Date;
  property: string;
  source: string;
  title: string;
  status: string;
  guest_link: string;
  number_of_guests: number;
  number_of_nights: number;
  language: string;
}
// Mock data for GuestReservationDetails
export const mockGuestReservationDetails: GuestReservationDetails = {
  guest: {
    id: "guest123",
    created_at: new Date("2023-01-01T00:00:00Z"),
    updated_at: new Date("2023-01-02T00:00:00Z"),
    name: "John Doe",
    email: null,
    phone_number: "+1234567890",
    country: "USA",
    country_code: "US",
    external_details: {
      uid: "external123",
      name: "John Doe",
      email: null,
      country: null,
      country_code: null,
      phone_number: "+1234567890",
    },
    created_by: "admin",
    updated_by: "admin",
  },
  reservation: {
    id: "res123",
    arrival: new Date("2023-02-01T00:00:00Z"),
    departure: new Date("2023-02-10T00:00:00Z"),
    property: "property123",
    source: "website",
    title: "Vacation Stay",
    status: "confirmed",
    guest_link: "http://example.com/guest123",
    number_of_guests: 2,
    number_of_nights: 9,
    language: "en",
  },
  language: "en",
};
//================================================Guest Contact Details================================================
export interface GuestContactDetails {
  title: string;
  data: ContactData;
}

export interface ContactData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

// Mock data for GuestContactDetails
export const mockGuestContactDetails: GuestContactDetails = {
  title: "Contact Information",
  data: {
    id: "contact123",
    first_name: "Jane",
    last_name: "Doe",
    email: "jane.doe@example.com",
    phone_number: "+1234567890",
  },
};

//================================================Guest Language================================================
export interface LanguageOption {
  code: string;
  label: string;
}

// List of language options
export const languageOptionsList: LanguageOption[] = [
  { code: "en", label: "English" },
  { code: "fr", label: "French" },
  { code: "es", label: "Spanish" },
  { code: "de", label: "German" },
  { code: "ar", label: "Arabic" },
  { code: "zh", label: "Chinese" },
  { code: "it", label: "Italian" },
  { code: "pt", label: "Portuguese" },
];
