import React from "react";
import { Button, ButtonProps } from "@mui/material";

interface ButtonAtomProps extends ButtonProps {
  label: string;
  onClick: () => void;
  backgroundColor?: string;
  textColor?: string;
}
const ButtonAtom: React.FC<ButtonAtomProps> = ({
  label,
  onClick,
  backgroundColor = "#000", // Default dark background
  textColor = "#fff", // Default light text
  color,
  sx,
  ...props
}) => (
  <Button
    onClick={onClick}
    sx={{
      backgroundColor,
      color: textColor,
      padding: "8px 16px",
      borderRadius: "4px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: backgroundColor === "#000" ? "#333" : backgroundColor,
      },
      ...sx,
    }}
    {...props}
  >
    {label}
  </Button>
);

export default ButtonAtom;
