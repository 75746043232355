import React, { useState } from "react";
import {
  GuestContactDetails,
  GuestFaqs,
  GuestGuidesDetails,
  GuestPropertyDetails,
  GuestReservationDetails,
  LanguageOption,
} from "../../features/public/guestPage/guest.types"; // Adjust the import path as needed
import GuestPropertyDetailsOrganisme from "../organisms /GuestPage/GuestPropertyDetailsOrganisme";
import GuestFeaturedGuidesOrganisme from "../organisms /GuestPage/GuestFeaturedGuidesOrganisme";
import GuestGuidesOrganisme from "../organisms /GuestPage/GuestGuidesOrganisme";
import GuestFAQsOrganisme from "../organisms /GuestPage/GuestFaqsOrganisme";
import GuestContactsOrganisme from "../organisms /GuestPage/GuestContactOrganisme";
import { useSearchParams } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CountryFlag from "react-country-flag";
import { getCountryCode } from "../../features/public/guestPage/guest.hooks";

interface GuestPageProps {
  guestFaqs: GuestFaqs | undefined;
  guestGuidesDetails: GuestGuidesDetails | undefined;
  guestPropertyDetails: GuestPropertyDetails | undefined;
  guestReservationDetails: GuestReservationDetails | undefined;
  guestContactsDetails: GuestContactDetails | undefined;
  isTemplate: boolean;
  languageOptions: LanguageOption[];
  selectedLanguage: string;
  handleLanguageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const GuestPage: React.FC<GuestPageProps> = ({
  isTemplate,
  guestFaqs,
  guestGuidesDetails,
  guestPropertyDetails,
  guestReservationDetails,
  guestContactsDetails,
  languageOptions,
  selectedLanguage,
  handleLanguageChange,
}) => {
  const [searchParams] = useSearchParams();
  const [copied, setCopied] = useState(false);

  const shareableLink = `${window.location.origin}${
    window.location.pathname
  }?id=${searchParams.get("id")}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {isTemplate && (
        <div className="flex items-center space-x-2">
          {/* Copy Link Button */}
          <Tooltip title={copied ? "Link Copied!" : "Copy Link"}>
            <IconButton onClick={copyToClipboard} sx={{ color: "black" }}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {/* Language Switcher */}
      <div className="flex items-center justify-end w-full">
        <select
          id="language-switcher"
          value={selectedLanguage}
          onChange={handleLanguageChange}
          className="border rounded-lg px-2 py-1"
          aria-label="Language Switcher" // Provide a descriptive label for accessibility
        >
          {languageOptions.map((option) => (
            <option key={option.code} value={option.code}>
              <CountryFlag
                countryCode={getCountryCode(option.code)}
                style={{ width: "20px", marginRight: "5px" }}
              />
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <GuestPropertyDetailsOrganisme
        guestPropertyDetails={guestPropertyDetails}
      />
      <GuestFeaturedGuidesOrganisme guestGuidesDetails={guestGuidesDetails} />
      <GuestFAQsOrganisme guestFaqs={guestFaqs} />
      <GuestGuidesOrganisme guestGuidesDetails={guestGuidesDetails} />
      <GuestContactsOrganisme
        guestPropertyDetails={guestPropertyDetails}
        guestContactsDetails={guestContactsDetails}
      />
    </div>
  );
};

export default GuestPage;
