import React, { useEffect, useState } from "react";
import GuestPage from "../../../components/pages/guestPage";
import { useSearchParams } from "react-router-dom";
import {
  useFaqs,
  useGuidesDetails,
  usePropertyDetails,
  useReservationDetails,
  useContactDetails,
  useAppDispatch,
  useSetError,
  useSetLoading,
} from "./guest.hooks";
import guestApiSlice from "./guest.services";
import { languageOptionsList } from "./guest.types";
import { CircularProgress } from "@mui/material";

const Guest: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";
  const mode = searchParams.get("mode") || "property";
  const isReservation = mode === "reservation";
  const isTemplate = mode === "template";

  // State for managing language
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");

  // State to track initialization
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  // State for loading indicators
  const [isInitialLoading, setInitialLoading] = useState<boolean>(true);
  const [isLanguageChanging, setLanguageChanging] = useState<boolean>(false);

  // Hooks for accessing data from Redux
  const faqs = useFaqs();
  const guidesDetails = useGuidesDetails();
  const propertyDetails = usePropertyDetails();
  const reservationDetails = useReservationDetails();
  const contactDetails = useContactDetails();

  const dispatch = useAppDispatch();
  const setError = useSetError();
  const setLoading = useSetLoading();

  useEffect(() => {
    // Set default language only on the first load
    if (!isInitialized) {
      if (mode === "reservation" && reservationDetails?.language) {
        setSelectedLanguage(reservationDetails.language);
      } else if (propertyDetails?.data.language) {
        setSelectedLanguage(propertyDetails.data.language);
      }
      setIsInitialized(true); // Mark as initialized
    }
  }, [reservationDetails, propertyDetails, mode, isInitialized]);

  // Fetch Data Function
  const fetchData = React.useCallback(
    async (language: string) => {
      try {
        setError(null);
        setLoading(true);

        if (mode === "reservation") {
          await dispatch(
            guestApiSlice.endpoints.getGuestReservationDetails.initiate({
              id,
              isReservation,
              lang: language,
            })
          );
        } else {
          await dispatch(
            guestApiSlice.endpoints.getGuestPropertyDetails.initiate({
              id,
              isReservation,
              lang: language,
            })
          );
        }

        await Promise.all([
          dispatch(
            guestApiSlice.endpoints.getGuestGuidesDetails.initiate({
              id,
              isReservation,
              lang: language,
            })
          ),
          dispatch(
            guestApiSlice.endpoints.getGuestFaqs.initiate({
              id,
              isReservation,
              lang: language,
            })
          ),
          dispatch(
            guestApiSlice.endpoints.getGuestContactDetails.initiate({
              id,
              isReservation,
              lang: language,
            })
          ),
        ]);

        setLoading(false);
      } catch (error: any) {
        setError(error?.message || "Error fetching guest data");
        setLoading(false);
      }
    },
    [dispatch, id, isReservation, setError, setLoading, mode]
  );

  // Initial Load
  useEffect(() => {
    const initialize = async () => {
      await fetchData(selectedLanguage);
      setInitialLoading(false);
    };
    initialize();
  }, [id, mode, dispatch, fetchData, selectedLanguage]);

  // Handle Language Change
  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    setLanguageChanging(true);

    fetchData(newLanguage).finally(() => {
      setLanguageChanging(false);
    });
  };

  if (isInitialLoading) {
    return (
      <div className="text-center mt-20 text-black">
        <CircularProgress />
      </div>
    );
  }

  if (isLanguageChanging) {
    return (
      <div className="text-center mt-20 text-black">
        <CircularProgress />
      </div>
    );
  }

  return (
    <GuestPage
      isTemplate={isTemplate}
      guestFaqs={faqs || undefined}
      guestGuidesDetails={guidesDetails || undefined}
      guestPropertyDetails={propertyDetails || undefined}
      guestReservationDetails={reservationDetails || undefined}
      guestContactsDetails={contactDetails || undefined}
      selectedLanguage={selectedLanguage}
      languageOptions={languageOptionsList}
      handleLanguageChange={handleLanguageChange}
    />
  );
};

export default Guest;
